import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

export const customerAddressSchema = yup
  .object({
    addressLine: yup.string().required('Address is required').trim(),
    country: yup.string().required('Country is required'),
    province: yup.string().required('Province is required'),
    city: yup.string().required('City is required'),
    postalCode: yup.string().required('Postal code is required'),
    tag: yup.string().oneOf(['Home', 'Office']).default('Home'),
    isDefaultBillingAddress: yup.boolean().default(false),
    isDefaultShippingAddress: yup.boolean().default(false),
  })
  .required();

export type CustomerAddressInputs = yup.InferType<typeof customerAddressSchema>;

const defaultValues: CustomerAddressInputs = {
  addressLine: '',
  country: '',
  province: '',
  city: '',
  postalCode: '',
  tag: 'Home',
  isDefaultBillingAddress: false,
  isDefaultShippingAddress: false,
};

export const useAddressForm = (options?: { initialValues?: CustomerAddressInputs }) => {
  const form = useForm({
    defaultValues,
    resolver: yupResolver(customerAddressSchema),
  });

  const { setValue, watch } = form;

  const province = watch('province');

  useEffect(() => {
    if (!province) {
      setValue('city', '');
    }
  }, [province, setValue]);

  useEffect(() => {
    if (!options?.initialValues) return;

    const {
      addressLine,
      city,
      country,
      province,
      postalCode,
      tag,
      isDefaultBillingAddress,
      isDefaultShippingAddress,
    } = options.initialValues;

    setValue('addressLine', addressLine);
    setValue('city', city);
    setValue('country', country);
    setValue('province', province);
    setValue('postalCode', postalCode);
    setValue('tag', tag);
    setValue('isDefaultBillingAddress', isDefaultBillingAddress);
    setValue('isDefaultShippingAddress', isDefaultShippingAddress);
  }, [options?.initialValues, setValue]);

  return form;
};
