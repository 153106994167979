import React from 'react'
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {colors} from '../../../theme/defaultTheme';
import { Box, Typography } from '@material-ui/core';
import {  useProductFetchQuery } from '../hooks/followerd-vendor-hooks';
import useLocale from '../../home/hooks/useLocale';
interface VendorItemProps {
    id:string,

}



const useStyles = makeStyles((theme: Theme)=>
  createStyles({
    container:{
      display:'flex',
      height:'100px',
      minWidth:"100%",
      border:`solid .5px ${colors.darkest}`,
      flex:1,
      padding:'20px'
    },
    itemContainer:{
      padding:'20px',
      // width:'100%',
      // backgroundColor:"Blue",
      width:"60px",
      // margin:"0 10px",

    },
    itemName:{
      fontSize:"12px",
      color:"red",
      overflow:"hidden",
      textOverflow:"ellipsis",
      whiteSpace:"nowrap"
    }
  })
)

const VendorItems = (props:VendorItemProps) => {
  const classes = useStyles();
  const products = useProductFetchQuery();
  const country = useLocale();
  
  const vendorProducts = products?.data?.filter(i => i.vendorId === props.id)
  if(vendorProducts?.length === 0){
    return <>
     <Typography>No Product Available</Typography>
    </>
  }
  return (
    <Box className={classes.container}>
      {vendorProducts?.map((i)=>(
        <Box className={classes.itemContainer} id={i.id}>
          <img src={i.image.path} alt='' height="50px" width="50px" />
          <Typography className={classes.itemName}>{i.name}</Typography>
          <Typography>{country === 'PH' ? `₱ ${i.price.toFixed(2)}`:`S$ ${i.price.toFixed(2)}`}</Typography>
        </Box>
      ))}
    </Box>
  )
}

export default VendorItems