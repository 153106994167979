import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ProductItem } from '../order-types';
import { formatToCurrency } from '../../../utils/helpers';
import useLoggedInUser from '../hooks/useLoggedInUser';

const TrackOrderItem = ({ product }: { product: ProductItem }) => {
  const user = useLoggedInUser();

  const isSG = user?.countryCode === 'SG';

  return (
    <Box
      display='grid'
      gridTemplateColumns='90px 40% auto auto'
      gridColumnGap={24}
      alignItems='center'
      flex={1}
      p={4}
      pt={1}
    >
      <img
        loading='lazy'
        style={{ width: 90, height: 90, objectFit: 'cover' }}
        src={product.image}
        alt={product.name}
      />
      <Box>
        <Typography component='h3'>{product.name}</Typography>
        <Typography>{formatToCurrency(product.price, !isSG)}</Typography>
      </Box>
      <Typography>Quantity: {product.qty}</Typography>
    </Box>
  );
};

export default TrackOrderItem;
