import React from 'react';
// import { Box, Divider, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  BrandDescription,
  ImageBox,
  ProductBox,
  ProductDescription,
  ProductDetailCategory,
  ProductDetailContainer,
  ProductDetailContainerBody,
  ProductInfoDetails,
  ProductInfoHeaders,
} from '../cart/fragments/ProductItemComponents';
import { Link, useHistory, useParams } from 'react-router-dom';
import useLocale from '../home/hooks/useLocale';
import { Box, Divider, Typography } from '@material-ui/core';
import { Product } from '../home/types';
import { useQuery } from 'react-query';
import { publicAxiosClient } from '../../utils/apiPublicClient';
import LoadingIndicator from './LoadingIndicator';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from '../../theme/defaultTheme';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../store/product/actions';
import { AppState } from '../../store';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      // gridGap:"10px"
    },
    imageContainer: {
      aspectRatio: '16/9',
      objectFit: 'contain',
    },
    buttonStyle: {
      backgroundColor: colors.primary,
      borderRadius: '0px',
      color: '#FFF',
    },
    vendorLink: {
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.darkest,
      display: 'block',
      marginTop: 'auto',
      marginBottom: 30,
    },
  })
);

const CarouselItemProductDetail = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const user = useSelector((state: AppState) => state.auth.isLoggedIn);
  const country = useLocale();
  const {
    data: products,
    status,
    error,
  } = useQuery<Product[]>(
    'products',
    async () =>
      (
        await publicAxiosClient.get<Product[]>(
          `v1/public/products?isActive=true&forMarketplace=true&countryCode=${country}`
        )
      ).data
  );

  if (status === 'loading') return <LoadingIndicator />;
  if (!products) return null;
  const handleClose = () => history.replace('/home');
  const product = products.find((p) => p.id === id);
  if (!product) return null;

  const addToCartHandler = (id: string, productSkuId: string, vendorId: string) => {
    if (!user) {
      history.push('/login');
    } else {
      dispatch(addToCart(id, productSkuId, vendorId));
      history.replace('/home');
    }
  };
  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle>
        <Box>
            <Typography>
                {[product?.h1,product?.h2,product?.h3].filter(Boolean).join("/")}
            </Typography>
            <Typography>{product?.name}</Typography>
            <Typography>Product Code: {product?.displayId}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.infoContainer}>
          <Box className={classes.imageContainer}>
            <img
              src={product?.image.path}
              style={{ marginTop: '10px', aspectRatio: '1/1',objectFit: 'cover'}}
              alt='item'
              height='250px'
              width='250px'
            />
          </Box>
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              PHP {Number(product?.price).toFixed(2)}
            </Typography>
            <Typography style={{ marginBottom: '10px' }}>Details</Typography>
            <Typography style={{ marginBottom: '5px' }}>Brand</Typography>
            <Typography style={{ marginBottom: '5px' }}>{product?.brand}</Typography>
            <Button
              startIcon={<AddShoppingCartIcon />}
              onClick={() => addToCartHandler(product.id, product.productSkuId, product.vendorId)}
              variant='contained'
              className={classes.buttonStyle}
            >
              Add To Cart
            </Button>
            <Link to={`/vendor/${product.vendorId}`} className={classes.vendorLink}>
              {product.companyName}
            </Link>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CarouselItemProductDetail;

// <>

// <ProductDetailContainer>
//   <ProductDetailCategory>
// <Box>
//     {/* <ItemCategory /> */}
//     <Typography variant='h5'>Sample Data</Typography>
//     <Typography variant='body1'>1234</Typography>
// </Box>
//   </ProductDetailCategory>

//   <Divider />
//   <ProductDetailContainerBody>
//     <ImageBox>
//     <img src={product?.image.path} style={{marginTop:'10px'}} alt='item' height="100px" width="100px" />
//     </ImageBox>
//     <Box>
//       <ProductBox>
//       <ProductInfoHeaders>
//       <Typography variant='h6' style={{fontWeight:'bold'}}>PHP {Number(product?.price).toFixed(2)}</Typography>
//       <Typography style={{marginBottom:'10px'}}>Details</Typography>
//       <Typography style={{marginBottom:'5px'}}>Brand</Typography>

//       <Typography style={{marginBottom:'5px',marginTop:'10px'}}>Description </Typography>
//       <Typography  style={{marginTop:'25px',fontWeight:'bold',textDecoration:'underline'}}>{product?.companyName}</Typography>
//       </ProductInfoHeaders>
//       <ProductInfoDetails>
//       <BrandDescription>{product?.brand}</BrandDescription>

//       </ProductInfoDetails>
//       </ProductBox>
//       <Button>Hello</Button>
//     </Box>
//   </ProductDetailContainerBody>
// </ProductDetailContainer>
// </>
