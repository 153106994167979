import { LayoutContainer } from './CommonComponents';
import Box from '@material-ui/core/Box';
import Footer from './Footer';

const Layout = ({ children, disableFooter, overrideHeight }: { children: JSX.Element | JSX.Element[] | string; disableFooter?: boolean, overrideHeight?: string }) => (
    <Box height="100%" width="100%">
        <LayoutContainer height={`calc(100vh - ${overrideHeight ? overrideHeight : '162px'})`} marginTop={overrideHeight ? overrideHeight : '162px'} className="layout-container">
            {children}
            {/* Footer */}
            {!disableFooter && <Footer />}
        </LayoutContainer>
    </Box>
)

export default Layout;