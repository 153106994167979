import { Box, Button, Typography } from '@material-ui/core'
import vendorLogo from '../../../assets/images/icons/vendor-logo.png';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {colors} from '../../../theme/defaultTheme';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import VendorItems from './VendorItems';
import { useHistory } from 'react-router-dom';

interface VendorProps{
    id:string,
    name:string,
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      padding:'25px',
      flexDirection:'column',
      backgroundColor:colors.white,
      marginBottom:'20px',
      border:`solid .5px ${colors.darkest}`,
    },
    dataContainer:{
        display:'grid',
        gridTemplateColumns:'1fr 18fr 1fr',
        
    },
    storeName:{
        fontWeight:'bold',
        fontSize:'18px'
    },
    storeTag:{
        fontSize:'15px',
        fontWeight:'lighter'
    },
    itemContainer:{
        padding:'20px',
        display:'flex'
    }
  })
);

const VendorSection = (props:VendorProps) => {
  const history = useHistory();
    const classes = useStyles();
    const openDeletePrompt = () => history.push(`/me/followed-vendor/delete/${props.id}`);
  return (
   <>
    <Box className={classes.container}>
       <Box className={classes.dataContainer}>
        <Box>
        <img src={vendorLogo} alt='Vendor' height='50px' width='50px' />
        </Box>
        <Box>
        <Typography className={classes.storeName} >{props.name}</Typography>
        <Typography className={classes.storeTag} >Official Store</Typography>
        </Box>
        <Box alignSelf='center'>
            <Button onClick={openDeletePrompt}><CheckCircleOutlineIcon color='primary' /> 
            <Typography>Followed</Typography>
            </Button>
        </Box>
       </Box>
       <Box className={classes.itemContainer}>
        <VendorItems id={props.id} />
      </Box>
    </Box>

   </>
  )
}

export default VendorSection