import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Order } from './order-types';
import { formatDate } from '../../utils/helpers';
import { appColors } from '../../utils/ui-utils';
import OrderItem from './OrderItem';
import { Link } from 'react-router-dom';
import OrderTag from './OrderTag';
import { formatOrderStatus } from './track-orders/track-order-utils';
import { colors } from '../../theme/defaultTheme';

interface OrderGroupProps {
  order: Order;
  hideLinkButton?: boolean;
}

const OrderGroup = ({ order, hideLinkButton }: OrderGroupProps) => {
  return (
    <Box border={`1px solid ${appColors.lightGray}`} mb={3}>
      <Box
        display='flex'
        alignItems='center'
        gridColumnGap={12}
        bgcolor={appColors.lightGray}
        padding='0 20px'
        height={56}
      >
        <Typography component='h2' style={{ fontSize: 14, fontWeight: 600 }}>
          {order.vendorName}
        </Typography>
        <Typography style={{ fontSize: 14, fontWeight: 600 }}>ORDER NO. {order.id}</Typography>
        <Typography style={{ fontSize: 13, fontWeight: 'lighter', textTransform: 'capitalize' }}>
          {formatOrderStatus(order.status).toLowerCase()} {formatDate(order.date)}
        </Typography>
        <OrderTag status={order.status} />
      </Box>
      <Box p={4} display='flex' alignItems='center'>
        <Box flex={1}>
          {order.items.map((product) => (
            <OrderItem key={product.productId} product={product} />
          ))}
        </Box>
        {!hideLinkButton && (
          <Link
            style={{ fontWeight: 600, color: colors.primary, marginRight: 32 }}
            to={`/me/orders/${order.vendorName}/${order.id}`}
          >
            View Item(s)
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default OrderGroup;
