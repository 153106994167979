import React from 'react'
import Box from '@material-ui/core/Box';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Route, useHistory } from 'react-router-dom';
import { useFollowedByUserQuery, useProductFetchQuery } from '../hooks/followerd-vendor-hooks';
import LoadingIndicator from '../../common/LoadingIndicator';
import NoDataView from '../../common/NoDataView';
import FormButton from '../../common/FormButton';
import VendorSection from './VendorSection';
import UnFollowVendorPrompt from './UnFollowVendorPrompt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection:'column',


      [theme.breakpoints.down('md')]: {
        flexDirection:'column'
      },

      [theme.breakpoints.down('sm')]: {
        flexDirection:'column'
      },
    },
  })
);
const FollowedVendorPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const followed = useFollowedByUserQuery();
  const products = useProductFetchQuery();
  if (followed.isLoading && products.isLoading) return <LoadingIndicator />;

  const isEmpty = !followed.data ||followed.data.length === 0;

  const goToHomePage = () => history.push('/home');
  console.log(products.data)
  if (isEmpty)
  return (
    <NoDataView message='You have no followed vendor yet. Browse products now'>
      <FormButton onClick={goToHomePage}>Browse Products</FormButton>
    </NoDataView>
  );

  return (
    <Box className={classes.container}>
      {followed.data?.map((vendor)=>(
        <VendorSection  key={vendor.id} id={vendor.id} name={vendor.companyName} />
      ))}
      <Route path='/me/followed-vendor/delete/:id' component={UnFollowVendorPrompt} />
    </Box>
  )
}

export default FollowedVendorPage