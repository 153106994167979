import React from 'react'
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { publicAxiosClient } from '../../utils/apiPublicClient';
import Layout from '../common/Layout';
import LoadingIndicator from '../common/LoadingIndicator';
import useLocale from '../home/hooks/useLocale';
import { Product } from '../home/types';
const ProductList = () => {
  const { id } = useParams<{ id: string }>();
  const country = useLocale()
  const {data:products,status,error} = useQuery<Product[]>("products",
  async ()=> (await publicAxiosClient.get<Product[]>(`v1/public/products?isActive=true&forMarketplace=true&countryCode=${country}`)).data
  )
  // if(user) return null;
  console.log(products?.filter(i => i.h1 === id))
  if(status === 'loading') return <LoadingIndicator />
  if(!products) return null;
  if(error) return <div>Something Went Wrong</div>
  
  return (
    <Layout>
      <div>{id}</div>
    </Layout>
  )
}

export default ProductList