import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { OrderDetail } from './order-types';
import { formatDate } from '../../utils/helpers';
import { appColors } from '../../utils/ui-utils';
import OrderItem from './OrderItem';
import OrderTag from './OrderTag';
import { formatOrderStatus } from './track-orders/track-order-utils';

const OrderDetailGroup = ({ orderDetail }: { orderDetail: OrderDetail }) => {
  return (
    <Box border={`1px solid ${appColors.lightGray}`} mb={3}>
      <Box
        flex={1}
        display='flex'
        alignItems='center'
        gridColumnGap={12}
        bgcolor={appColors.lightGray}
        padding='0 20px'
        height={56}
      >
        <Typography component='h2' style={{ fontSize: 14, fontWeight: 600 }}>
          {orderDetail.vendorName}
        </Typography>
        <Typography style={{ fontSize: 14, fontWeight: 600 }}>
          ORDER NO. {orderDetail.id}
        </Typography>
        <Typography style={{ fontSize: 13, fontWeight: 'lighter', textTransform: 'capitalize' }}>
          {formatOrderStatus(orderDetail.status).toLowerCase()} {formatDate(orderDetail.orderDate)}
        </Typography>
        <OrderTag status={orderDetail.status} />
      </Box>
      <Box p={4} display='flex' alignItems='center'>
        {orderDetail.items.map((product) => (
          <OrderItem key={product.productId} product={product} />
        ))}
      </Box>
    </Box>
  );
};

export default OrderDetailGroup;
