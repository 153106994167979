import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { getCategories } from '../../../store/system/actions';
import { Category } from '../../../store/system/types';
import useLocale from '../hooks/useLocale';

const useCategories = () => {
  const userLocale = useLocale();

  const dispatch = useDispatch();

  const categories = useSelector<AppState, Category[]>((state) => state.system.categories);

  useEffect(() => {
    dispatch(getCategories(userLocale));
  }, [dispatch, userLocale]);

  return categories;
};

export default useCategories;
