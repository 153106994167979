import { AppState } from '..';
import { SET_SYSTEM_STATE, SystemState, SystemAction, SET_USER_DETAILS } from './types';

const INITIAL_STATE: SystemState = {
  categories: [],
  categoriesLoading: false,
  activeCategory: 'Home',
  isLoggedIn: false,
  shallRedirect: false,
  redirectTo: '',
  snackBarIsOpen: false,
  snackBarMessage: '',
  snackBarPosition: { vertical: 'top', horizontal: 'right' },
  snackBarType: 'success',
  popoverAnchorEl: null,
  interceptors: null,
  systemDialogOpen: false,
  systemDialogMaxWidth: false,
  systemDialogTitle: '',
  systemDialogContent: '',
  systemDialogActions: undefined,
  systemDialogSimple: false,
  systemDialogConfirm: false,
  systemOverrideTitle: '',
  systemConfirmOnly: false,
  systemDialogConfirmAction: () => {},
  productDialogIsOpen: false,
  productDialogCategory: '',
  productDialogName: '',
  productDialogCode: '',
  productDialogPrice: 0,
  productDialogDiscountPrice: 0,
  productDialogBrand: '',
  productDialogMaterial: '',
  productDialogType: '',
  productDialogDescription: '',
  productDialogImage: '',
  productDialogIsFavorite: false,
  productDialogVendorId: '',
  productDialogVendorName: '',
  productDialogDetailsLoading: false,
  productDialogOnClickAddToCartButton: () => {},
  productDialogOnClickCloseButton: () => {},
  headerCategoryIsVisible: true,
};

const reducer = (state = INITIAL_STATE, action: SystemAction): SystemState => {
  switch (action.type) {
    case SET_SYSTEM_STATE:
      return { ...state, ...action.payload };
    case SET_USER_DETAILS:
      return {
        ...state,
        session: {
          ...state.session,
          userDetails: {
            ...state.session?.userDetails,
            ...action.payload,
          },
        },
      } as SystemState;
    default:
      return state;
  }
};

// added by jeff
export const selectUserDetails = (state: AppState) => state.system.session?.userDetails;

export const selectIsLoggedIn = (state: AppState) => state.auth.isLoggedIn;

export default reducer;
