import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { useFavoritesQuery, useRemoveFavorite } from '../hooks/favorites-hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      fontSize: 16,
    },
    productName: {
      fontWeight: 'bold',
      color: '#333',
    },
    cancelBtn: {
      borderRadius: 0,
      textTransform: 'capitalize',
    },
    deleteBtn: {
      borderRadius: 0,
      textTransform: 'capitalize',
      backgroundColor: theme.palette.error.main,

      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
  })
);

function FavoriteRemovePrompt() {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const favorites = useFavoritesQuery();

  const removeFavorite = useRemoveFavorite();

  const currentItem = favorites.data?.find((f) => f.id === id);

  const handleClose = () => history.replace('/me/favorites');

  const handleDelete = async () => {
    if (!currentItem) return;

    await removeFavorite.mutateAsync({
      favoriteId: currentItem.id,
      favoriteName: currentItem.name,
    });
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='xs'
    >
      <DialogTitle id='alert-dialog-title'>Remove Favorite</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' className={classes.message}>
          Are you sure you want to remove{' '}
          <span className={classes.productName}>{currentItem?.name}</span> from your favorites list?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button
          variant='outlined'
          color='primary'
          onClick={handleClose}
          className={classes.cancelBtn}
        >
          Cancel
        </Button>
        <Button
          disableElevation
          variant='contained'
          onClick={handleDelete}
          color='primary'
          className={classes.deleteBtn}
        >
          {removeFavorite.isLoading ? 'Removing...' : 'Yes, remove it'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FavoriteRemovePrompt;
