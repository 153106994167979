import { useQuery } from 'react-query';
import { publicAxiosClient } from '../../utils/apiPublicClient';
import useLocale from '../home/hooks/useLocale';
import { Vendor } from './types';

async function publicVendorService(country: string) {
  const url = `user/public/VENDOR?status=Approved&countryCode=${country}`;
  const response = await publicAxiosClient.get<Vendor[]>(url);
  return response.data;
}

function usePublicVendors() {
  const country = useLocale();

  return useQuery('public-vendors', () => publicVendorService(country));
}

export default usePublicVendors;
