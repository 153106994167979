import { useCallback, useEffect } from 'react';
import MiniSearch from 'minisearch';

import { Product } from '../home/types';
import { Vendor } from './types';
import usePublicProducts from './usePublicProducts';
import usePublicVendors from './usePublicVendors';

const productSearch = new MiniSearch<Product>({
  fields: ['brand', 'h1', 'h2', 'h3', 'name', 'companyName'],
  searchOptions: {
    boost: { name: 2 },
    prefix: true,
    fuzzy: true,
  },
  autoSuggestOptions: {
    boost: { name: 2 },
    prefix: true,
    fuzzy: true,
  },
});

const vendorSearch = new MiniSearch<Vendor>({
  fields: ['companyName'],
  searchOptions: {
    prefix: true,
    fuzzy: true,
  },
  autoSuggestOptions: {
    prefix: true,
    fuzzy: true,
  },
});

interface UseFullTextSearchOptions {
  searchQuery: string;
  suggestionSize?: number;
  isOnSearchPage?: boolean;
}

function useFullTextSearch({
  searchQuery,
  suggestionSize = 10,
  isOnSearchPage,
}: UseFullTextSearchOptions) {
  const products = usePublicProducts();
  const vendors = usePublicVendors();

  // index the products
  useEffect(() => {
    if (productSearch.documentCount === 0) {
      productSearch.addAll(products.data ?? []);
    }
  }, [products.data]);

  // index the vendors
  useEffect(() => {
    if (vendorSearch.documentCount === 0) {
      vendorSearch.addAll(vendors.data ?? []);
    }
  }, [vendors.data]);

  if (isOnSearchPage) {
    if (productSearch.documentCount === 0) {
      productSearch.addAll(products.data ?? []);
    }

    if (vendorSearch.documentCount === 0) {
      vendorSearch.addAll(vendors.data ?? []);
    }
  }

  const searchSuggestions = Array.from(
    new Set(
      [...productSearch.autoSuggest(searchQuery), ...vendorSearch.autoSuggest(searchQuery)]
        .slice(0, suggestionSize)
        .map((s) => s.suggestion)
    )
  );

  const searchHandler = useCallback(() => {
    productSearch.search(searchQuery);
    vendorSearch.search(searchQuery);
  }, [searchQuery]);

  const productResults = productSearch.search(searchQuery).map((s) => s.id);
  const vendorResults = vendorSearch.search(searchQuery).map((s) => s.id);

  const loading = products.isLoading || vendors.isLoading;

  return {
    searchSuggestions,
    searchHandler,
    loading,
    products,
    vendors,
    productResults,
    vendorResults,
  };
}

export default useFullTextSearch;
