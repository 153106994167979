import React from 'react';

import Box from '@material-ui/core/Box';
import OrderGroup from './OrderGroup';
import { useGetCustomerOrders } from './hooks/useGetCustomerOrders';
import ListTitle from '../common/ListTitle';
import LoadingIndicator from '../common/LoadingIndicator';
import NoDataView from '../common/NoDataView';

const CustomerOrders = () => {
  const { data: customerOrders, isLoading } = useGetCustomerOrders();

  if (isLoading) return <LoadingIndicator />;

  if (!customerOrders) return <NoDataView message='You have no orders yet' />;

  return (
    <Box flex={1}>
      <ListTitle title='My Orders' />
      {customerOrders.map((order) => (
        <OrderGroup key={order.id} order={order} />
      ))}
    </Box>
  );
};

export default CustomerOrders;
