import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import { useCategoriesStyles } from './categoriesStyles';
import CategoryItem from './CategoryItem';
import useCategories from './useCategories';
import useSearchParams from './useSearchParams';

const CategoriesBar = () => {
  const { pathname } = useSearchParams();

  const categories = useCategories();

  const classes = useCategoriesStyles();

  const containerRef = useRef<HTMLDivElement>(null);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [stillScrolling, setStillScrolling] = useState(false);

  const atHome = pathname === '/home' || pathname === '/';

  const atLeftEdge = scrollPosition <= 0;
  const atRightEdge = containerRef.current
    ? scrollPosition >= containerRef.current.getBoundingClientRect().width
    : false;

  const handleScroll = (dir: 'left' | 'right') => {
    if (!containerRef.current) return;

    setStillScrolling(true);

    const containerWidth = containerRef.current.getBoundingClientRect().width;

    const offset = 0.9 * (dir === 'right' ? containerWidth : -containerWidth);

    containerRef.current.scrollBy(offset, 0);

    setScrollPosition(containerRef.current.scrollLeft + offset);

    setTimeout(() => {
      setStillScrolling(false);
    }, 1000);
  };

  return (
    <div className={classes.mainBox}>
      <div className={classes.scrollButtonPrev}>
        {!atLeftEdge && (
          <IconButton aria-label='scroll to right' onClick={() => handleScroll('left')}>
            <ChevronLeft />
          </IconButton>
        )}
      </div>
      <div
        ref={containerRef}
        className={classes.container}
        style={{ pointerEvents: stillScrolling ? 'none' : 'initial' }}
      >
        <Link
          to={`/`}
          className={[classes.categoryItem, atHome ? 'active' : '', 'click-only'].join(' ')}
        >
          Home
        </Link>
        {categories.map((category) => (
          <CategoryItem key={category.name} category={category} />
        ))}
      </div>
      <div className={classes.scrollButton}>
        {!atRightEdge && (
          <IconButton aria-label='scroll to left' onClick={() => handleScroll('right')}>
            <ChevronRight />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default CategoriesBar;
