import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from '../../../theme/defaultTheme';

export const useCategoriesStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainBox: {
      position: 'relative',
      padding: '0px 36px',
      '@media (max-width: 768px)': {
        display: 'none',
      },
    },
    container: {
      color: colors.darkest,
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      flexWrap: 'nowrap',
      overflow: 'hidden',
      padding: '0 48px',
      height: 54,
      boxSizing: 'border-box',
      scrollBehavior: 'smooth',
      scrollSnapType: 'x mandatory',
    },
    categoryItemContainer: {
      height: '100%',

      '&:hover .h1': {
        color: colors.darkest,
      },
    },
    categoryItem: {
      color: colors.gray,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      textDecoration: 'none',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',

      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 4,
        backgroundColor: colors.primary,
        opacity: 0,
      },

      '&:hover': {
        color: colors.darkest,
      },

      '&.active': {
        color: colors.darkest,

        '&:after': {
          opacity: 1,
        },
      },

      '&:not(.click-only):hover + .flyout-menu': {
        opacity: 1,
        zIndex: 10,
        transform: 'transformY(0)',
        visibility: 'visible',
      },

      '& + .flyout-menu:hover': {
        opacity: 1,
        zIndex: 10,
        transform: 'transformY(0)',
        visibility: 'visible',
      },

      // '&.active + .flyout-menu': {
      //   opacity: 1,
      //   zIndex: 10,
      //   transform: 'transformY(0)',
      //   visibility: 'visible',
      // },
    },
    scrollButton: {
      position: 'absolute',
      top: 4,
      right: 24,
      paddingRight: 8,
      width: 48,
      backgroundColor: 'white',
      zIndex: 10,
    },
    scrollButtonPrev: {
      position: 'absolute',
      top: 4,
      left: 24,
      paddingRight: 8,
      width: 48,
      backgroundColor: 'white',
      zIndex: 10,
    },

    flyoutMenu: {
      position: 'absolute',
      left: 0,
      right: 0,
      width: '100vw',
      boxSizing: 'border-box',
      height: '40vh',
      backgroundColor: 'transparent',
      padding: '0 48px',
      color: colors.darkest,
      transform: 'transformY(-32px)',
      opacity: 0, // hide menu
      transition: 'opacity 0.3s, transform 0.3s',
      zIndex: -1,
      visibility: 'hidden',
    },

    flyoutContent: {
      backgroundColor: 'white',
      padding: '36px',
      maxHeight: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',

      '& ul': {
        padding: 0,
      },

      '& ul.first': {
        flex: 1,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: 16,
        height: '25vh',
      },

      '& li': {
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      },

      '& li a': {
        color: 'inherit',
        fontWeight: 'bold',
        textDecoration: 'none',
        width: 'max-content',

        '&:hover': {
          color: colors.primary,
        },
      },

      '& li li a': {
        fontWeight: 'normal',
        marginBottom: 12,
      },
    },
    flyoutRightImage: {
      width: 250,

      '& img': {
        width: '100%',
        aspectRatio: '1 / 1',
        objectFit: 'contain',
      },
    },
  })
);
