import { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { SystemState } from '../../store/system/types'
import { setSystemState } from '../../store/system/actions';

import HomeCarouselSection from './fragments/HomeCarousel';
import HomeFeaturedProductsSection from './fragments/HomeFeaturedProductsSection';
import Layout from '../common/Layout';
import HomeUserProductSection from './fragments/HomeUserProductSection';
import HomeUserProductOnSale from './fragments/HomeUserProductOnSale';



interface HomeProps {
    setSystemState: typeof setSystemState;
    system: SystemState;
}

class Home extends Component<HomeProps> {
    render = () => {
        return (
            <Layout>
                <HomeCarouselSection />
                {/* <HomeFeaturedProductsSection /> */}
                {/* <HomeUserProductOnSale /> */}
                <HomeUserProductSection />
            </Layout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system
});

const mapDispatchToProps = {
    setSystemState
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);