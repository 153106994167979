
import { CartItems } from '../../../store/cart/types';

// Local Components
import { 
    CartCheckbox,
    CartItemsHeader,
    SelectAllContainer
} from './CartComponents';
import CartItemProduct from './CartItemProduct';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Utils
import map from 'lodash/map';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import LoadingIndicator from '../../common/LoadingIndicator';

interface CartItemBlockProps {
    items: Array<CartItems>;
    checkOutItems: Array<CartItems>;
    vendorId: string;
    vendorSelected?: boolean;
    vendorName: string;
    checkOutItem?: boolean;
    onPressSelectAllVendorCheckBox: (vendorId: string) => void;
    onPressSelectProductCheckBox: (bagId: string) => void;
    onPressDeleteProduct: (bagId: string) => void;
    onChangeQuantityInput: (bagId: string, value: string) => void;
}

const CartItemBlock = (props: CartItemBlockProps) => {
    const { vendorName, items, vendorId, checkOutItems, onPressSelectAllVendorCheckBox, onPressSelectProductCheckBox, onPressDeleteProduct, onChangeQuantityInput } = props;
    const filteredCheckOutItems = filter(checkOutItems, { vendorId } );
    const allItemsChecked = filteredCheckOutItems.length === items.length ? true : false;
    let filteredCheckOutItemsLength = 0;
    const itemLoading = useSelector((state:AppState)=>state.cart.cartLoading);

    const check0= checkOutItems.filter((i)=> i.vendorId === vendorId).reduce((acc,curr)=>{
        return acc + curr.quantity
    },0)
    const check1 = items.filter((i)=> i.vendorId === vendorId).filter((i)=>i.isOutOfStock === false).map(i => i.quantity).reduce((acc,curr)=> {
        return acc + curr
    },0)
 
    for (const x in filteredCheckOutItems) {
        filteredCheckOutItemsLength += filteredCheckOutItems[x].quantity;
    }
    if(itemLoading) return <LoadingIndicator />
    return (
        <Box
            id={`cart-${vendorName.toLowerCase().replace(/\s/g, '')}-vendorblock`}
            style={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box', width: '100%', marginBottom: 10 }}
        >
            <CartItemsHeader style={{ justifyContent: 'flex-start' }}>
                <SelectAllContainer>
                    <CartCheckbox id={`cart-${vendorName.toLowerCase().replace(/\s/g, '')}-selectall-checkbox`} checked={check0 === check1 ?true:false} onChange={(e) => onPressSelectAllVendorCheckBox(vendorId)}/>
                    <Typography className="cart-selectall-text">{vendorName}</Typography>
                    <Typography className="cart-items-text">{filteredCheckOutItemsLength} Item{filteredCheckOutItemsLength > 1 ? '(s)' : ''}</Typography>
                </SelectAllContainer>
            </CartItemsHeader>
            <Box style={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box', width: '100%', borderWidth: '1px 1px 0 1px', borderStyle: 'solid', borderColor: '#C7C7C7'}}>
                {
                    map(items, (item: CartItems) => {
                        return (
                            <CartItemProduct 
                                //key={`cart-${item.name.toLowerCase().replace(/\s/g, '')}-itemproduct`}
                                key={item.id}
                                item={item}
                                checkOutItems={checkOutItems}
                                onPressSelectProductCheckBox={onPressSelectProductCheckBox}
                                onPressDeleteProduct={onPressDeleteProduct}
                                onChangeQuantityInput={onChangeQuantityInput}
                            />
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default CartItemBlock;