import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addToFavorites, getFavoritesByUser, removeFavorite } from '../favorites/favoritesService';
import useLoggedInUser, { useIsLoggedIn } from './useLoggedInUser';

type FavMutationArgs = {
  favoriteId: string;
  favoriteName: string;
};

export function useFavoritesQuery() {
  const user = useLoggedInUser();
  const isLoggedIn = useIsLoggedIn();

  return useQuery(
    ('favorites:' + user?.id) as string,
    () => getFavoritesByUser(user?.id as string),
    {
      enabled: Boolean(user) && Boolean(isLoggedIn),
    }
  );
}

export function useRemoveFavorite() {
  const user = useLoggedInUser();
  const history = useHistory();
  const dispatch = useDispatch();

  const favoritesQuery = useFavoritesQuery();

  return useMutation(
    ({ favoriteId, favoriteName }: FavMutationArgs) =>
      removeFavorite(user?.id as string, favoriteId),
    {
      retry: 1,
      async onSuccess(data, variables, context) {
        await favoritesQuery.refetch();

        // close prompt
        history.replace('/me/favorites');

        dispatch({
          type: 'set_system_state',
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: `${variables.favoriteName} is removed from Favorites`,
            snackBarType: 'success',
          },
        });
      },
      onError: (error: any) => {
        let err = 'An unknown error occured';

        if (error.response) {
          if (error.response.status === 401) return;

          err = error.response.data?.error?.message;
        }

        if (error.message) {
          err = error.message;
        }

        dispatch({
          type: 'set_system_user_details',
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: err,
            snackBarType: 'error',
          },
        });
      },
    }
  );
}

// ADD TO FAVORITE
type AddFavoriteMutationArgs = {
  productId: string;
  vendorId: string;
};

export function useAddFavorite() {
  const user = useLoggedInUser();
  const dispatch = useDispatch();

  const favoritesQuery = useFavoritesQuery();

  return useMutation(
    ({ productId, vendorId }: AddFavoriteMutationArgs) =>
      addToFavorites(user?.id as string, productId, vendorId),
    {
      retry: 1,
      async onSuccess(data, variables, context) {
        await favoritesQuery.refetch();

        dispatch({
          type: 'set_system_state',
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: `Added to favorites!`,
            snackBarType: 'success',
          },
        });
      },
      onError: (error: any) => {
        let err = 'An unknown error occured';

        if (error.response) {
          if (error.response.status === 401) return;

          err = error.response.data?.error?.message;
        }

        if (error.message) {
          err = error.message;
        }

        dispatch({
          type: 'set_system_user_details',
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: err,
            snackBarType: 'error',
          },
        });
      },
    }
  );
}

// REMOVE TO FAVORITE FOR PRODUCTS (not in fav page)

export function useRemoveFavoriteForHome() {
  const user = useLoggedInUser();
  const dispatch = useDispatch();

  const favoritesQuery = useFavoritesQuery();

  return useMutation(
    ({ favoriteId, favoriteName }: FavMutationArgs) =>
      removeFavorite(user?.id as string, favoriteId),
    {
      retry: 1,
      async onSuccess(data, variables, context) {
        await favoritesQuery.refetch();

        dispatch({
          type: 'set_system_state',
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: `Removed from favorites!`,
            snackBarType: 'success',
          },
        });
      },
      onError: (error: any) => {
        let err = 'An unknown error occured';

        if (error.response) {
          if (error.response.status === 401) return;

          err = error.response.data?.error?.message;
        }

        if (error.message) {
          err = error.message;
        }

        dispatch({
          type: 'set_system_user_details',
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: err,
            snackBarType: 'error',
          },
        });
      },
    }
  );
}
