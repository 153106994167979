import { AppState } from '../../../store';
import { useSelector } from 'react-redux';
import { Box, Divider, Typography } from '@material-ui/core';
import {
  BrandDescription,
  ImageBox,
  ProductBox,
  ProductDescription,
  ProductDetailCategory,
  ProductDetailContainer,
  ProductDetailContainerBody,
  ProductInfoDetails,
  ProductInfoHeaders,
} from './ProductItemComponents';
import { Link } from 'react-router-dom';

interface ProductProps {
  id: string;
}

const ProductDetails = (props: ProductProps) => {
  const { id } = props;

  const cartItems = useSelector((state: AppState) => state.cart.cartItems.find((i) => i.id === id));

  if (!cartItems) return null;

  const {
    h1,
    h2,
    h3,
    name,
    image,
    price,
    vendorName,
    displayId,
    brand,
    description,
    type,
    material,
  } = cartItems;

  const ItemCategory = () => {
    if (h1 && h2 && h3) {
      return (
        <Typography variant='caption'>
          {h1} / {h2} / {h3}
        </Typography>
      );
    } else if (h2 && !h3) {
      return (
        <Typography variant='caption'>
          {h1} / {h2}
        </Typography>
      );
    } else {
      return <Typography variant='caption'>{h1}</Typography>;
    }
  };
  return (
    <>
      <ProductDetailContainer>
        <ProductDetailCategory>
          <Box>
            <ItemCategory />
            <Typography variant='h5'>{name}</Typography>
            <Typography variant='body1'>{displayId}</Typography>
          </Box>
        </ProductDetailCategory>

        <Divider />
        <ProductDetailContainerBody>
          <ImageBox>
            <img
              src={image?.path}
              style={{ marginTop: '10px' }}
              alt='item'
              height='100px'
              width='100px'
            />
          </ImageBox>
          <Box>
            <ProductBox>
              <ProductInfoHeaders>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                  PHP {Number(price).toFixed(2)}
                </Typography>
                <Typography style={{ marginBottom: '10px' }}>Details</Typography>
                <Typography style={{ marginBottom: '5px' }}>Brand</Typography>
                <Typography style={{ marginBottom: '5px' }}>Material</Typography>
                <Typography style={{ marginBottom: '5px' }}>Type</Typography>
                <Typography style={{ marginBottom: '5px', marginTop: '10px' }}>
                  Description{' '}
                </Typography>
                <Link to={`/vendor/${cartItems.vendorId}`} style={{ color: 'inherit' }}>
                  <Typography
                    style={{ marginTop: '25px', fontWeight: 'bold', textDecoration: 'underline' }}
                  >
                    {vendorName}
                  </Typography>
                </Link>
              </ProductInfoHeaders>
              <ProductInfoDetails>
                <BrandDescription>{brand}</BrandDescription>
                <Typography style={{ marginTop: '5px' }}>{material}</Typography>
                <Typography style={{ marginTop: '5px' }}>{type}</Typography>
                <ProductDescription dangerouslySetInnerHTML={{ __html: `${description}` }} />
              </ProductInfoDetails>
            </ProductBox>
          </Box>
        </ProductDetailContainerBody>
      </ProductDetailContainer>
    </>
  );
};

export default ProductDetails;
