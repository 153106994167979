export type DynamicOrdersInputType = string | boolean | number | undefined | Array<OrderType> | OrderDetailsType;

interface DynamicOrdersStateInput<T> {
    [key: string]: T;
}

export interface OrdersStateInput extends DynamicOrdersStateInput<DynamicOrdersInputType> {}

export interface OrdersState {
    ordersLoading: boolean;
    activeSubTab: 'My Orders' | 'Track Orders';
    myOrders: Array<OrderType>;
    orderDetails: OrderDetailsType;
    orderDetailsLoading: boolean;
}

export interface OrderType {
    date: string;
    id: string;
    items: Array<OrderItemType>
    source: string;
    status: 'FAILED_DELIVERY' | 'CANCELLED' | 'RECEIVED';
    subtotal: number;
    totalAmount: number;
    totalDiscount: number;
    vendorId: string;
    vendorName: string;
}

export interface OrderItemType {
    discountPrice: number;
    image: string;
    name: string;
    price: number;
    productId: string;
    productSkuId: string;
    qty: number;
    size: string;
    skuNumber: string;
    status: 'FAILED_DELIVERY' | 'CANCELLED' | 'RECEIVED'
    subtotal: number;
    totalAmount: number;
    totalDiscount: number;
}

export interface OrderDetailsType {
    billingAddress: OrderDetailsAddress;
    customerName: string;
    items: Array<OrderItemType>;
    mobileNumber: string;
    orderDate: string;
    paymentMethod: string;
    shippingAddress: OrderDetailsAddress;
    status?: 'FAILED_DELIVERY' | 'CANCELLED' | 'RECEIVED';
    subtotal: number;
    totalAmount: number;
    totalDiscount: number;
    id: string;
    vendorName: string;
}

export interface OrderDetailsAddress {
    addressLine: string;
    city: string;
    country: string;
    customerId: string;
    dateCreated: string;
    dateUpdated: string;
    docType: string;
    id: string;
    isDeleted: boolean;
    postalCode: number;
    province: string;
    tag: string;
}

export const SET_ORDERS_STATE = 'set_orders_state';

export interface SetOrderStateInput {
    type: typeof SET_ORDERS_STATE;
    payload: OrdersStateInput;
}

export type OrdersAction =  SetOrderStateInput