import React from 'react';
import { AutocompleteKeyPair } from '../../store/system/types';
// common
import {
  InputLabelGridContainer,
  InputGridContainer,
  InputGridItemContainer,
  InputTextField,
  DropdownSearchableField,
  InputIconRadioButton,
  InputSelect,
  InputChip,
  LoginInputTextField,
  LoginDropdownSearchableField,
} from './CommonComponents';

// material
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RemoveIcon from '@material-ui/icons/Remove';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// util
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import { colors } from '../../theme/defaultTheme';

export type SymphonyInputType =
  | 'text'
  | 'password'
  | 'decoratedtext'
  | 'decoratedtextrange'
  | 'multiline'
  | 'searchabledropdown'
  | 'freeinput'
  | 'radio'
  | 'radiolist'
  | 'select';

interface SymphonyInputProps {
  id?: string;
  type: SymphonyInputType;
  value: string | Array<string> | AutocompleteKeyPair | boolean | Array<AutocompleteKeyPair>;
  label: string;
  inputAdornment?: string | JSX.Element | Array<JSX.Element>;
  disabled?: boolean;
  placeholder?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onAutocompleteChange?: (e: React.ChangeEvent<{}>, value: AutocompleteKeyPair | null) => void;
  onfreeAutocompleteChange?: (e: React.ChangeEvent<{}>, value: Array<unknown>) => void;
  autocompleteOptions?: Array<AutocompleteKeyPair>;
  disableCustomPopover?: boolean;
  richText?: boolean;
  onRadioButtonChange?: (value: boolean) => void;
  radioTrueText?: string;
  radioFalseText?: string;
  decoratedTextRangeOneId?: string;
  decoratedTextRangeTwoId?: string;
  decoratedTextRangeOneValue?: string;
  decoratedTextRangeTwoValue?: string;
  decoratedTextRangeOverrideDisabledOne?: boolean;
  decoratedTextRangeOverrideDisabledTwo?: boolean;
  decoratedTextOneChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  decoratedTextTwoChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  selectOptions?: Array<AutocompleteKeyPair>;
  selectOnchange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
  maxLength?: number;
  radioListItems?: Array<string>;
  radioListValues?: Array<string>;
  radioListOrientation?: 'horizontal' | 'vertical';
  onRadioListInput?: (val: string) => void;
  sliderValue?: Array<number>;
  sliderMin?: number;
  sliderMax?: number;
  sliderOnChange?: (e: any, newValue: number | Array<number>) => void;
  login?: boolean;
}

const Input = (props: SymphonyInputProps) => {
  const [passVisible, setPassVisible] = React.useState(false);
  const {
    id,
    label,
    value,
    inputAdornment,
    richText,
    type,
    placeholder,
    disabled,
    autocompleteOptions,
    disableCustomPopover,
    radioFalseText,
    radioTrueText,
    radioListOrientation,
    radioListItems,
    radioListValues,
    decoratedTextRangeOneId,
    decoratedTextRangeTwoId,
    decoratedTextRangeOneValue,
    decoratedTextRangeTwoValue,
    decoratedTextRangeOverrideDisabledOne,
    decoratedTextRangeOverrideDisabledTwo,
    login,
    decoratedTextOneChange,
    decoratedTextTwoChange,
    onChange,
    onKeyDown,
    onBlur,
    onAutocompleteChange,
    onfreeAutocompleteChange,
    onRadioButtonChange,
    onRadioListInput,
  } = props;
  const searchableDropdownValueIndex = findIndex(props.autocompleteOptions, {
    value: value as string,
  });
  const searchableDropdownValue =
    searchableDropdownValueIndex > -1 && autocompleteOptions
      ? autocompleteOptions[searchableDropdownValueIndex]
      : null;
  return (
    <InputGridContainer container={true}>
      <InputLabelGridContainer className='symphony-input-label' item={true} xs={12}>
        {label}
      </InputLabelGridContainer>
      <InputGridItemContainer item={true} xs={12}>
        {type === 'text' && !login && (
          <InputTextField
            id={id}
            type='text'
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            fullWidth={true}
            disabled={disabled}
            placeholder={placeholder}
            value={value as string}
            autoComplete='none'
            inputProps={{ maxLength: props.maxLength ? props.maxLength : 999 }}
          />
        )}
        {type === 'text' && login && (
          <LoginInputTextField
            id={id}
            type='text'
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            fullWidth={true}
            disabled={disabled}
            placeholder={placeholder}
            value={value as string}
            autoComplete='none'
            inputProps={{ maxLength: props.maxLength ? props.maxLength : 999 }}
          />
        )}
        {type === 'password' && !login && (
          <InputTextField
            id={id}
            type={passVisible ? 'text' : 'password'}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            fullWidth={true}
            disabled={disabled}
            placeholder={placeholder}
            value={value as string}
            autoComplete='new-password'
            InputProps={{
              endAdornment: (
                <InputAdornment className={disabled ? 'disabled-adornment' : ''} position='end'>
                  {passVisible ? (
                    <VisibilityIcon onClick={() => setPassVisible(false)} />
                  ) : (
                    <VisibilityOffIcon onClick={() => setPassVisible(true)} />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}

        {type === 'password' && login && (
          <LoginInputTextField
            id={id}
            type={passVisible ? 'text' : 'password'}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            fullWidth={true}
            disabled={disabled}
            placeholder={placeholder}
            value={value as string}
            autoComplete='new-password'
            InputProps={{
              endAdornment: (
                <InputAdornment className={disabled ? 'disabled-adornment' : ''} position='end'>
                  {passVisible ? (
                    <VisibilityIcon
                      htmlColor={colors.primary}
                      onClick={() => setPassVisible(false)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      htmlColor={colors.primary}
                      onClick={() => setPassVisible(true)}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
        {type === 'decoratedtext' && (
          <InputTextField
            id={id}
            type='text'
            onKeyDown={onKeyDown}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth={true}
            disabled={disabled}
            placeholder={placeholder}
            value={value as string}
            InputProps={{
              startAdornment: (
                <InputAdornment className={disabled ? 'disabled-adornment' : ''} position='start'>
                  {inputAdornment}
                </InputAdornment>
              ),
            }}
          />
        )}
        {type === 'decoratedtextrange' && (
          <>
            <InputTextField
              id={decoratedTextRangeOneId}
              type='text'
              onChange={decoratedTextOneChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              fullWidth={true}
              disabled={disabled || decoratedTextRangeOverrideDisabledOne}
              placeholder={placeholder}
              value={(decoratedTextRangeOneValue as string) || ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    className={
                      disabled || decoratedTextRangeOverrideDisabledOne ? 'disabled-adornment' : ''
                    }
                    position='start'
                  >
                    {inputAdornment}
                  </InputAdornment>
                ),
              }}
            />
            <RemoveIcon style={{ margin: '0 16px' }} htmlColor='#A1A1A1' />
            <InputTextField
              id={decoratedTextRangeTwoId}
              type='text'
              onChange={decoratedTextTwoChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              fullWidth={true}
              disabled={disabled || decoratedTextRangeOverrideDisabledTwo}
              placeholder={placeholder}
              value={(decoratedTextRangeTwoValue as string) || ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    className={
                      disabled || decoratedTextRangeOverrideDisabledTwo ? 'disabled-adornment' : ''
                    }
                    position='start'
                  >
                    {inputAdornment}
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        {type === 'multiline' && !richText && (
          <InputTextField
            id={id}
            multiline={true}
            value={value as string}
            type='text'
            onKeyDown={onKeyDown}
            onChange={onChange}
            fullWidth={true}
            disabled={disabled}
            placeholder={placeholder}
          />
        )}
        {type === 'select' && (
          <InputSelect
            id={id}
            disabled={disabled}
            value={props.value}
            inputProps={{ 'aria-label': 'With out label' }}
            onChange={props.selectOnchange}
            displayEmpty={true}
          >
            {map(props.selectOptions, (option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                id={option.value.replace(/ +/g, '_')}
              >
                {option.label}
              </MenuItem>
            ))}
          </InputSelect>
        )}
        {type === 'searchabledropdown' && !login && (
          <Autocomplete
            id={id}
            value={searchableDropdownValue}
            options={autocompleteOptions || []}
            renderOption={(option) => option.label}
            getOptionLabel={(option) => option.label}
            fullWidth={true}
            renderInput={(props) => (
              <DropdownSearchableField {...props} placeholder={placeholder} />
            )}
            onChange={onAutocompleteChange}
            PopperComponent={
              !disableCustomPopover
                ? (props) => {
                    const { className, anchorEl, style, ...rest } = props;
                    return (
                      <Box
                        {...rest}
                        id={`${id}-autocomplete-list`}
                        style={{
                          ...props.style,
                          position: 'absolute',
                          top: 46,
                          width: '100%',
                          left: 0,
                          right: 0,
                          zIndex: 1300,
                        }}
                      >
                        {props.children}
                      </Box>
                    );
                  }
                : undefined
            }
          />
        )}
        {type === 'searchabledropdown' && login && (
          <Autocomplete
            id={id}
            value={searchableDropdownValue}
            options={autocompleteOptions || []}
            renderOption={(option) => option.label}
            getOptionLabel={(option) => option.label}
            fullWidth={true}
            renderInput={(props) => (
              <LoginDropdownSearchableField {...props} placeholder={placeholder} />
            )}
            onChange={onAutocompleteChange}
            PopperComponent={
              !disableCustomPopover
                ? (props) => {
                    const { className, anchorEl, style, ...rest } = props;
                    return (
                      <Box
                        {...rest}
                        id={`${id}-autocomplete-list`}
                        style={{
                          ...props.style,
                          position: 'absolute',
                          top: 46,
                          width: '100%',
                          left: 0,
                          right: 0,
                          zIndex: 1300,
                        }}
                      >
                        {props.children}
                      </Box>
                    );
                  }
                : undefined
            }
          />
        )}
        {props.type === 'freeinput' && (
          <Autocomplete
            id={id}
            multiple={true}
            value={(value as Array<string>) || []}
            options={autocompleteOptions ? map(autocompleteOptions, (o) => o.label) : []}
            freeSolo={true}
            renderTags={(value: Array<unknown>, getTagProps) =>
              value.map((option: unknown, index: number) => (
                <InputChip
                  variant='outlined'
                  label={option as string}
                  {...getTagProps({ index })}
                />
              ))
            }
            style={{ width: '100%' }}
            filterSelectedOptions={true}
            onChange={onfreeAutocompleteChange}
            renderInput={(props) => (
              <DropdownSearchableField {...props} placeholder={placeholder} />
            )}
          />
        )}
        {type === 'radio' && (
          <Box display='inline'>
            <InputIconRadioButton
              id={`${id}-radio-true`}
              style={{ marginRight: 8 }}
              onClick={() => {
                if (onRadioButtonChange) onRadioButtonChange(true);
              }}
            >
              {props.value === true ? (
                <CheckCircleIcon htmlColor='#4C89F5' />
              ) : (
                <RadioButtonUncheckedIcon htmlColor='#969696' />
              )}
            </InputIconRadioButton>
            <Box display='inline' marginRight='16px'>
              {radioTrueText ? radioTrueText : 'Yes'}
            </Box>
            <InputIconRadioButton
              id={`${id}-radio-false`}
              style={{ marginRight: 8 }}
              onClick={() => {
                if (onRadioButtonChange) onRadioButtonChange(false);
              }}
            >
              {props.value === false ? (
                <CheckCircleIcon htmlColor='#4C89F5' />
              ) : (
                <RadioButtonUncheckedIcon htmlColor='#969696' />
              )}
            </InputIconRadioButton>
            <Box display='inline' marginRight='16px'>
              {radioFalseText ? radioFalseText : 'No'}
            </Box>
          </Box>
        )}
        {type === 'radiolist' && (
          <Box
            display='flex'
            flexDirection={
              radioListOrientation && radioListOrientation === 'vertical' ? 'column' : 'row'
            }
          >
            {radioListItems &&
              radioListValues &&
              map(radioListItems, (i) => (
                <Box key={`${i}-item`} display='flex' alignItems='center'>
                  <InputIconRadioButton
                    id={`radio-list-${i.toLowerCase().replace(/ +/g, '_')}`}
                    onClick={() => {
                      if (onRadioListInput) onRadioListInput(i);
                    }}
                  >
                    {radioListValues.includes(i) ? (
                      <CheckCircleIcon htmlColor={colors.primary} />
                    ) : (
                      <RadioButtonUncheckedIcon htmlColor='#969696' />
                    )}
                  </InputIconRadioButton>
                  <Box display='inline' marginRight='16px'>
                    {i}
                  </Box>
                </Box>
              ))}
          </Box>
        )}
      </InputGridItemContainer>
    </InputGridContainer>
  );
};

export default Input;
