import { 
    VendorAction,
    VendorStateInput,
    SET_VENDOR_STATE
} from "./types";
import { AppThunk } from '..'; 
import { SET_SYSTEM_STATE } from "../system/types";
import { fetchCartItems } from '../cart/actions';

import axios from 'axios';
import map from 'lodash/map';

const API_URL = process.env.REACT_APP_API_URL;

export const setVendorState = (input: VendorStateInput) : VendorAction => {
    return {
        type: SET_VENDOR_STATE,
        payload: input
    }
}

export const getVendorDetails = (vendorId: string) : AppThunk => {
    return async (dispatch, getState) => {
        dispatch({ 
            type: SET_VENDOR_STATE,
            payload: {
                vendorLoading: true
            }
        })
        try {
            const vendorDetails = await axios.get(`${API_URL}/user?id=${vendorId}`)
            const isFollowed =  await axios.get(`${API_URL}/user/follower/check/${getState().system.session?.userDetails.id}_${vendorId}`);
            const bestSeller = await axios.get(`${API_URL}/v1/users/${vendorId}/best-seller?customerId=${getState().system.session?.userDetails.id}`);
            const allProducts = await axios.get(`${API_URL}/v1/products?vendorId=${vendorId}&customerId=${getState().system.session?.userDetails.id}`)
            const catRes = await axios.get(`${API_URL}/product/category/h1?vendorId=${vendorId}`)


            if (vendorDetails.status === 200 && isFollowed.status === 200 && bestSeller.status === 200 && allProducts.status === 200 && catRes.status === 200) {
                dispatch({
                    type: SET_VENDOR_STATE,
                    payload: {
                        vendorBanner: vendorDetails.data.banner,
                        vendorName: vendorDetails.data.companyName,
                        vendorIsFollowed: isFollowed.data.isFollowed,
                        vendorBestSellers: bestSeller.data,
                        vendorAllProducts: allProducts.data,
                        vendorCategories: map(catRes.data, (c) => c.h1)
                    }
                })
            }
            
        } catch (e) {
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        } finally {
            dispatch({ 
                type: SET_VENDOR_STATE,
                payload: {
                    vendorLoading: false
                }
            })
        }
    }
}

export const onPressFollowButton = (vendorId: string, isFollowed: boolean) : AppThunk => {
    return async (dispatch, getState) => {
        const customerId =  getState().system.session?.userDetails.id;
        const data = { customerId, vendorId }
        let result;
        try {
            if (!isFollowed) {
                result = await axios.post(`${API_URL}/user/follower`, data)
            } else {
                result = await axios.delete(`${API_URL}/user/follower/${customerId}_${vendorId}`)
            }
            if (result && (result.status === 200 || result.status === 204)  ) {
                dispatch({ 
                    type: SET_VENDOR_STATE, 
                    payload: {
                        vendorIsFollowed: result.status === 200 ? true : false
                    } 
                })
            }
        } catch (e) {
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        }
    }
}

export const getProductDetails = (productId: string) : AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_SYSTEM_STATE,
            payload: {
                productDialogDetailsLoading: true,
                productDialogIsOpen: true
            }
        })
        try {
            const res = await axios.get(`${API_URL}/v1/products/${productId}?view=detailed`);
            if (res.status === 200) {
                dispatch({
                    type: SET_SYSTEM_STATE,
                    payload: {
                        productDialogCategory: `${res.data.h1}${res.data.h2 ? ` / ${res.data.h2}` : '' } ${res.data.h3 ? ` / ${res.data.h3}` : ''}`,
                        productDialogCode: res.data.displayId,
                        productDialogPrice: res.data.price,
                        productDialogDiscountPrice: res.data.discountPrice,
                        productDialogBrand: res.data.brand,
                        productDialogMaterial: res.data.material,
                        productDialogType: res.data.type,
                        productDialogDescription: res.data.description,
                        productDialogImage: res.data.media[0].path,
                        productDialogIsFavorite: false,
                        productDialogVendorId: res.data.vendorId,
                        productDialogVendorName: res.data.vendorName,
                        productDialogOnClickCloseButton: () => {
                            dispatch({
                                type: SET_SYSTEM_STATE,
                                payload: {
                                    productDialogIsOpen: false
                                }
                            })
                        },
                        productDialogOnClickAddToCartButton: () => {
                            dispatch(addToCart(productId, res.data.skuId, res.data.vendorId))
                        }
                    }
                })
            }
        } catch (e) {
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        } finally {
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    productDialogDetailsLoading: false 
                }
            })
        }
    }
}

export const addToCart = (productId: string, productSkuId: string, vendorId: string): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const res = await axios.post(`${API_URL}/bag`, {
                productId, productSkuId, vendorId,
                qty: 1,
                customerId: getState().system.session?.userDetails.id
            })
            if(res.status === 200) {
                dispatch({
                    type: SET_SYSTEM_STATE,
                    payload: {
                        snackBarIsOpen: true,
                        snackBarMessage: 'Added to cart',
                        snackBarType: 'success'
                    }
                })
                dispatch(fetchCartItems())
            } 
        }
        catch (e) {
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        }
    }
}

export const getAllProducts = (vendorId: string) : AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_VENDOR_STATE,
            payload: {
                vendorAllProductsLoading: true
            }
        })
        try {
            const allProducts = await axios.get(`${API_URL}/v1/products?vendorId=${vendorId}&customerId=${getState().system.session?.userDetails.id}`)
            if (allProducts.status === 200) {
                dispatch({
                    type: SET_VENDOR_STATE,
                    payload: {
                        vendorAllProducts: allProducts.data
                    }
                })
            }
        } catch (e) {
            console.log(e.toString());
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        } finally {
            dispatch({
                type: SET_VENDOR_STATE,
                payload: {
                    vendorAllProductsLoading: false
                }
            })
        }
    }
}

export const getProductsByCategory = (vendorId: string, h1: string) : AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_VENDOR_STATE,
            payload: {
                vendorAllProductsLoading: true
            }
        })
        try {
            const res = await axios.get(`${API_URL}/v1/products?h1=${encodeURIComponent(h1)}&customerId=${getState().system.session?.userDetails.id}&vendorId=${vendorId}&view=list`);
            if (res.status === 200) {
                dispatch({
                    type: SET_VENDOR_STATE,
                    payload: {
                        vendorAllProducts: res.data
                    }
                })
            }
        } catch (e) {
            console.log(e.toString());
        } finally {
            dispatch({
                type: SET_VENDOR_STATE,
                payload: {
                    vendorAllProductsLoading: false
                }
            })
        }
    }
}

