import { orderBy } from 'lodash';

import apiClient, { endpoints } from '../../utils/apiClient';
import { CustomerAddressInputs } from './my-account/my-account-utils';
import { CustomerAddress } from './my-account/types';
import { Order, OrderDetail } from './order-types';

export const getCustomerOrders = async (userId: string) => {
  const url = `${endpoints.customerOrders}/${userId}/All`;

  const response = await apiClient.get<Order[]>(url);

  const customerOrders = orderBy(
    response.data.filter((d) => ['CANCELLED', 'FAILED_DELIVERY', 'RECEIVED'].includes(d.status)),
    ['date'],
    ['desc']
  );

  return customerOrders;
};

export const getCustomerActiveOrders = async (userId: string) => {
  const url = `${endpoints.customerOrders}/${userId}/All`;

  const response = await apiClient.get<Order[]>(url);

  const orders = orderBy(response.data, ['date'], ['desc']);

  return orders;
};

export const getCustomerOrderById = async (orderId: string, vendorName: string) => {
  const url = `${endpoints.customerOrderDetail}/${orderId}`;

  const order = await apiClient.get<OrderDetail>(url);

  return { ...order.data, vendorName, id: orderId };
};

export const getCustomerAddress = async (customerId: string) => {
  const url = `${endpoints.customerAddress}/${customerId}`;

  const response = await apiClient.get<CustomerAddress[]>(url);

  return orderBy(response.data, ['dateCreated'], ['desc']);
};

export const getCustomerAddressById = async (customerId: string, addressId: string) => {
  const url = `${endpoints.customerAddress}/${customerId}`;

  if (!addressId) return undefined;

  const response = await apiClient.get<CustomerAddress[]>(url);

  const userResponse = await apiClient.get(`${endpoints.getUserById}/${customerId}`);

  const address = response.data.find((a) => a.id === addressId) as unknown as CustomerAddressInputs;

  address.isDefaultBillingAddress = addressId === userResponse.data.billingAddressId;
  address.isDefaultShippingAddress = addressId === userResponse.data.shippingAddressId;

  return address ? address : undefined;
};

export const getCountries = async () => {
  const countries = await apiClient.get<any[]>(endpoints.countries);
  return countries.data.map((d) => d.name) as string[];
};

export const createAddress = async (
  userId: string,
  addressFields: CustomerAddressInputs
): Promise<string> => {
  try {
    const { isDefaultBillingAddress, isDefaultShippingAddress, ...address } = addressFields;

    if (isNaN(parseInt(address.postalCode))) throw new Error('Invalid postal code');

    const addressData = {
      customerId: userId,
      ...address,
      postalCode: parseInt(address.postalCode),
    };

    const addressResponse = await apiClient.post(endpoints.createAddress, addressData);

    const newAddressId = addressResponse.data.id;

    // update the user
    if (!isDefaultBillingAddress && !isDefaultShippingAddress) return newAddressId;

    const updateUserFields: any = {};

    if (isDefaultBillingAddress) updateUserFields.billingAddressId = newAddressId;
    if (isDefaultShippingAddress) updateUserFields.shippingAddressId = newAddressId;

    await apiClient.put(endpoints.updateUser, {
      id: userId,
      data: updateUserFields,
    });

    return newAddressId;
  } catch (error) {
    throw error;
  }
};

export const updateAddress = async (
  userId: string,
  addressId: string,
  addressFields: CustomerAddressInputs
) => {
  const { isDefaultBillingAddress, isDefaultShippingAddress, ...address } = addressFields;

  if (isNaN(parseInt(address.postalCode))) throw new Error('Invalid postal code');

  const addressData = {
    customerId: userId,
    ...address,
    postalCode: parseInt(address.postalCode),
  };

  await apiClient.put(endpoints.updateAddress, {
    id: addressId,
    data: addressData,
  });

  // update the user
  if (!isDefaultBillingAddress && !isDefaultShippingAddress) return userId;

  const updateUserFields: any = {};

  if (isDefaultBillingAddress) updateUserFields.billingAddressId = addressId;
  if (isDefaultShippingAddress) updateUserFields.shippingAddressId = addressId;

  const userUpdateResponse = await apiClient.put(endpoints.updateUser, {
    id: userId,
    data: updateUserFields,
  });

  return userUpdateResponse.data.id;
};
