import { Link } from 'react-router-dom';
import { RegisterInformationType, ErrorInputs } from '../../../store/auth/types';
import { AutocompleteKeyPair } from '../../../store/system/types';
import { useSelector } from 'react-redux';
// Global Components
import Input from '../../common/Input';

// Local Components
import { 
    Tabs,
    ErrorText,
    TabsText,
    ProceedButton,
    TabsContainer,
    RegisterContent,
    TabsMainContainer,
    RadioInputContainer,
    RegisterInputContainer,
    SuccessText
} from './RegisterComponents';

// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// Utils
import map from 'lodash/map';
import { AppState } from '../../../store';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getSgCities, sgProvinces } from '../../../utils/helpers';

interface RegisterInformationProps {
    registerLoading: boolean;
    registerCities: Array<string>;
    registerUserTypes: Array<string>;
    registerCountries: Array<string>;
    registerProvinces: Array<string>;
    registerInformation: RegisterInformationType;
    registerErrorInputs: ErrorInputs;
    registerSelectedUserType: ['Customer'] | ['Vendor'];
    onPressProceedButton: () => void;
    onRadioListInput: (val: string) => void;
    onChangeRegisterInformationinput: (property: string, value: string) => void;
}

const RegisterInformation = (props: RegisterInformationProps) => {

    const passwordData = useSelector((state:AppState) => state.auth.registerInformation.password)
    const { 
        onRadioListInput, 
        registerLoading, 
        registerUserTypes, 
        registerSelectedUserType, 
        registerCountries, 
        registerCities, 
        registerProvinces, 
        registerInformation, 
        registerErrorInputs,
        onPressProceedButton,
        onChangeRegisterInformationinput
    } = props;
    const numberRegex: RegExp = /^[\d-()+]+$/;
    const hasLowerCase = new RegExp(/^(?=.*[a-z])/);
    const hasUpperCase = new RegExp(/^(?=.*[A-Z])/);
    const hasNumber = new RegExp(/^(?=.*[0-9])/);
    const hasSpecialCharac = new RegExp(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])/);
    const _onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onPressProceedButton();
        }
    }
    const x = useSelector((state:AppState)=> state.auth.registerInformation.countryCode)
    const sgProv = useSelector((state:AppState)=> state.auth.registerInformation.province)
    const coun = useSelector((state:AppState)=> state.auth.registerInformation.country)
    const sgP = sgProvinces;
    const sgCities = getSgCities(sgProv);
    const LengthCaseChecker = () => passwordData.length <= 36 && passwordData.length >=8 ? <SuccessText>Length of the password 8-36 characters. <CheckCircleIcon fontSize='small' /></SuccessText> : <ErrorText>Length of the password 8-36 characters</ErrorText>
    const LowerCaseChecker =() => hasLowerCase.test(passwordData) ? <SuccessText>The password must contain at least one lower case letter. <CheckCircleIcon fontSize='small' /></SuccessText> : <ErrorText>The password must contain at least one lower case letter</ErrorText>
    const UpperCaseChecker =() => hasUpperCase.test(passwordData) ?  <SuccessText>The password must contain at least one upper case letter. <CheckCircleIcon fontSize='small' /></SuccessText> : <ErrorText>The password must contain at least one upper case letter</ErrorText>
    const NumberCaseChecker =() => hasNumber.test(passwordData)  ?  <SuccessText>The password must contain at least one number. <CheckCircleIcon fontSize='small' /></SuccessText> : <ErrorText>The password must contain at least one number</ErrorText>
    const SpecialCaseChecker =() => hasSpecialCharac.test(passwordData) ? <SuccessText>The password must contain at least one special character. <CheckCircleIcon fontSize='small' /></SuccessText> : <ErrorText>The password must contain at least one special character</ErrorText>
    const countryVal = useSelector((state:AppState)=> state.auth.registerInformation.country)
    const cCode = useSelector((state:AppState)=> state.auth.registerInformation.countryCode)

    return (
        <RegisterContent>
            <TabsMainContainer>
                <TabsContainer>
                    <Tabs id="register-logintab-btn" disableFocusRipple disableTouchRipple disableRipple>
                        <Link to="/login">Login</Link>
                    </Tabs>
                </TabsContainer>
                <TabsContainer>
                    <Tabs id="register-registertab-btn" disableFocusRipple disableTouchRipple disableRipple>
                        <TabsText style={{ fontWeight: 'bold', opacity: 1 }}>Register</TabsText>
                    </Tabs>
                </TabsContainer>
            </TabsMainContainer>
            <RadioInputContainer>
                <Input 
                    type="radiolist"
                    label=""
                    value=""
                    id="register-radiolist-btn"
                    radioListOrientation="horizontal"
                    radioListItems={registerUserTypes}
                    radioListValues={registerSelectedUserType}
                    onRadioListInput={onRadioListInput}
                    onKeyDown={_onEnterPress}
                />
            </RadioInputContainer>
            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                    <Box style={{ width: '48%' }}>
                        <RegisterInputContainer>
                            <Input 
                                id="register-firstname-input"
                                type="text"
                                label=""
                                value={registerInformation.firstName}
                                onChange={(e) => onChangeRegisterInformationinput('firstName', e.target.value)}
                                placeholder="First name"
                                login={true}
                                onKeyDown={_onEnterPress}
                            />
                        </RegisterInputContainer>
                    </Box>
                    <Box style={{ width: '48%' }}>
                        <RegisterInputContainer>
                            <Input 
                                id="register-lastname-input"
                                type="text"
                                label=""
                                value={registerInformation.lastName}
                                onChange={(e) => onChangeRegisterInformationinput('lastName', e.target.value)}
                                placeholder="Last name"
                                login={true}
                                onKeyDown={_onEnterPress}
                            />
                        </RegisterInputContainer>
                    </Box>
                </Box>
                { registerErrorInputs.name.error &&
                    <ErrorText>{registerErrorInputs.name.message}</ErrorText>
                }
            </Box>
            { registerSelectedUserType[0] === 'Vendor' &&
                <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                    <RegisterInputContainer>
                        <Input 
                            id="register-companyname-input"
                            type="text"
                            label=""
                            value={registerInformation.companyName}
                            onChange={(e) => onChangeRegisterInformationinput('companyName', e.target.value)}
                            placeholder="Company Name"
                            login={true}
                            onKeyDown={_onEnterPress}
                        />
                    </RegisterInputContainer>
                    { registerErrorInputs.companyName.error &&
                        <ErrorText>{registerErrorInputs.companyName.message}</ErrorText>
                    }
                </Box>
            }
            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                <RegisterInputContainer>
                    <Input 
                        id="register-email-input"
                        type="text"
                        label=""
                        value={registerInformation.email}
                        onChange={(e) => onChangeRegisterInformationinput('email', e.target.value)}
                        placeholder="Email"
                        login={true}
                        onKeyDown={_onEnterPress}
                    />
                </RegisterInputContainer>
                { registerErrorInputs.email.error &&
                    <ErrorText>{registerErrorInputs.email.message}</ErrorText>
                }
            </Box>
            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                <RegisterInputContainer>
                    <Input 
                        id="register-password-input"
                        type="password"
                        label=""
                        value={registerInformation.password}
                        onChange={(e) => onChangeRegisterInformationinput('password', e.target.value)}
                        placeholder="Password"
                        login={true}
                        onKeyDown={_onEnterPress}
                    />
                </RegisterInputContainer>
                {/* { registerErrorInputs.password.error &&
                    <ErrorText>{registerErrorInputs.password.message}</ErrorText>
                } */}
                {passwordData ? <Box display="flex" style={{flexDirection:"column"}}>
                    <LengthCaseChecker />
                    <LowerCaseChecker />
                    <UpperCaseChecker />
                    <NumberCaseChecker />
                    <SpecialCaseChecker />
                </Box>:''}
            </Box>
            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                <RegisterInputContainer>
                    <Input 
                        id="register-confirmpassword-input"
                        type="password"
                        label=""
                        value={registerInformation.confirmPassword}
                        onChange={(e) => onChangeRegisterInformationinput('confirmPassword', e.target.value)}
                        placeholder="Confirm Password"
                        login={true}
                        onKeyDown={_onEnterPress}
                    />
                </RegisterInputContainer>
                { registerErrorInputs.confirmPassword.error &&
                    <ErrorText>{registerErrorInputs.confirmPassword.message}</ErrorText>
                }
            </Box>
            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                    <Box style={{ width: '28%' }}>
                        <RegisterInputContainer>
                            <Input 
                                id="register-countrycode-dropdown"
                                type="searchabledropdown"
                                label=""
                                disabled={true}
                                value={registerInformation.countryCode}
                                autocompleteOptions={[{ label: '+63', value: '+63'},{ label: '+65', value: '+65'}]}
                                onAutocompleteChange={(e: React.ChangeEvent<{}>, v: AutocompleteKeyPair | null) => {onChangeRegisterInformationinput('countryCode', v ? v.value : '')}}
                                placeholder="+63"
                                login={true}
                                onKeyDown={_onEnterPress}
                            />
                        </RegisterInputContainer>
                    </Box>
                    <Box style={{ width: '70%' }}>
                        <RegisterInputContainer>
                            <Input 
                                id="register-mobilenumber-input"
                                type="text"
                                label=""
                                value={registerInformation.mobileNumber}
                                onChange={(e) => {
                                    if (numberRegex.test(e.target.value) || e.target.value.length === 0) {
                                        onChangeRegisterInformationinput('mobileNumber', e.target.value)
                                    }
                                }}
                                disabled={!x?true:false}
                                placeholder="Mobile Number"
                                login={true}
                                onKeyDown={_onEnterPress}
                            />
                        </RegisterInputContainer>
                    </Box>
                </Box>
                { registerErrorInputs.mobileNumber.error &&
                    <ErrorText>{registerErrorInputs.mobileNumber.message}</ErrorText>
                }
            </Box>
            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                    <Box style={{ width: '28%' }}>
                        <RegisterInputContainer>
                            <Input 
                                id="register-areacode-input"
                                type="text"
                                label=""
                                value={registerInformation.areaCode}
                                onChange={(e) => {
                                    if (numberRegex.test(e.target.value) || e.target.value.length === 0) {
                                        onChangeRegisterInformationinput('areaCode', e.target.value)
                                    }
                                }}
                                placeholder="Area Code"
                                login={true}
                                onKeyDown={_onEnterPress}
                            />
                        </RegisterInputContainer>
                    </Box>
                    <Box style={{ width: '70%' }}>
                        <RegisterInputContainer>
                            <Input 
                                id="register-landlinenumber-input"
                                type="text"
                                label=""
                                value={registerInformation.landLineNumber}
                                onChange={(e) => {
                                    if (numberRegex.test(e.target.value) || e.target.value.length === 0) {
                                        onChangeRegisterInformationinput('landLineNumber', e.target.value)
                                    }
                                }}
                                placeholder="Landline Number"
                                login={true}
                                onKeyDown={_onEnterPress}
                            />
                        </RegisterInputContainer>
                    </Box>
                </Box>
                { registerErrorInputs.landLineNumber.error &&
                    <ErrorText>{registerErrorInputs.landLineNumber.message}</ErrorText>
                }
            </Box>
            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                <RegisterInputContainer>
                    <Input 
                        id="register-address-input"
                        type="text"
                        label=""
                        value={registerInformation.address}
                        onChange={(e) => onChangeRegisterInformationinput('address', e.target.value)}
                        placeholder="Address"
                        login={true}
                        onKeyDown={_onEnterPress}
                    />
                </RegisterInputContainer>
                { registerErrorInputs.address.error &&
                    <ErrorText>{registerErrorInputs.address.message}</ErrorText>
                }
            </Box>
            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                    <Box style={{ width: '48%' }}>
                        <RegisterInputContainer>
                            <Input 
                                id="register-country-dropdown"
                                type="searchabledropdown"
                                label=""
                                value={registerInformation.country}
                                //autocompleteOptions={countrySelection}
                                disabled={true}
                                autocompleteOptions={cCode === "+63" ? [{label:"Philippines",value:"Philippines"}]: cCode === "+65" ? [{label:"Singapore",value:"Singapore"}]:[]}

                                //autocompleteOptions={map(registerCountries, (country) => ({ label: country, value: country }) )}
                                onAutocompleteChange={(e: React.ChangeEvent<{}>, v: AutocompleteKeyPair | null) => onChangeRegisterInformationinput('country', v ? v.value : '')}
                                placeholder="Country"
                                login={true}
                                onKeyDown={_onEnterPress}
                            />
                        </RegisterInputContainer>
                    </Box>
                    <Box style={{ width: '48%' }}>
                        <RegisterInputContainer>
                            <Input 
                                id="register-province-dropdown"
                                type="searchabledropdown"
                                label=""
                                value={registerInformation.province}
                                autocompleteOptions={ cCode === '+65' && countryVal === 'Singapore' ?
                                map(sgP, (province) => ({ label: province, value: province }) ): 
                                cCode==="+63" && countryVal==="Philippines"?
                                map(registerProvinces, (province) => ({ label: province, value: province }) ):
                                []}
                                onAutocompleteChange={(e: React.ChangeEvent<{}>, v: AutocompleteKeyPair | null) => onChangeRegisterInformationinput('province', v ? v.value : '')}
                                placeholder="Province"
                                login={true}
                                onKeyDown={_onEnterPress}
                            />
                        </RegisterInputContainer>
                    </Box>
                </Box>
                { registerErrorInputs.countryAndProvince.error &&
                    <ErrorText>{registerErrorInputs.countryAndProvince.message}</ErrorText>
                }
            </Box>
            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                    <Box style={{ width: '48%' }}>
                        <RegisterInputContainer>
                            <Input 
                                id="register-city-dropdown"
                                type="searchabledropdown"
                                label=""
                                value={registerInformation.city}
                                autocompleteOptions={ coun === 'Singapore' && cCode === '+65' ?
                                map(sgCities, (city) => ({ label: city, value: city }) ):
                                coun === 'Philippines' && cCode === '+63'?
                                map(registerCities, (city) => ({ label: city, value: city }) ):[]}
                                onAutocompleteChange={(e: React.ChangeEvent<{}>, v: AutocompleteKeyPair | null) => onChangeRegisterInformationinput('city', v ? v.value : '')}
                                placeholder="City"
                                login={true}
                                onKeyDown={_onEnterPress}
                            />
                        </RegisterInputContainer>
                    </Box>
                    <Box style={{ width: '48%' }}>
                        <RegisterInputContainer>
                            <Input 
                                id="register-postal-input"
                                type="text"
                                label=""
                                value={registerInformation.postal}
                                onChange={(e) => {
                                    if (numberRegex.test(e.target.value) || e.target.value.length === 0) {
                                        onChangeRegisterInformationinput('postal', e.target.value)
                                    }
                                }}
                                placeholder="Postal"
                                login={true}
                                onKeyDown={_onEnterPress}
                            />
                        </RegisterInputContainer>
                    </Box>
                </Box>
                { registerErrorInputs.cityAndPostal.error &&
                    <ErrorText>{registerErrorInputs.cityAndPostal.message}</ErrorText>
                }
            </Box>
            <ProceedButton id="register-proceed-btn" onClick={onPressProceedButton}>
                { registerLoading ?
                    <CircularProgress size={20} style={{ color: '#FFF' }} /> : <>Proceed</>
                }
            </ProceedButton>
        </RegisterContent>
    )
}

export default RegisterInformation;