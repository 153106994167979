import { useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CartIcon from '@material-ui/icons/ShoppingCart';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import FormButton from '../../common/FormButton';
import useLoggedInUser from '../hooks/useLoggedInUser';
import { formatToCurrency } from '../../../utils/helpers';

import { FavoriteItem } from './types';
import { appColors } from '../../../utils/ui-utils';
import { useHistory } from 'react-router-dom';
import { colors } from '../../../theme/defaultTheme';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../../store/product/actions';
import FavoriteDetails from './FavoriteDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      border: `1px solid ${appColors.mediumGray}`,
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: 0,
        marginBottom: 0,
      },
    },
    name: {
      fontSize: 17,
      fontWeight: 600,
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    image: {
      width: 120,
      height: 120,
      objectFit: 'cover',

      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: 180,
      },
    },
    details: {
      marginLeft: theme.spacing(4),

      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        padding: theme.spacing(2),
      },
    },
    buttonContainer: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      gap: 12,

      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        marginTop: 'auto',
        alignItems: 'flex-end',
        padding: theme.spacing(2),

        '& > button': {
          width: '100%',
        },
      },
    },
    removeBtn: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  })
);

function FavoriteItemCard({ favorite }: { favorite: FavoriteItem }) {
  const classes = useStyles();
  const history = useHistory();
  const user = useLoggedInUser();

  const dispatch = useDispatch();

  const [detailsModalShown, setDetailsModalShown] = useState(false);

  const isSG = user?.countryCode === 'SG';

  const openDeletePrompt = () => history.push(`/me/favorites/delete/${favorite.id}`);

  const handleAddToCart = () => {
    const { id, vendorId, productSkuId } = favorite;
    if (!user) {
      history.push('/login');
    } else {
      dispatch(addToCart(id, productSkuId, vendorId));
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <img
          className={classes.image}
          loading='lazy'
          src={favorite.image.path}
          alt={favorite.name}
        />
        <Box className={classes.details}>
          <Typography style={{ color: colors.gray }}>{favorite.h1}</Typography>
          <Typography
            component='h2'
            className={classes.name}
            onClick={() => setDetailsModalShown(true)}
          >
            {favorite.name}
          </Typography>
          <Typography style={{ color: colors.gray }}>Size {favorite.size}</Typography>
          <Typography
            style={{ color: colors.darkest, fontSize: 16, fontWeight: 'bold', marginTop: '12px' }}
          >
            {formatToCurrency(favorite.price, !isSG)}
          </Typography>
          {favorite.discountPrice > 0 && (
            <Typography style={{ color: colors.gray, textDecoration: 'line-through' }}>
              {formatToCurrency(favorite.discountPrice, !isSG)}
            </Typography>
          )}
        </Box>
        <Box className={classes.buttonContainer}>
          <FormButton onClick={openDeletePrompt} className={`btn-text ${classes.removeBtn}`}>
            Remove Item
          </FormButton>
          <FormButton onClick={handleAddToCart} startIcon={<CartIcon />}>
            Add to Cart
          </FormButton>
        </Box>
      </Box>
      {detailsModalShown && (
        <FavoriteDetails
          favorite={favorite}
          onClose={() => setDetailsModalShown(false)}
          onAddToCart={handleAddToCart}
        />
      )}
    </>
  );
}

export default FavoriteItemCard;
