import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { RiPriceTag3Line as PriceTagIcon } from 'react-icons/ri';
import { Order } from '../order-types';
import { appColors } from '../../../utils/ui-utils';
import OrderTag from '../OrderTag';
import { formatDate } from '../../../utils/helpers';
import { formatOrderStatus } from './track-order-utils';
import TrackOrderItem from './TrackOrderItem';
import OrderJourney from './OrderJourney';

const TrackOrderGroup = ({ order }: { order: Order }) => {
  return (
    <Box border={`1px solid ${appColors.lightGray}`} mb={3}>
      <Box
        display='flex'
        alignItems='center'
        gridColumnGap={12}
        bgcolor={appColors.lightGray}
        padding='0 20px'
        height={56}
      >
        <PriceTagIcon fontSize={16} />
        <Typography style={{ fontSize: 14, fontWeight: 600 }}>ORDER NO. {order.id}</Typography>
        <Typography style={{ fontSize: 13, fontWeight: 'lighter', textTransform: 'capitalize' }}>
          {formatOrderStatus(order.status).toLowerCase()} {formatDate(order.date)}
        </Typography>
        <OrderTag status={order.status} />
      </Box>
      <OrderJourney orderHistory={order.history} />
      {order.items.map((product) => (
        <TrackOrderItem key={product.productId} product={product} />
      ))}
    </Box>
  );
};

export default TrackOrderGroup;
