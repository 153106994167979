import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getCustomerOrderById, getCustomerOrders } from '../user-service';
import useLoggedInUser from './useLoggedInUser';

interface OrderRouteParams {
  vendorName: string;
  orderId: string;
}

export function useOrderDetailParams() {
  return useParams<OrderRouteParams>();
}

export function useGetCustomerOrders() {
  const user = useLoggedInUser();

  return useQuery(user?.id as string, () => getCustomerOrders(user?.id as string));
}

export function useGetCustomerOrderDetail() {
  const { vendorName, orderId } = useOrderDetailParams();

  return useQuery(orderId, () => getCustomerOrderById(orderId, vendorName));
}
