import { useSelector } from 'react-redux';
import { selectIsLoggedIn, selectUserDetails } from '../../../store/system/reducers';

export default function useLoggedInUser() {
  return useSelector(selectUserDetails);
}

export function useIsLoggedIn() {
  return useSelector(selectIsLoggedIn);
}
