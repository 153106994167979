export const appColors = {
  primary: '#21B5EB',
  purple: '#2C2E6C',
  grayText: '#B9B9B9',
  darkText: '#222323',
  lightGray: '#F5F5F5',
  mediumGray: '#ddd',
  failedColor: '#FF841A',
  failedBgColor: '#FFE4CC',
  cancelledColor: '#FF4D4D',
  cancelledBgColor: 'rgb(255, 77, 77, 0.2)',
  receivedColor: '#1DAB22',
  receivedBgColor: 'rgb(29, 171, 34, 0.2)',
  primaryLight: '#E8F7FD',
  purpleLight: '#E9EAF0',
};
