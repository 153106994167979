import { AppState, AppThunk } from '..';
import { AuthAction, AuthStateInput, SET_AUTH_STATE, RESET_AUTH_STATE } from './types';
import { SET_SYSTEM_STATE } from '../system/types';
import { fetchCartItems } from '../cart/actions';
import { setInterceptor } from '../system/actions';
import provinces from 'philippines/provinces.json';
import cities from 'philippines/cities.json';

// Utils
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import map from 'lodash/map';
import jwt from 'jsonwebtoken';

const API_URL = process.env.REACT_APP_API_URL;
const AMI_URL = process.env.REACT_APP_AMI_URL;
const REGISTER_TOKEN = process.env.REACT_APP_MARKET_WEB_REGISTER_TOKEN;
const AUTOMATIC_LOGIN_TOKEN = process.env.REACT_APP_MARKET_WEB_AUTOMATIC_LOGIN_TOKEN
  ? process.env.REACT_APP_MARKET_WEB_AUTOMATIC_LOGIN_TOKEN
  : '';

export const setAuthState = (input: AuthStateInput): AuthAction => {
  return {
    type: SET_AUTH_STATE,
    payload: input,
  };
};

export const authenticateUser = (email?: string, pass?: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_AUTH_STATE,
      payload: {
        loginLoading: true,
      },
    });
    try {
      const { username, password } = getState().auth;
      let body = { username: email ? email : username, password: pass ? pass : password };

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_MARKET_WEB_LOGIN_TOKEN}`,
      };

      const authenticateRes = await axios.post(`${API_URL}/user/login`, body, { headers });
      if (authenticateRes.status === 200) {
        const { userDetails } = authenticateRes.data;
        console.log(userDetails);
        if (userDetails.userType === 'VENDOR') {
          const { username, password } = getState().auth;
          let jwtBody = { username: email ? email : username, password: pass ? pass : password };
          const token = jwt.sign(jwtBody, AUTOMATIC_LOGIN_TOKEN);
          window.location.href = `${AMI_URL}/login/${token}`;
        } else {
          const { redirectTo } = getState().system;
          dispatch({
            type: SET_SYSTEM_STATE,
            payload: {
              redirectTo: redirectTo ? redirectTo : '/home',
              shallRedirect: true,
              session: {
                token: authenticateRes.data.token,
                refreshToken: authenticateRes.data.refreshToken,
                userDetails: authenticateRes.data.userDetails,
              },
            },
          });
          dispatch({
            type: SET_AUTH_STATE,
            payload: {
              isLoggedIn: true,
            },
          });
          dispatch(setInterceptor(authenticateRes.data.token));
          dispatch(fetchCartItems());
        }
      }
    } catch (e) {
      const err = e.response.data.error.message.split(':');
      dispatch({
        type: SET_SYSTEM_STATE,
        payload: {
          snackBarIsOpen: true,
          snackBarMessage: err[1],
          snackBarType: 'error',
        },
      });
    } finally {
      dispatch({
        type: SET_AUTH_STATE,
        payload: {
          loginLoading: false,
        },
      });
    }
  };
};

export const fetchPhilippines = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_AUTH_STATE,
        payload: {
          registerCountries: ['Philippines', 'Singapore'],
          registerProvinces: map(provinces, (p) => p.name),
          registerCities: map(cities, (c) => c.name),
        },
      });
    } catch (e) {
      console.log(e.response);
      dispatch({
        type: SET_SYSTEM_STATE,
        payload: {
          snackBarIsOpen: true,
          snackBarMessage: e.toString(),
          snackBarType: 'error',
        },
      });
    }
  };
};

export const createUser = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_AUTH_STATE,
      payload: {
        registerLoading: true,
      },
    });
    try {
      const { registerSelectedUserType } = getState().auth;
      const {
        firstName,
        lastName,
        companyName,
        email,
        countryCode,
        mobileNumber,
        areaCode,
        landLineNumber,
        country,
        city,
        province,
        address,
        postal,
        password,
        confirmPassword,
      } = getState().auth.registerInformation;

      interface BodyType {
        firstName: string;
        lastName: string;
        companyName?: string;
        email: string;
        countryCode: string;
        mobileNumber: string;
        areaCode?: string;
        landlineNumber?: string;
        country: string;
        city: string;
        addressLine: string;
        province: string;
        postalCode: number;
        password: string;
        confirmedPassword: string;
        userType: string;
      }

      const body: BodyType = {
        firstName,
        lastName,
        companyName,
        email,
        countryCode,
        mobileNumber,
        areaCode,
        landlineNumber: landLineNumber,
        country,
        city,
        addressLine: address,
        province,
        postalCode: parseInt(postal),
        password,
        confirmedPassword: confirmPassword,
        userType: registerSelectedUserType[0],
      };

      if (registerSelectedUserType[0] !== 'Vendor') delete body.companyName;
      if (!areaCode) delete body.areaCode;
      if (!landLineNumber) delete body.landlineNumber;

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${REGISTER_TOKEN}`,
      };

      const createRes = await axios.post(`${API_URL}/user/createUser`, body, { headers });
      if (createRes.status === 200) {
        dispatch({
          type: SET_SYSTEM_STATE,
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: 'Account successfully created',
            snackBarType: 'success',
          },
        });
        dispatch({
          type: SET_AUTH_STATE,
          payload: {
            registerInformation: {
              firstName: '',
              lastName: '',
              companyName: '',
              email: '',
              password: '',
              confirmPassword: '',
              countryCode: '',
              mobileNumber: '',
              areaCode: '',
              landLineNumber: '',
              address: '',
              country: '',
              province: '',
              city: '',
              postal: '',
            },
            registerProceedButtonClicked: false,
          },
        });
        window.location.replace('/');
      }
    } catch (e) {
      console.log(e.response);
      if (e.response.data.error.message === 'Create User: Email address is already taken') {
        dispatch({
          type: SET_SYSTEM_STATE,
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: 'Email already taken',
            snackBarType: 'error',
          },
        });
        return;
      }

      if (e.response.data.error.message === 'Create User: Invalid landline number format') {
        dispatch({
          type: SET_SYSTEM_STATE,
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: 'Invalid Landline Number format',
            snackBarType: 'error',
          },
        });
        return;
      }
      dispatch({
        type: SET_SYSTEM_STATE,
        payload: {
          snackBarIsOpen: true,
          snackBarMessage: e.toString(),
          snackBarType: 'error',
        },
      });
    } finally {
      dispatch({
        type: SET_AUTH_STATE,
        payload: {
          registerLoading: false,
        },
      });
    }
  };
};

export const forgotPassword = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_AUTH_STATE,
      payload: {
        forgotPasswordLoading: true,
      },
    });
    try {
      const result = await axios.post(
        `${API_URL}/user/forgotPassword?client=Market Web E-Commerce`,
        {
          email: getState().auth.forgotPasswordEmail,
          uuid: uuidv4(),
        }
      );
      console.log(result);
      if (result.status === 204) {
        dispatch({
          type: SET_SYSTEM_STATE,
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: 'Email Sent: Please check your email to reset password.',
            snackBarType: 'success',
          },
        });
        dispatch({
          type: SET_AUTH_STATE,
          payload: {
            forgotPasswordEmail: '',
          },
        });
      }
    } catch (e) {
      console.log(e.response);
      if (e.response.data.error.message === 'Forgot Password: User was not found') {
        dispatch({
          type: SET_SYSTEM_STATE,
          payload: {
            snackBarIsOpen: true,
            snackBarMessage:
              'Email does not exist: No account is associated with the email address.',
            snackBarType: 'error',
          },
        });
      }
    } finally {
      dispatch({
        type: SET_AUTH_STATE,
        payload: {
          forgotPasswordLoading: false,
        },
      });
    }
  };
};

export const getForgotPasswordId = (id: string, token: string): AppThunk => {
  return async (dispatch) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const result = await axios.get(`${apiUrl}/user/forgotPasswordId/${id}`, { headers });

      if (result.data.forgotPasswordId) {
        dispatch({
          type: SET_AUTH_STATE,
          payload: {
            resetPasswordForgotPasswordId: result.data.forgotPasswordId,
          },
        });
      } else {
        dispatch({
          type: SET_AUTH_STATE,
          payload: {
            resetPasswordForgotPasswordId: '',
          },
        });
      }
    } catch (e) {
      dispatch({
        type: SET_SYSTEM_STATE,
        payload: {
          shallRedirect: true,
          redirectTo: '/login',
          snackBarIsOpen: true,
          snackBarMessage: 'Password request does not exist. Redirecting to login',
          snackBarType: 'error',
        },
      });
    }
  };
};

export const newPassword = (
  id: string,
  newPassword: string,
  confirmedPassword: string,
  token: string
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_AUTH_STATE,
      payload: {
        resetPasswordLoading: true,
      },
    });

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const requestBody = { id, password: newPassword, confirmedPassword };
      const result = await axios.post(`${apiUrl}/user/changePassword/forgot`, requestBody, {
        headers,
      });
      if (result.status === 200 || result.status === 204) {
        dispatch({
          type: SET_AUTH_STATE,
          payload: {
            resetPasswordSuccess: true,
          },
        });
        dispatch({
          type: SET_SYSTEM_STATE,
          payload: {
            shallRedirect: true,
            redirectTo: '/login',
            snackBarIsOpen: true,
            snackBarMessage: 'Your password has been changed',
            snackBarType: 'success',
          },
        });
        dispatch(authenticateUser(result.data.email, newPassword));
      }
    } catch (e) {
      console.log(e.response);
      if (e.response.data.error.message === 'Change Password: Cannot use same password') {
        dispatch({
          type: SET_SYSTEM_STATE,
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: 'Current password is not allowed.',
            snackBarType: 'error',
          },
        });
        return;
      }
      dispatch({
        type: SET_SYSTEM_STATE,
        payload: {
          snackBarIsOpen: true,
          snackBarMessage: e.toString(),
          snackBarType: 'error',
        },
      });
    } finally {
      dispatch({
        type: SET_AUTH_STATE,
        payload: {
          resetPasswordLoading: false,
        },
      });
    }
  };
};

export const resetAuthState = (): AuthAction => {
  return {
    type: RESET_AUTH_STATE,
    payload: null,
  };
};

// added by jeff
export const getAuthState = (state: AppState) => state.auth.isLoggedIn;
