
import React from 'react';
import { getUserCountryCode } from '../../../utils/helpers';

const useLocale =()=> {
    const[userLocale,setUserLocale]=React.useState<string>("PH")
    React.useEffect(()=>{
        const x = getUserCountryCode()
        if(x.length === 0) {
            setUserLocale("PH")
        }else{
            setUserLocale("SG")
        }
        
    },[userLocale])

    
    return userLocale
}
export default useLocale;
