import { NavLink } from 'react-router-dom';
import { styled } from '@material-ui/styles';

import { colors } from '../../theme/defaultTheme';

const UserLink = styled(NavLink)({
  textDecoration: 'none',
  color: colors.gray,
  fontSize: 18,
  transition: 'color 200ms',
  display: 'flex',
  alignItems: 'center',
  gap: 16,

  '&:not(.active):hover': {
    color: colors.darkest,
  },

  '&.active': {
    color: colors.primary,
  },

  '&.with-active': {
    color: colors.primary,
  },
});

export default UserLink;
