import {
    SET_ORDERS_STATE,
    OrdersState,
    OrdersAction
} from './types';

const INITIAL_STATE: OrdersState = {
    ordersLoading: false,
    activeSubTab: 'My Orders',
    myOrders: [],
    orderDetails: {
        billingAddress: {
            addressLine: '',
            city: '',
            country: '',
            customerId: '',
            dateCreated: '',
            dateUpdated: '',
            docType: '',
            id: '',
            isDeleted: false,
            postalCode: 0,
            province: '',
            tag: ''
        },
        customerName: '',
        items: [],
        mobileNumber: '',
        orderDate: '',
        paymentMethod: '',
        shippingAddress: {
            addressLine: '',
            city: '',
            country: '',
            customerId: '',
            dateCreated: '',
            dateUpdated: '',
            docType: '',
            id: '',
            isDeleted: false,
            postalCode: 0,
            province: '',
            tag: ''
        },
        subtotal: 0,
        totalAmount: 0,
        totalDiscount: 0,
        id: '',
        vendorName: ''
    },
    orderDetailsLoading: false
}

const reducer = (state = INITIAL_STATE, action: OrdersAction): OrdersState => {
    switch (action.type) {
        case SET_ORDERS_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default reducer;