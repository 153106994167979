import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store'
import Layout from '../../common/Layout'
import { BillBoxInfo, BillingBox, MainBodyContainer, PaymentBox, PaymentHeading, PaymentIconBox, PaymentInfoBox } from './PlaceOrderStyle';
import { FormControl, Typography } from '@material-ui/core';
import visa from "../../../assets/images/icons/visa.png";
import mc from "../../../assets/images/icons/mc.png";
import { getCustomerAddresses } from '../../../store/cart/actions';
import axios from 'axios';


function sendPayLoad(jSonString:string, hmac:string, apiKey:string){

  const paymentRequest = axios.post('https://uat-api.nets.com.sg/GW2/TxnReqListener',
   {headers:{
    
  }}
  )

}


const PlaceOrder = () => {
  
const [sdk1,setSdk1]=React.useState(false)
const [sdk2,setSdk2]=React.useState(false)
const [sdk3,setSdk3]=React.useState(false)  
const history = useHistory()
const dispatch = useDispatch()
const shipAdd = useSelector((state:AppState)=> state.cart.checkOutShippingAddress)
const checkoutData = useSelector((state:AppState)=>state.cart.checkOutItems)
const customerData = useSelector((state:AppState)=> state.system.session?.userDetails)
console.log(checkoutData)
console.log(customerData)
console.log(shipAdd)
React.useEffect(()=>{
  if(checkoutData.length <= 0){
    history.replace('/cart')
  }
},[history,checkoutData])
React.useEffect(()=>{
  dispatch(getCustomerAddresses())
},[dispatch])

React.useEffect(()=>{
  
    const firstScript = document.createElement("script");
    firstScript.type = "text/javascript";
    firstScript.src = "https://uat2.enets.sg/GW2/js/jquery-3.1.1.min.js";
    firstScript.async = true;
    firstScript.onload= ()=> setSdk1(true)
    document.body.appendChild(firstScript)

    const secondScript = document.createElement("script");
    secondScript.src = "https://uat2.enets.sg/GW2/pluginpages/env.jsp"
    secondScript.async = true;
    secondScript.onload= ()=> setSdk2(true)
    document.body.appendChild(secondScript)

    const thirdScript = document.createElement("script");
    thirdScript.src = "https://uat2.enets.sg/GW2/js/apps.js"
    thirdScript.type = "text/javascript";
    thirdScript.async = true;
    thirdScript.onload= ()=> setSdk3(true)
    document.body.appendChild(thirdScript)

},[])


if(!shipAdd) return null;

const {addressLine, city, province, country, postalCode } = shipAdd;

if(!customerData) return null;
const {firstName, lastName, mobileNumber, email} = customerData;
  return (
    <Layout>
      <MainBodyContainer>
        <PaymentBox>
          <Typography variant='h6' >Payment</Typography>
          <PaymentHeading>Payment</PaymentHeading>
          <PaymentInfoBox>
          <PaymentIconBox>
            <img src={visa} alt="visa" width="50px" height="50px" style={{marginRight:"10px"}}/>
            <img src={mc} alt="master card"  width="50px" height="50px"/>
          </PaymentIconBox>
          <FormControl id="payment_confirmation" component="form" >
          
          </FormControl>
          </PaymentInfoBox>
        </PaymentBox> 
        <BillingBox>
        <Typography variant='h6' >Billing Information</Typography>
        <BillBoxInfo>
          <Typography>Full Name</Typography>
          <Typography>{firstName} {' '} {lastName}</Typography>
          <Typography>Shipping Address</Typography>
          <Typography>{addressLine},{city},{province},{country},{postalCode}</Typography>
          <Typography>Phone Number</Typography>
          <Typography>{mobileNumber}</Typography>
          <Typography>Email</Typography>
          <Typography>{email}</Typography>
        </BillBoxInfo>
        </BillingBox>
      </MainBodyContainer>
    </Layout>
  )
}

export default PlaceOrder