import { useState } from 'react';
import { Category } from '../../store/system/types';
import { Link, useHistory } from 'react-router-dom';

import { HeaderCartContainer } from '../common/CommonComponents';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Box from '@material-ui/core/Box';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

import map from 'lodash/map';
import { ReactComponent as Logo } from '../../assets/images/symphonylogo.svg';
import LogoImg from '../../assets/images/symphony-logo-gold.png';
import Search from '../search/Search';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import BackIcon from '@material-ui/icons/ArrowBack';
import { colors } from '../../theme/defaultTheme';

interface DrawerProps {
  categories: Array<Category>;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  onHomeClick: (name: string) => void;
  onRedirectClick: (to: string) => void;
  onCategorySelect: (h1: string) => void;
  onPressLogout: () => void;
  isLoggedIn: boolean;
}

const Drawer = (props: DrawerProps) => {
  const { categories, firstName, lastName, email, onRedirectClick, onHomeClick, onCategorySelect } =
    props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  const [searchShown, setSearchShown] = useState(false);

  const navigate = useHistory();

  const handleNavigation = (path: string) => {
    toggleDrawer(false);
    navigate.push(path);
  };

  return (
    <Box
      className='drawer-container'
      height='100%'
      width='100%'
      display='none'
      justifyContent='space-between'
      alignItems='center'
    >
      {!searchShown && (
        <Link to='/'>
          <img height={32} src={LogoImg} alt='Symphony Marketplace' style={{ marginLeft: 14 }} />
        </Link>
      )}
      <Box display='flex' alignItems='center' width='100%'>
        <Box
          display='flex'
          flex={1}
          alignItems='center'
          justifyContent='flex-end'
          pr={searchShown ? 2 : 0}
        >
          {searchShown ? (
            <>
              <IconButton aria-label='close search' onClick={() => setSearchShown(false)}>
                <BackIcon htmlColor={colors.darkest} />
              </IconButton>
              <Search focused={searchShown} onSuggestionClick={() => setSearchShown(false)} />
            </>
          ) : (
            <IconButton aria-label='show search' onClick={() => setSearchShown(true)}>
              <SearchIcon htmlColor={colors.darkest} />
            </IconButton>
          )}
        </Box>
        <HeaderCartContainer>
          {props.isLoggedIn ? (
            <>
              <Link to='/cart'>
                <ShoppingCartOutlinedIcon style={{ marginRight: 8 }} />
              </Link>
            </>
          ) :
          (
              <Link to='/login'>
                <ShoppingCartOutlinedIcon style={{ marginRight: 8 }} />
              </Link>
          )
          }
        </HeaderCartContainer>
        <Button
          id='drawer-btn'
          style={{ width: 48, height: 48, marginRight: 12, minWidth: 48 }}
          onClick={() => toggleDrawer(true)}
        >
          <MenuOutlinedIcon />
        </Button>
      </Box>
      <SwipeableDrawer
        anchor='left'
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        id='drawer'
      >
        <List id='drawer-top-list'>
          <Logo style={{ height: 32, position: 'relative', left: -16, margin: '16px 0' }} />
          {props.isLoggedIn ? (
            <>
              <Box
                display='flex'
                paddingLeft='15px'
                flexDirection='column'
                justifyContent='space-between'
                alignContent='flex-start'
                paddingTop='10px'
                paddingBottom='10px'
              >
                <Box
                  fontSize='12px'
                  whiteSpace='nowrap'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  fontWeight='bolder'
                >
                  {firstName} {lastName}
                </Box>
                <Box
                  fontSize='12px'
                  whiteSpace='nowrap'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  style={{ opacity: '50%' }}
                >
                  {email}
                </Box>
                <Box fontSize='12px' fontWeight='bolder' display='flex' alignItems='center'>
                  <Box style={{ textDecoration: 'underline', cursor: 'pointer' }}>Edit</Box>
                </Box>
              </Box>
              <Divider />
              <ListItem
                button
                key='MyAccount'
                className='drawer-my-account-btn'
                onClick={() => handleNavigation('/me/account')}
              >
                <ListItemText primary='My Account' />
              </ListItem>

              <ListItem
                button
                key='Favorites'
                className='drawer-favorites-btn'
                onClick={() => handleNavigation('/me/favorites')}
              >
                <ListItemText primary='Favorites' />
              </ListItem>
              <ListItem
                button
                key='FollowedVendors'
                className='drawer-followed-vendor-btn'
                onClick={() => handleNavigation('/me/followed-vendor')}
              >
                <ListItemText primary='Followed Vendor' />
              </ListItem>
              <ListItem
                button
                key='Wishlist'
                className='drawer-wishlist-btn'
                onClick={() => handleNavigation('/me/wishlists')}
              >
                <ListItemText primary='Wishlist' />
              </ListItem>
              <ListItem
                button
                key='MyOrders'
                className='drawer-my-orders-btn'
                onClick={() => handleNavigation('/me/orders')}
              >
                <ListItemText primary='My Orders' />
              </ListItem>
              <ListItem
                button
                key='Quotations'
                className='drawer-quotation-btn'
                onClick={() => handleNavigation('/me/quotations')}
              >
                <ListItemText primary='Quotations' />
              </ListItem>
              <ListItem button key='Payment' className='drawer-payment-btn'>
                <ListItemText primary='Payment' />
              </ListItem>
              <ListItem
                button
                key='Home'
                className='drawer-home-btn'
                onClick={() => {
                  toggleDrawer(false);
                  props.onPressLogout();
                }}
              >
                <ListItemText primary='Logout' />
              </ListItem>
            </>
          ) : (
            <ListItem
              className='drawer-reglog-btn'
              button
              key='Home'
              onClick={() => {
                toggleDrawer(false);
                onRedirectClick('/login');
              }}
            >
              <ListItemText primary='Register / Login' />
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          <ListItem
            button
            key='Home'
            className='drawer-home-btn'
            onClick={() => {
              toggleDrawer(false);
              onHomeClick('Home');
            }}
          >
            <ListItemText primary='Home' />
          </ListItem>
          {map(categories, (cat) => (
            <ListItem
              button
              key={cat.name}
              onClick={() => {
                toggleDrawer(false);
                onCategorySelect(cat.name);
              }}
            >
              <ListItemText primary={cat.name} />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    </Box>
  );
};

export default Drawer;
