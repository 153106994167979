import React from 'react';

import Box from '@material-ui/core/Box';
import ListTitle from '../common/ListTitle';
import { useGetCustomerOrderDetail } from './hooks/useGetCustomerOrders';
import OrderDetailGroup from './OrderDetailGroup';
import CustomerAddressCard from './CustomerAddressCard';
import CustomerTotalSummary from './CustomerTotalSummary';
import LoadingIndicator from '../common/LoadingIndicator';
import NoDataView from '../common/NoDataView';

const CustomerOrderDetail = () => {
  const { data: order, isLoading } = useGetCustomerOrderDetail();

  if (isLoading) return <LoadingIndicator />;

  if (!order) return <NoDataView message='No detail is found for this order' />;

  return (
    <Box flex={1}>
      <ListTitle title='Order Details' />
      <OrderDetailGroup orderDetail={order} />
      <Box display='flex' style={{ gap: 24 }}>
        <CustomerAddressCard orderDetail={order} />
        <CustomerTotalSummary orderDetail={order} />
      </Box>
    </Box>
  );
};

export default CustomerOrderDetail;
