import {
  ProductCardContainer,
  ProductCardImageContainer,
  ProductCardDescriptionContainer,
  ProductFavoriteButton,
  ProductCardAddToCartButton,
  ProductNameContainer,
  CategoryNameContainer,
} from './CommonComponents';

import Box from '@material-ui/core/Box';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { colors } from '../../theme/defaultTheme';
import useLocale from '../home/hooks/useLocale';
import { MouseEventHandler } from 'react';
import {
  useAddFavorite,
  useFavoritesQuery,
  useRemoveFavoriteForHome,
} from '../user-specific/hooks/favorites-hooks';
import { useIsLoggedIn } from '../user-specific/hooks/useLoggedInUser';
import { useHistory } from 'react-router-dom';

interface ProductCardProps {
  brand?: string;
  color?: string;
  dateCreated?: string;
  displayId?: string;
  h1: string;
  h2?: string;
  id: string;
  isActive?: boolean;
  name: string;
  price: number;
  productSkuId: string;
  size?: number;
  vendorId: string;
  image: {
    path: string;
    type: string;
  };
  discountPrice?: number;
  marketStock?: number;
  isOutOfStock?: boolean;
  isFavorite: boolean;
  addToCartClick: (id: string, productSkuId: string, vendorId: string) => void;
  onProductClick: (id: string) => void;
}
const ProductCard = (props: ProductCardProps) => {
  const {
    id,
    image,
    h1,
    name,
    price,
    discountPrice,
    vendorId,
    productSkuId,
    onProductClick,
    addToCartClick,
  } = props;

  const handleProductClick = () => onProductClick(id);
  const country = useLocale();
  const handleAddToCartClick = () => addToCartClick(id, productSkuId, vendorId);

  const history = useHistory();
  const isLoggedIn = useIsLoggedIn();
  const addFavorite = useAddFavorite();
  const removeFavorite = useRemoveFavoriteForHome();
  const favorites = useFavoritesQuery();

  const favs = favorites.data?.map((f) => f.id) ?? [];

  const isFavorite = favs.includes(id);

  const handleFavoriteClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();

    // if not logged in, redirect to login page
    if (!isLoggedIn) {
      history.push('/login');
      return;
    }

    // perform mutation
    if (!isFavorite) {
      await addFavorite.mutateAsync({ productId: id, vendorId });
      return;
    }

    await removeFavorite.mutateAsync({ favoriteId: id, favoriteName: name });
  };

  return (
    <ProductCardContainer key={id} className='product-card'>
      <ProductCardImageContainer id={`image-container-${id}`} onClick={handleProductClick}>
        <img src={image.path} alt='' style={{ aspectRatio: '1/1' }} />
        <ProductFavoriteButton onClick={handleFavoriteClick}>
          {isFavorite ? (
            <FavoriteIcon htmlColor='#FE5415' />
          ) : (
            <FavoriteBorderIcon htmlColor={colors.gray} />
          )}
        </ProductFavoriteButton>
      </ProductCardImageContainer>
      <ProductCardDescriptionContainer>
        <CategoryNameContainer>{h1}</CategoryNameContainer>
        <ProductNameContainer onClick={handleProductClick}>{name}</ProductNameContainer>
        <Box color={colors.darkest} display='flex' width='100%' alignItems='flex-start'>
          <Box fontWeight='bold'>
            {country === 'PH'
              ? `₱ ${parseFloat(price.toString()).toFixed(2)}`
              : `S$ ${parseFloat(price.toString()).toFixed(2)}`}
          </Box>
          {Boolean(discountPrice) && (
            <Box
              fontSize='11px'
              marginLeft='6px'
              color={colors.darkest}
              style={{ textDecoration: 'line-through', opacity: '50%' }}
            >
              {country === 'PH'
                ? `₱ ${parseFloat(price.toString()).toFixed(2)}`
                : `S$ ${parseFloat(price.toString()).toFixed(2)}`}
            </Box>
          )}
        </Box>
      </ProductCardDescriptionContainer>
      <ProductCardAddToCartButton onClick={handleAddToCartClick}>
        <ShoppingCartOutlinedIcon />
        <Box marginLeft='8px'>Add to Cart</Box>
      </ProductCardAddToCartButton>
    </ProductCardContainer>
  );
};

export default ProductCard;
