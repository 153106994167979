import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

export const endpoints = {
  customerOrders: API_URL + '/order/getOrderItemsByCustomerId',
  customerOrderDetail: API_URL + '/v1/orders',
  customerAddress: API_URL + '/user/address/getAddress',
  createAddress: API_URL + '/user/address/createAddress',
  updateAddress: API_URL + '/user/address/updateAddress',
  countries: API_URL + '/v1/countries',
  updateUser: API_URL + '/user/updateUser',
  getUserById: API_URL + '/user/getById',
  users: API_URL + '/v1/users', // /userId/favorites -> favorites
  bag: API_URL + '/bag',
  follower: API_URL + '/user/follower',
  products: API_URL + '/v1/products'
};

const apiClient = axios;

export default apiClient;
