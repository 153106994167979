import React from 'react';

import Box from '@material-ui/core/Box';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import UserOrderPage from './UserOrderPage';
import CustomerAccountPage from './my-account/CustomerAccountPage';
import CustomerAddressPage from './my-account/CustomerAddressPage';
import TrackOrdersPage from './track-orders/TrackOrdersPage';
import FavoritesPage from './favorites/FavoritesPage';
import FollowedVendorPage from './followed-vendor/FollowedVendorPage';
const UserPages = () => {
  const { path } = useRouteMatch();

  return (
    <Box mb={4}>
      <Switch>
        <Route path={`${path}/quotations`}>
          <h2>Quotations here</h2>
        </Route>
        <Route path={`${path}/favorites`} component={FavoritesPage} />
        <Route path={`${path}/followed-vendor`} component={FollowedVendorPage}/>
        <Route path={`${path}/wishlists`}>
          <h2>Wishlists here</h2>
        </Route>
        <Route path={`${path}/orders`} component={UserOrderPage} />
        <Route path={`${path}/track-orders`} component={TrackOrdersPage} />
        <Route path={`${path}/account`} component={CustomerAccountPage} />
        <Route path={`${path}/address`} component={CustomerAddressPage} />
        <Redirect from={path} to={`${path}/account`} />
      </Switch>
    </Box>
  );
};

export default UserPages;
