//@ts-ignore
import {cities} from "philippines/index"
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ProductCard from '../../common/ProductCard';
import { useQuery } from "react-query"
import { useDispatch, useSelector } from 'react-redux';
import {
    FeaturedCategoryContainer,
    FeaturedCategoryDescriptionContainer,
    FeaturedCategoryItemsContainer,
    FeaturedCategoryItemsControllerContainer,
    FeaturedCategoryControllerButton,
    FeaturedCategoryItemsButtonContainer,
    FeaturedCategoryItemsCardContainer
} from './HomeComponents';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import map from 'lodash/map';
import LoadingIndicator from '../../common/LoadingIndicator';
import { publicAxiosClient } from '../../../utils/apiPublicClient';
import { Product } from '../types';
import { AppState } from '../../../store';
import { useHistory, Route } from 'react-router-dom';
import { addToCart } from '../../../store/product/actions';
import { BannerContainer } from './HomeStyle';
import useAtBreakpoint from '../hooks/useAtBreakpoint';
import useLocale from '../hooks/useLocale';
import CarouselItemProductDetail from '../../common/CarouselItemProductDetail';
import { getUserCountryCode } from "../../../utils/helpers";
import { useEffect } from "react";


// import sgCities from './singapore/cities.json';

const HomeFeaturedProductsSection = ({country}:{country:string}) => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    const isLarge = useAtBreakpoint('lg')
    const user = useSelector((state:AppState)=> state.auth.isLoggedIn)
    //const country = useLocale()
    

    useEffect(()=>{
        getUserCountryCode()
    },[])
    

    const {data:products,status,error} = useQuery<Product[]>("products",
    async ()=> (await publicAxiosClient.get<Product[]>(`v1/public/products?isActive=true&forMarketplace=true&countryCode=${country}`)).data
    )
    // if(user) return null;
    if(status === 'loading') return <LoadingIndicator />
    if(!products) return null;
    if(error) return <div>Something Went Wrong</div>
    const addToCartHandler = (id:string,productSkuId:string,vendorId:string) => {

        if(!user){
            history.push('/login')
        }else{
            dispatch(
                addToCart(
                    id,productSkuId,vendorId
                )
            )
            
        }
    }
    const productClickHandler=(id:string)=>{
        history.push(`/home/products/${id}`)
    }
    return (

<>
<FeaturedCategoryContainer className="featured-category">
            <FeaturedCategoryItemsContainer>
                <FeaturedCategoryItemsControllerContainer>
                    <Box fontSize="20px" width="100%">Featured Products</Box>
                    {/* <FeaturedCategoryItemsButtonContainer>
                        <FeaturedCategoryControllerButton
                            disabled={true}
                        >
                            <ArrowBackIcon />
                        </FeaturedCategoryControllerButton>
                        <FeaturedCategoryControllerButton>
                            <ArrowForwardOutlinedIcon />
                        </FeaturedCategoryControllerButton>
                    </FeaturedCategoryItemsButtonContainer> */}
                </FeaturedCategoryItemsControllerContainer>
                <FeaturedCategoryItemsCardContainer >
                    <BannerContainer>
                        <Box className='headers-carousel'>
                            <Carousel
                                      showIndicators={false}
                                      showStatus={false}
                                      showThumbs={false}
                                      emulateTouch={true}
                                      infiniteLoop={false}
                                      centerSlidePercentage={
                                        isLarge ? 100/5 : 100
                                      }
                                      centerMode={true}
                            >
                            {map(products, (i) => (
                        <ProductCard
                            {...i}
                            id={i.id}
                            key={i.id}
                            vendorId={i.vendorId}
                            h1={i.h1}
                            productSkuId={i.name}
                            onProductClick={()=>{productClickHandler(i.id)}}
                            addToCartClick={()=>addToCartHandler(i.id,i.productSkuId,i.vendorId)}
                            isFavorite={false}
                        />
                    ))}
                            </Carousel>
                        </Box>
                    
                    </BannerContainer>
                    
                </FeaturedCategoryItemsCardContainer>
            </FeaturedCategoryItemsContainer>
           
        </FeaturedCategoryContainer>
         <Route path='/home/products/:id' component={CarouselItemProductDetail} />
</>
    )
}

export default HomeFeaturedProductsSection;