import apiClient, { endpoints } from '../../../utils/apiClient';
import { FavoriteItem } from './types';

// GET
export const getFavoritesByUser = async (userId: string) => {
  const url = `${endpoints.users}/${userId}/favorites`;

  const response = await apiClient.get<FavoriteItem[]>(url);

  return response.data;
};

// DELETE
export const removeFavorite = async (userId: string, favoriteId: string) => {
  const url = `${endpoints.users}/${userId}/favorites/${userId}_${favoriteId}`;

  const response = await apiClient.delete(url);

  return response.data;
};

// ADD TO CART
export type AddToCartArgs = {
  customerId: string;
  productId: string;
  productSkuId: string;
  qty: number;
  vendorId: string;
};

export const addToCart = async (args: AddToCartArgs) => {
  const response = await apiClient.post<{ id: string }>(endpoints.bag);
  return response.data;
};

// ADD TO FAVORITE
export const addToFavorites = async (userId: string, productId: string, vendorId: string) => {
  const url = `${endpoints.users}/${userId}/favorites`;
  const response = await apiClient.post(url, { productId, vendorId });
  return response.data;
};
