import {
    SET_VENDOR_STATE,
    VendorState,
    VendorAction
} from './types';

const INITIAL_STATE: VendorState = {
    vendorLoading: false,
    vendorAvatar: '',
    vendorName: '',
    vendorIsFollowed: false,
    vendorBanner: [],
    vendorBestSellers: [],
    vendorActiveTab: 'HOME',
    vendorAllProducts: [],
    vendorAllProductsLoading: false,
    vendorCategories: [],
    vendorActiveCategory: ''

}

const reducer = (state = INITIAL_STATE, action: VendorAction): VendorState => {
    switch (action.type) {
        case SET_VENDOR_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default reducer;