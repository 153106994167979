import { Button, styled } from '@material-ui/core';
import { colors } from '../../theme/defaultTheme';

const FormButton = styled(Button)({
  textTransform: 'capitalize',
  backgroundColor: colors.primary,
  color: 'white',
  borderRadius: 0,
  padding: '12px 20px',
  fontSize: 15,
  minWidth: 200,
  transition: 'all 200ms',

  '&.outline': {
    backgroundColor: 'white',
    color: colors.primary,
    border: '1px solid',
    borderColor: colors.primary,
  },

  '&:hover': {
    opacity: 0.8,
    backgroundColor: colors.primary,
    color: colors.white,
  },

  '&.btn-text': {
    backgroundColor: 'transparent',
    color: colors.primary,
    textDecoration: 'underline',
  },
});

export default FormButton;
