import { Box } from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from '../../store';
import { logout } from '../../store/system/actions';
import HeaderPopOver from './HeaderPopOver';
import { isEmpty } from 'lodash';

const HeaderBox = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.system.session);
  const userLoggedIn = useSelector((state: AppState) => state.auth.isLoggedIn);
  const logoutHandler = () => {
    dispatch(logout());
  };

  if (!isEmpty(user) && userLoggedIn) {
    return (
      <HeaderPopOver
        firstName={user?.userDetails?.firstName}
        lastName={user?.userDetails?.lastName}
        image={user?.userDetails?.avatar?.path}
        email={user?.userDetails?.email}
        onPressLogout={logoutHandler}
      />
    );
  }

  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      <PersonOutlineOutlinedIcon />
      <Link to='/register'>Register</Link>/<Link to='/login'>Login</Link>
    </Box>
  );
};

export default HeaderBox;
