import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CartItems } from '../../../store/cart/types';

import Input from '../../common/Input';

import {
  POSwitch,
  POContainer,
  SwitchContainer,
  GenerateQuotationButton,
  ProceedToCheckOutButton,
  CartSummaryContentContainer,
} from './CartComponents';

import Box from '@material-ui/core/Box';

import map from 'lodash/map';
import { colors } from '../../../theme/defaultTheme';

interface CartSummaryProps {
  discount: number;
  subTotal: number;
  poNumber: string;
  type: 'CHECKOUT' | 'CART';
  checkOutItems: Array<CartItems>;
  onClickProceedToCheckOutButton: () => void;
  onChangePoNumberInput: (value: string) => void;
}

const CartSummary = (props: CartSummaryProps) => {
  const [poOpen, setPoOpen] = useState(false);

  const { checkOutItems, discount, subTotal, poNumber, type, onChangePoNumberInput } = props;

  return (
    <CartSummaryContentContainer id='cart-summary'>
      <Box
        flexDirection='column'
        boxSizing='border-box'
        height='100%'
        width='100%'
        style={{ backgroundColor: '#F5F5F5' }}
        padding='40px 15px 0 15px'
      >
        <Box display='flex' fontSize='20px' fontWeight='bold' marginBottom='20px'>
          Order Summary
        </Box>
        {type === 'CART' && (
          <SwitchContainer>
            <Box fontWeight='bold'>PO Number</Box>
            <POSwitch
              id='cart-summary-po-switch'
              checked={poOpen}
              onChange={(e) => setPoOpen(!poOpen)}
              name='checkedA'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </SwitchContainer>
        )}
        {checkOutItems.length > 0 && (
          <Box display='flex' flexDirection='column' width='100%' marginBottom='50px'>
            <Box paddingTop='20px' fontWeight='bold' fontSize='14px' marginBottom='20px'>
              Items
            </Box>
            {map(checkOutItems, (item) => {
              return (
                <Box
                  key={item.id}
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  justifyContent='space-between'
                  alignItems='center'
                  marginBottom='12px'
                >
                  <Box fontSize='12px' fontWeight='300'>
                    {item.name}
                  </Box>
                  <Box fontSize='12px' fontWeight='500'>
                    ₱{' '}
                    {parseFloat(((item.price as number) * item.quantity).toString())
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
        <Box
          display='flex'
          boxSizing='border-box'
          flexDirection='row'
          width='100%'
          justifyContent='space-between'
          marginBottom='10px'
        >
          <Box fontSize='12px' fontWeight='300'>
            Discount
          </Box>
          <Box fontSize='12px' fontWeight='500'>
            ₱{' '}
            {parseFloat(discount.toString())
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </Box>
        </Box>
        <Box
          display='flex'
          boxSizing='border-box'
          flexDirection='row'
          width='100%'
          justifyContent='space-between'
          marginBottom='10px'
        >
          <Box fontSize='12px' fontWeight='300'>
            Shipping
          </Box>
          <Box fontSize='12px' fontWeight='500'>
            Free
          </Box>
        </Box>
        <Box
          display='flex'
          boxSizing='border-box'
          flexDirection='row'
          width='100%'
          justifyContent='space-between'
          marginBottom='10px'
        >
          <Box fontSize='12px' fontWeight='300'>
            Subtotal
          </Box>
          <Box fontSize='12px' fontWeight='500'>
            ₱{' '}
            {parseFloat(subTotal.toString())
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </Box>
        </Box>
        {poOpen && (
          <Box
            id='cart-summary-po-container'
            display='flex'
            flexDirection='column'
            alignItems='center'
            width='100%'
          >
            <POContainer>
              <Box fontSize='12px' fontWeight='300' marginRight='10px'>
                PO Number
              </Box>
              <Box display='flex' width='60%'>
                <Input
                  id={`cart-ponumber-input`}
                  type='text'
                  label=''
                  value={poNumber}
                  onChange={(e) => onChangePoNumberInput(e.target.value as string)}
                  placeholder=''
                  disabled={false}
                />
              </Box>
            </POContainer>
          </Box>
        )}
        <Box display='flex' flexDirection='column' alignItems='center' width='100%'>
          {poOpen && <GenerateQuotationButton>Generate Quotation</GenerateQuotationButton>}
          {type === 'CHECKOUT' ? (
            <ProceedToCheckOutButton>
              <Link to='/place-order'>Place order</Link>
            </ProceedToCheckOutButton>
          ) : (
            <Link
              to={checkOutItems.length > 0 ? '/checkout' : '/cart'}
              style={{
                textDecoration: 'none',
                color: colors.white,
                backgroundColor: colors.primary,
                width: '90%',
                padding: '15px 0 15px 0',
                display: checkOutItems.length > 0 ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '0.875rem',
                lineHeight: '1.75',
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              Proceed to check out
            </Link>
          )}
        </Box>
      </Box>
    </CartSummaryContentContainer>
  );
};

export default CartSummary;
