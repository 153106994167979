import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import defaultImage from '../../assets/images/symphony_default.svg';
import { Vendor } from './types';
import { colors } from '../../theme/defaultTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#f5f5f5',
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      textDecoration: 'none',
      color: 'inherit',
      transition: 'background-color 230ms',

      '&:hover': {
        backgroundColor: '#eee',

        '& > .visit-shop': {
          textDecoration: 'underline',
        },
      },
    },
    image: {
      margin: 0,

      '& > img': {
        width: 64,
        height: 64,

        [theme.breakpoints.down('sm')]: {
          width: 48,
          height: 48,
        },
      },
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',

      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
    },
    subtitle: {
      color: '#aaa',
    },
    vistShopText: {
      color: colors.primary,

      marginLeft: 'auto',
      marginRight: 12,
      fontWeight: 600,
    },
  })
);

interface VendorCardProps {
  vendor: Vendor;
  hasVisitShop?: boolean;
}

function VendorCard({ vendor, hasVisitShop }: VendorCardProps) {
  const classes = useStyles();

  return (
    <Link to={`/vendor/${vendor.id}`} className={classes.container}>
      <Box component='figure' className={classes.image}>
        <img src={vendor.image ? vendor.image : defaultImage} alt={vendor.companyName} />
      </Box>
      <Box>
        <Typography className={classes.title} component='h2'>
          {vendor.companyName}
        </Typography>
        <Typography className={classes.subtitle}>Official Store</Typography>
      </Box>
      {hasVisitShop && (
        <Typography className={[classes.vistShopText, 'visit-shop'].join(' ')}>
          Visit Shop
        </Typography>
      )}
    </Link>
  );
}

export default VendorCard;
