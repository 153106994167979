import React, { CSSProperties } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { RiHome4Fill, RiBuilding4Fill } from 'react-icons/ri';
import { styled } from '@material-ui/core';
import { colors } from '../../../theme/defaultTheme';

const labelStyles: CSSProperties = {
  fontSize: 15,
  color: colors.gray,
  marginBottom: 8,
  display: 'block',
};

const ButtonBox = styled(Box)({
  textTransform: 'capitalize',
  fontWeight: 500,
  fontSize: 13,
  width: 100,
  height: 36,
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 6,
  border: '1px solid',
  cursor: 'pointer',

  '&.home': {
    color: colors.primary,
    borderColor: colors.primary,

    '&.selected': {
      color: colors.white,
      backgroundColor: colors.primary,
    },
  },

  '&.office': {
    color: colors.primary,
    borderColor: colors.primary,

    '&.selected': {
      color: colors.white,
      backgroundColor: colors.primary,
    },
  },
});

interface AddressTypeFieldProps {
  value: string;
  onChange: (val: string) => void;
}

const AddressTypeField = ({ value, onChange }: AddressTypeFieldProps) => {
  return (
    <Box flex={1} display='flex' flexDirection='column' mb={2} position='relative'>
      <Typography component='label' style={labelStyles}>
        Select Address Type
      </Typography>
      <Box display='flex' alignItems='center' style={{ gap: 12 }}>
        <ButtonBox
          onClick={() => onChange('Home')}
          component='span'
          className={value === 'Home' ? 'home selected' : 'home'}
        >
          <RiHome4Fill fontSize={15} /> Home
        </ButtonBox>
        <ButtonBox
          onClick={() => onChange('Office')}
          component='span'
          className={value === 'Office' ? 'office selected' : 'office'}
        >
          <RiBuilding4Fill fontSize={15} /> Office
        </ButtonBox>
      </Box>
    </Box>
  );
};

export default AddressTypeField;
