import { createStore, combineReducers, applyMiddleware, AnyAction } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
// @ts-ignore
import expireReducer from 'redux-persist-expire';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkAction } from 'redux-thunk';
// reducers
import systemReducer from './system/reducers';
import authReducer from './auth/reducers';
import cartReducer from './cart/reducers';
import productReducer from './product/reducers';
import ordersReducer from './orders/reducers';
import vendorReducer from './vendor/reducers';

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    whitelist: [],
    transforms: [
        // expireReducer('session', {
        //     expireSeconds: 3599,
        //     expiredState: {},
        //     autoExpire: true,
        // }),
    ],
};

const systemConfig = {
    key: 'system',
    storage,
    whitelist: ['session', 'categories', 'interceptors'],
    transforms: [
        expireReducer('session', {
            expireSeconds: 3599,
            expiredState: {},
            autoExpire: true
        })
    ]
}

const authConfig = {
    key: 'auth',
    storage,
    whitelist: ['isLoggedIn', 'username', 'rememberMe'],
    transforms: []
}

const cartConfig = {
    key: 'cart',
    storage,
    whitelist: ['cartItems', 'checkOutItems'],
    transforms: []
}

const ordersConfig = {
    key: 'orders',
    storage,
    whitelist: ['vendorName'],
    transforms: []
}

const productConfig = {
    key: 'product',
    storage,
    whitelist: [],
    transforms: []
}

const vendorConfig = {
    key: 'vendor',
    storage,
    whitelist: [],
    transforms: []
}

const rootReducer = combineReducers({
    system: persistReducer(systemConfig, systemReducer),
    auth: persistReducer(authConfig, authReducer),
    cart: persistReducer(cartConfig, cartReducer),
    product: persistReducer(productConfig, productReducer),
    order: persistReducer(ordersConfig, ordersReducer),
    vendor: persistReducer(vendorConfig, vendorReducer)
});

const middleware = [thunk];
const middlewareEnhancer = applyMiddleware(...middleware);
export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
export const store =
    process.env.NODE_ENV === 'development'
        ? createStore(persistedReducer, composeWithDevTools(middlewareEnhancer))
        : createStore(persistedReducer, middlewareEnhancer);

export type AppState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<
    void | any,
    AppState,
    null,
    AnyAction
>

console.log(`NODE_ENV: ${process.env.NODE_ENV}`);
console.log(`REACT_APP_ENV: ${process.env.REACT_APP_ENV}`);

export const persistor = persistStore(store);