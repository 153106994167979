import { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { CartState, CartItems } from '../../store/cart/types';
import { SystemState } from '../../store/system/types';
import { setSystemState } from '../../store/system/actions';
import {
  setCartState,
  fetchCartItems,
  deleteCartItem,
  deleteAllCartItems,
  updateCartItemQuantity,
} from '../../store/cart/actions';

import Layout from '../common/Layout';

import {
  CartContainer,
  CartGridContainer,
  CartItemsContainer,
  CartSummaryContainer,
} from './fragments/CartComponents';
import CartSummary from './fragments/CartSummary';
import CartItemList from './fragments/CartItemList';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import find from 'lodash/find';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import { colors } from '../../theme/defaultTheme';

interface CartProps {
  cart: CartState;
  system: SystemState;
  setCartState: typeof setCartState;
  fetchCartItems: typeof fetchCartItems;
  deleteCartItem: typeof deleteCartItem;
  setSystemState: typeof setSystemState;
  deleteAllCartItems: typeof deleteAllCartItems;
  updateCartItemQuantity: typeof updateCartItemQuantity;
}

class Cart extends Component<CartProps> {
  // _updateCartItem = debounce(
  //   (bagId: string, value: string) => {
  //     this.props.updateCartItemQuantity(bagId, value);
  //   },
  //   300,
  //   { leading: false }
  // );

  componentDidMount = () => {
    this.props.fetchCartItems();
  };

  _onCLickSelectAllCheckBox = () => {
    const { cartItems, checkOutItems } = this.props.cart;
    const filteredCartItems = filter(cartItems, (item) => item.isOutOfStock !== true).filter((item) => item.quantity !== 0 );;
    const filteredCheckOutItems = filter(checkOutItems, (item) => item.isOutOfStock !== true).filter((item) => item.quantity !== 0 );;
    if (filteredCartItems.length === filteredCheckOutItems.length) {
      this.props.setCartState({
        checkOutItems: [],
        cartSummaryDiscount: 0,
        cartSummarySubTotal: 0,
      });
    } else {
      this.props.setCartState({ checkOutItems: filteredCartItems });
      this._calculateCartSummary(filteredCartItems);
    }
  };

  _onClickSelectAllVendorCheckBox = (vendorId: string) => {
    const { cartItems, checkOutItems } = this.props.cart;
    const filteredVendorCartItems = filter(cartItems, { vendorId, isOutOfStock: false }).filter((item) => item.quantity !== 0 );;
    const filteredVendorCheckOutItems = filter(checkOutItems, { vendorId, isOutOfStock: false }).filter((item) => item.quantity !== 0 );;

    if (filteredVendorCartItems.length === filteredVendorCheckOutItems.length) {
      this.props.setCartState({
        checkOutItems: filter(checkOutItems, (i) => i.vendorId !== vendorId),
      });
      this._calculateCartSummary(filter(checkOutItems, (i) => i.vendorId !== vendorId));
    } else {
      // If a product of the vendor exist in check out items. Remove it and add all the products
      const newCheckOutItems = filter(checkOutItems, (i) => i.vendorId !== vendorId);
      this.props.setCartState({
        checkOutItems: [...newCheckOutItems, ...filteredVendorCartItems],
      });
      this._calculateCartSummary([...newCheckOutItems, ...filteredVendorCartItems]);
    }
  };

  _onClickSelectProductCheckBox = (bagId: string) => {
    const { cartItems, checkOutItems } = this.props.cart;
    const productIsInCheckOutItems = find(checkOutItems, { id: bagId });
    const product = find(cartItems, (i) => i.id === bagId);
    console.log(bagId)
    if (productIsInCheckOutItems) {
      this.props.setCartState({
        checkOutItems: filter(checkOutItems, (i) => i.id !== bagId),
      });
      this._calculateCartSummary(filter(checkOutItems, (i) => i.id !== bagId));
    } else {
      if (product) {
        this.props.setCartState({
          checkOutItems: [...checkOutItems, product],
        });
        this._calculateCartSummary([...checkOutItems, product]);
      }
    }
  };

  _onClickDeleteProduct = (bagId: string) => {
    this.props.setSystemState({
      systemDialogOpen: true,
      systemDialogMaxWidth: 'lg',
      systemDialogTitle: 'Confirm Delete',
      systemDialogContent: 'Are you sure you want to delete this product?',
      systemDialogSimple: true,
      systemDialogConfirm: true,
      systemOverrideTitle: 'Yes',
      systemDialogConfirmAction: async () => {
        this.props.deleteCartItem(bagId);
      },
    });
  };

  _onClickDeleteAllProduct = () => {
    this.props.setSystemState({
      systemDialogOpen: true,
      systemDialogMaxWidth: 'lg',
      systemDialogTitle: 'Confirm Delete',
      systemDialogContent: 'Are you sure you want to delete all items?',
      systemDialogSimple: true,
      systemDialogConfirm: true,
      systemOverrideTitle: 'Yes',
      systemDialogConfirmAction: async () => {
        this.props.deleteAllCartItems();
      },
    });
  };

  _onChangeQuantityInput = (bagId: string, value: string) => {
    const { cartItems, checkOutItems } = this.props.cart;
    if (value.length > 0 && value !== '0') {
      let newCartItems = [...cartItems];
      let newCheckOutItems = [...checkOutItems];

      const cartItemIndex = findIndex(newCartItems, { id: bagId });
      const checkOutItemIndex = findIndex(newCheckOutItems, { id: bagId });

      if (cartItemIndex > -1) {
        if (parseInt(value) > newCartItems[cartItemIndex].marketStock) {
          value = newCartItems[cartItemIndex].marketStock.toString()
        }
        newCartItems[cartItemIndex].quantity = !value ? 0 : parseInt(value);
      } 
      if (checkOutItemIndex > -1) {
        if (parseInt(value) > newCheckOutItems[checkOutItemIndex].marketStock) {
          value = newCheckOutItems[checkOutItemIndex].marketStock.toString()
        }
        newCheckOutItems[checkOutItemIndex].quantity = !value ? 0 : parseInt(value);
      }
        
      this.props.setCartState({
        cartItems: newCartItems,
        checkOutItems: newCheckOutItems,
      });
      
      this._calculateCartSummary(newCheckOutItems);
      this.props.updateCartItemQuantity(bagId, value);
    } else {
      let newCartItems = [...cartItems];
      let newCheckOutItems = [...checkOutItems];

      const cartItemIndex = findIndex(newCartItems, { id: bagId });
      const checkOutItemIndex = findIndex(newCheckOutItems, { id: bagId });

      if (cartItemIndex > -1) {
        if (parseInt(value) > newCartItems[cartItemIndex].marketStock) {
          newCartItems[cartItemIndex].quantity = newCartItems[cartItemIndex].marketStock
        }
        else {
          newCartItems[cartItemIndex].quantity = !value ? 0 : parseInt(value);
        }
      }
      if (checkOutItemIndex > -1) {
        if (parseInt(value) > newCheckOutItems[checkOutItemIndex].marketStock) {
          newCheckOutItems[checkOutItemIndex].quantity = newCheckOutItems[checkOutItemIndex].marketStock
        }
        else {
          newCheckOutItems[checkOutItemIndex].quantity = !value ? 0 : parseInt(value);
        }
      }
        
      this.props.setCartState({
        cartItems: newCartItems,
        checkOutItems: newCheckOutItems,
      });
      this._calculateCartSummary(newCheckOutItems);
    }
  };

  _calculateCartSummary = (checkOutItems: Array<CartItems>) => {
    let discount = 0;
    let subTotal = 0;

    checkOutItems.forEach((item) => {
      if (item.discountPrice > 0) {
        discount += (item.price as number) - item.discountPrice;
      }
      subTotal += (item.price as number) * item.quantity;
    });
    this.props.setCartState({
      cartSummaryDiscount: discount,
      cartSummarySubTotal: subTotal,
    });
  };

  _onChangePoNumber = (value: string) => this.props.setCartState({ cartSummaryPoNumber: value });

  _onPressProceedToCheckOutButton = () => {
    if (this.props.cart.checkOutItems.length > 0) {
      this.props.setSystemState({
        redirectTo: '/checkout',
        shallRedirect: true,
      });
    } else {
      this.props.setSystemState({
        snackBarIsOpen: true,
        snackBarMessage: 'You must select an item before proceeding to checkout',
        snackBarType: 'warning',
      });
    }
  };

  render() {
    const {
      cartItems,
      checkOutItems,
      cartLoading,
      cartSummaryDiscount,
      cartSummarySubTotal,
      cartSummaryPoNumber,
    } = this.props.cart;
    return (
      <Layout>
        {cartLoading ? (
          <Box
            display='flex'
            height='100%'
            width='100%'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress size={25} style={{ color: colors.primary }} />
          </Box>
        ) : (
          <CartContainer>
            {cartLoading ? (
              <CircularProgress size={50} style={{ color: colors.primary }} />
            ) : (
              <CartGridContainer container>
                <CartItemsContainer>
                  <CartItemList
                    items={cartItems}
                    checkOutItems={checkOutItems}
                    onPressDeleteProduct={this._onClickDeleteProduct.bind(this)}
                    onChangeQuantityInput={this._onChangeQuantityInput.bind(this)}
                    onPressDeleteAllProduct={this._onClickDeleteAllProduct.bind(this)}
                    onPressSelectAllCheckBox={this._onCLickSelectAllCheckBox.bind(this)}
                    onPressSelectProductCheckBox={this._onClickSelectProductCheckBox.bind(this)}
                    onPressSelectAllVendorCheckBox={this._onClickSelectAllVendorCheckBox.bind(this)}
                  />
                </CartItemsContainer>
                <CartSummaryContainer>
                  <CartSummary
                    type='CART'
                    checkOutItems={checkOutItems}
                    discount={cartSummaryDiscount}
                    subTotal={cartSummarySubTotal}
                    poNumber={cartSummaryPoNumber}
                    onChangePoNumberInput={this._onChangePoNumber.bind(this)}
                    onClickProceedToCheckOutButton={this._onPressProceedToCheckOutButton.bind(this)}
                  />
                </CartSummaryContainer>
              </CartGridContainer>
            )}
          </CartContainer>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  cart: state.cart,
});

const mapDispatchToProps = {
  setCartState,
  fetchCartItems,
  deleteCartItem,
  setSystemState,
  deleteAllCartItems,
  updateCartItemQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
