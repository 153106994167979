
import React, { useEffect } from 'react'
import HomeUserBestSellers from './HomeUserBestSellers'
import HomeUserProductOnSale from './HomeUserProductOnSale'
import HomeUserRecommended from './HomeUserRecommended'
import HomeFeaturedProductsSection from './HomeFeaturedProductsSection'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store'
import useLocale from '../hooks/useLocale'
import { getUserCountryCode } from '../../../utils/helpers'


const HomeUserProductSection = () => {
  const [userCCode,setUserCCode]=React.useState<string>("PH")
  const user = useSelector((state:AppState)=> state.auth.isLoggedIn)
  const userCountry= useLocale()
  

  return (
    <React.Fragment>
      <HomeFeaturedProductsSection country={userCountry} />
        <HomeUserBestSellers country={userCountry}/>
        <HomeUserProductOnSale country={userCountry} />
        {user && <HomeUserRecommended country={userCountry} />}
    </React.Fragment>
  )
}

export default HomeUserProductSection