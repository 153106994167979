import React, { CSSProperties } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import { styled } from '@material-ui/core';
import { appColors } from '../../utils/ui-utils';

interface FormSelectProps {
  label: string;
  options: string[];
  helperText?: string;
}

const labelStyles: CSSProperties = {
  fontSize: 15,
  color: appColors.grayText,
  marginBottom: 8,
  display: 'block',
};

const Select = styled(MuiSelect)({
  '&.MuiOutlinedInput-root': {
    borderRadius: 0,
    height: 37.63,
    padding: 0,

    '& .MuiSelect-root': {
      backgroundColor: 'white',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  '& .MuiSelect-outlined': {
    borderRadius: 0,
  },
});

const FormSelect = (props: FormSelectProps & Omit<SelectProps, 'label'>) => {
  const { label, options, helperText, ...selectProps } = props;

  return (
    <Box flex={1} display='flex' flexDirection='column' mb={2} position='relative'>
      <Typography component='label' htmlFor={selectProps.name} style={labelStyles}>
        {label}
      </Typography>
      <Select
        {...selectProps}
        variant='outlined'
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography
                component='small'
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: appColors.grayText,
                }}
              >
                {selectProps.placeholder}
              </Typography>
            );
          }
          return (
            <Typography
              component='small'
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {selected as string}
            </Typography>
          );
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {selectProps.error && (
        <FormHelperText style={{ color: '#f44336' }}>{helperText}</FormHelperText>
      )}
    </Box>
  );
};

export default FormSelect;
