import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from '../../theme/defaultTheme';

const LoadingIndicator = () => {
  return (
    <Box flex={1} minHeight={300} display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress style={{ color: colors.primary }} />
    </Box>
  );
};

export default LoadingIndicator;
