import { Box, IconButton, styled } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
interface BtnProps {
    clickHandler: () => void;
    canBeClicked: boolean;
    label: string;
  }
export const BannerContainer =styled(Box)({
    maxWidth: "100%",
    height: 'inherit',
    display: 'flex',
    position: 'relative',
    '& .carousel-root': {
        width: '100%',
    },
    '& .carousel-root > .carousel': {
        overflow: 'hidden',
        height: '100%',
    },
    '& .headers-carousel': {
        width: '100%',
    },
    '& .slider.animated': {
        // transform: `translate3d(0%, 0px, 0px)`,
    },
    // '& .slide' : {
    //     minWidth:'50%'
    // }
})
export const CarouselBox = styled(Box)({
  position:"relative",
  zIndex:1
  // '& .next': { 
  //   right: '0', 
  //   top: '0' },
  // '& .prev': { right:  '10', 
  // top: '0',
  //  },
});

export const CarouselStack = styled(Box)({
  "& .carousel-root": {
    width: "100%",
    // zIndex:1
  },
  "& .carousel-root > .carousel": {
    overflow: "unset",
    height: "100%",
    
    marginTop:"100px"
  },
})

export const SliderButton = styled(IconButton)(() => ({
    position: 'absolute',
    
    zIndex: 2,
    backgroundColor: `transparent`,
    color: '#eee',
    width: 28,
    height: 28,
    //border: `2px solid ${theme.palette.secondary.main}`,
  
    '&.next': {
      right: '0',
      top:"0",
      zIndex: 3
    },
  
    '&.prev': {
      right: '10',
      top:"0",
      zIndex: 3
    },
  
    '&:hover': {
      backgroundColor: 'white',
    },
  
    '& > svg': {
      fontSize: 16,
      //color: theme.palette.secondary.main,
    },
  }));

  export const NextButton = (props: BtnProps) => {
    return (
      <SliderButton
        className='slider-btn next'
        onClick={props.clickHandler}
        aria-label={props.label}
        disabled={props.canBeClicked}
      >
        <ArrowForwardOutlinedIcon />
      </SliderButton>
    );
  };

  export const PrevButton = (props: BtnProps) => {
    return (
      <SliderButton
        className='slider-btn prev'
        onClick={props.clickHandler}
        aria-label={props.label}
        disabled={props.canBeClicked}
      >
        <ArrowBackIcon />
      </SliderButton>
    );
  };