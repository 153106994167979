import { useState } from 'react';
import { Address } from '../../../store/cart/types';

import { 
    AddressHeaderContainer, 
    AddressBodyContainer, 
    AddressCheckBoxContainer, 
    CartCheckbox,
    PaymentHeaderContainer,
    PaymentBodyContainer,
    AddressBodyItemContainer
} from '../fragments/CartComponents';

import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

import { ReactComponent as UserLogo } from '../../../assets/images/icons/user-icon-black.svg';
import { ReactComponent as PhoneLogo } from '../../../assets/images/icons/phone-icon-black.svg';

interface DeliveryDetailsProps {
    name: string;
    contactNumber: string;
    paymentMethod: string;
    billingAddress: Address;
    shippingAddress: Address;
    onChangePaymentMethod: (value: string) => void;
    onPressEditAddress: (title: 'Billing Address' | 'Shipping Address') => void;
}

const DeliveryDetails = (props: DeliveryDetailsProps) => {
    const  [isSameAddress, setIsSameAddress] = useState(false);
    const { billingAddress, shippingAddress, onPressEditAddress } = props;
    return (
        <Box display="flex" flexDirection="column" boxSizing="border-box" height="100%" id="checkout-delivery-details">
            <Box fontWeight="600" fontSize="20px" marginBottom="15px">Delivery Details</Box>
            <Box marginBottom="20px" id="checkout-shipping-address">
                <AddressHeaderContainer>
                    <Box fontSize="11px" fontWeight="bold">Shipping Address</Box>
                    <Button id="checkout-edit-shipping-address" onClick={() => onPressEditAddress('Shipping Address')}>Edit</Button>
                </AddressHeaderContainer>
                <AddressBodyContainer>
                    <AddressBodyItemContainer>  
                        <UserLogo fill="#fff" width="15px" height="15px" />
                        <Box fontSize="11px" marginLeft="10px">{props.name}</Box>
                    </AddressBodyItemContainer>
                    <AddressBodyItemContainer>  
                        <PhoneLogo fill="#fff" width="15px" height="15px" />
                        <Box fontSize="11px" marginLeft="10px">{props.contactNumber}</Box>
                    </AddressBodyItemContainer>
                    <AddressBodyItemContainer>  
                        <LocationOnOutlinedIcon fontSize="small" />
                        <Box fontSize="11px" marginLeft="10px">{shippingAddress.addressLine}</Box>
                    </AddressBodyItemContainer>
                </AddressBodyContainer>
                <AddressCheckBoxContainer>
                    <Box display="flex" flexDirection="row" alignItems="center" boxSizing="border-box">
                        <CartCheckbox id="cart-selectall-checkbox" checked={isSameAddress} onChange={(e) => setIsSameAddress(!isSameAddress)}/>
                        <Box fontSize="11px">Use my shipping as my billing address</Box>
                    </Box>
                </AddressCheckBoxContainer>
            </Box>
            
            <Fade in={!isSameAddress} timeout={{ enter: 200, exit: 200 }} style={{ display: !isSameAddress ? 'block' : 'none' }}>
                <Box marginBottom="20px" id="checkout-billing-address">
                    <AddressHeaderContainer>
                        <Box fontSize="11px" fontWeight="bold">Billing Address</Box>
                        <Button id="checkout-edit-billing-address" onClick={() => onPressEditAddress('Billing Address')}>Edit</Button>
                    </AddressHeaderContainer>
                    <AddressBodyContainer>
                        <AddressBodyItemContainer>  
                            <UserLogo fill="#fff" width="15px" height="15px" />
                            <Box fontSize="11px" marginLeft="10px">{props.name}</Box>
                        </AddressBodyItemContainer>
                        <AddressBodyItemContainer>  
                            <PhoneLogo fill="#fff" width="15px" height="15px" />
                            <Box fontSize="11px" marginLeft="10px">{props.contactNumber}</Box>
                        </AddressBodyItemContainer>
                        <AddressBodyItemContainer>  
                            <LocationOnOutlinedIcon fontSize="small" />
                            <Box fontSize="11px" marginLeft="10px">{billingAddress.addressLine}</Box>
                        </AddressBodyItemContainer>
                    </AddressBodyContainer>
                </Box>
            </Fade>

            <Box marginBottom="20px" id="checkout-payment-method">
                <PaymentHeaderContainer>
                    <Box fontSize="11px" fontWeight="bold">Select Payment Method</Box>
                </PaymentHeaderContainer>
                <PaymentBodyContainer>
                    <Checkbox 
                        checked={props.paymentMethod === 'Credit Card' ? true : false}
                        style={{ height: 14, width: 14, padding: 2, borderColor: '#181E28', borderWidth: 1, borderStyle: 'solid' }}
                        checkedIcon={<Box style={{ height: 14, width: 14, borderRadius: 7, backgroundColor: '#181E28' }}/>} 
                        icon={<Box style={{ height: 14, width: 14, borderRadius: 7, backgroundColor: '#FFF' }}/>}
                        onChange={(e) => props.onChangePaymentMethod('Credit Card')}
                    />
                    <Box display="flex" flexDirection="column" boxSizing="border-box" height="100%" marginLeft="30px">
                        <Box fontSize="13px" fontWeight="bold" marginBottom="10px">Credit Card</Box>
                        <Box fontSize="10px" fontWeight="300" style={{ opacity: 0.5 }}>Pay your order using your credit card</Box>
                    </Box>
                </PaymentBodyContainer>
            </Box>
        </Box>
    )
}

export default DeliveryDetails;