import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useSearchParams() {
  const { search, pathname } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  return { searchParams, pathname };
}

export default useSearchParams;
