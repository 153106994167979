import { useQuery } from "react-query"
import { colors } from '../../../theme/defaultTheme';
import { Carousel } from 'react-responsive-carousel';
import {
  CarouselButtonContainer,
  CarouselButton,
  CarouselContainer,
  CarouselItemContainer,
  CarouselItemContainerAux,
} from './HomeComponents';
import { publicAxiosClient } from '../../../utils/apiPublicClient';
import Box from '@material-ui/core/Box';
import LoadingIndicator from '../../common/LoadingIndicator';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import map from 'lodash/map';
import useLocale from '../hooks/useLocale';
import { BannerData } from "../types";
import { useHistory } from "react-router-dom";

const HomeCarousel = () => {
  const userLocale = useLocale()

  const history = useHistory()
  const {data:banners, status, error} = useQuery<BannerData>("banners",
  async ()=> (await publicAxiosClient.get<BannerData>(`/home/public/banner?countryCode=${userLocale}`)).data,
  )
  if(status === 'loading') return <LoadingIndicator />
  if(!banners) return null;
  const activeBanners = banners.banner.filter( i=> i.isActive === true)
  const shopNowHandler = (id:string)=>{
    const filter = activeBanners.filter(i => i.name === id)
    
    history.push(`/search?q=${filter[0].category?.[0].h1}`)

  }

  return (
    <CarouselContainer id='carousel-container'>
      <Carousel
        autoPlay={true}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        interval={5000}
        infiniteLoop={true}
        renderArrowPrev={(clickHandler) => {
          return (
            <CarouselButtonContainer left='28px'>
              <CarouselButton className='carousel-control' onClick={clickHandler}>
                <ChevronLeftOutlinedIcon />
              </CarouselButton>
            </CarouselButtonContainer>
          );
        }}
        renderArrowNext={(clickHandler) => {
          return (
            <CarouselButtonContainer right='28px'>
              <CarouselButton className='carousel-control' onClick={clickHandler}>
                <ChevronRightOutlinedIcon />
              </CarouselButton>
            </CarouselButtonContainer>
          );
        }}
      >
        {map(activeBanners, (i, ind) => (
          <CarouselItemContainer key={`c-${ind}`}>
            <img src={i.image} alt='' style={{aspectRatio:"16/9",objectFit:"cover"}} />
            <CarouselItemContainerAux>
              <Box textAlign='left' marginBottom='8px' fontSize='13px' color={colors.primaryLight}>
                NEW ARRIVALS
              </Box>
              <Box
                className='carousel-item-description'
                textAlign='left'
                fontSize='30px'
                fontWeight='bold'
                color={colors.primaryLight}
              >
                {i.name}
              </Box>
              <Box
                className='carousel-item-button'
                marginTop='8px'
                bgcolor={colors.primary}
                fontSize='13px'
                width='120px'
                height='40px'
                color={colors.white}
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={()=>shopNowHandler(i.name)}
              >
                Shop Now
                <ArrowForwardOutlinedIcon style={{ width: 14, marginLeft: 6 }} />
              </Box>
            </CarouselItemContainerAux>
          </CarouselItemContainer>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

export default HomeCarousel;
