import React from 'react';
import { useHistory } from 'react-router-dom';
import useLoggedInUser from '../hooks/useLoggedInUser';
import { useFavoritesQuery } from '../hooks/favorites-hooks';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FavoriteIcon from '@material-ui/icons/FavoriteBorder';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'capitalize',
      fontSize: 14,
    },
  })
);

function FavoriteWidget() {
  const classes = useStyles();
  const navigate = useHistory();
  const user = useLoggedInUser();

  const { isLoading, data } = useFavoritesQuery();

  const favoriteCount = data ? data.length : 0;

  const favoriteText = `${favoriteCount} item${favoriteCount > 1 ? 's' : ''}`;

  const goToFavorites = () => navigate.push('/me/favorites');

  if (isLoading) {
    return (
      <Box display='flex' alignItems='center' mt={0.5}>
        <Button className={classes.button} startIcon={<FavoriteIcon />} disabled>
          Loading...
        </Button>
      </Box>
    );
  }

  if (!user || !data) return null;

  return (
    <Box display='flex' alignItems='center' mt={0.5}>
      <Button className={classes.button} startIcon={<FavoriteIcon />} onClick={goToFavorites}>
        {favoriteText}
      </Button>
    </Box>
  );
}

export default FavoriteWidget;
