import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { useFollowedByUserQuery, useRemoveFollowedByUser } from '../hooks/followerd-vendor-hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      fontSize: 16,
    },
    productName: {
      fontWeight: 'bold',
      color: '#333',
    },
    cancelBtn: {
      borderRadius: 0,
      textTransform: 'capitalize',
    },
    deleteBtn: {
      borderRadius: 0,
      textTransform: 'capitalize',
      backgroundColor: theme.palette.error.main,

      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
  })
);
const UnFollowVendorPrompt = () => {
    const history = useHistory();
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const followed = useFollowedByUserQuery();

    const unFollow = useRemoveFollowedByUser();

    const currentVendor = followed.data?.find((vendor) => vendor.id === id)

    const handleClose = () => history.replace('/me/followed-vendor');

    const handleDelete = async () => {
        if (!currentVendor) return;
    
        await unFollow.mutateAsync({
          vendorId: currentVendor.id,
          vendorName: currentVendor.companyName
        });
      };
  return (
    <Dialog
    open
    onClose={handleClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    maxWidth='xs'
  >
    <DialogTitle id='alert-dialog-title'>Unfollow Vendor</DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description' className={classes.message}>
        Are you sure you want to unfollow this vendor?{' '}
        <span className={classes.productName}>{currentVendor?.companyName}</span> from your followed list?
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ padding: 16 }}>
      <Button
        variant='outlined'
        color='primary'
        onClick={handleClose}
        className={classes.cancelBtn}
      >
        Cancel
      </Button>
      <Button
        disableElevation
        variant='contained'
        onClick={handleDelete}
        color='primary'
        className={classes.deleteBtn}
      >
        {unFollow ? 'Removing...' : 'Yes, remove it'}
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default UnFollowVendorPrompt