import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { appColors } from '../../utils/ui-utils';
import { OrderDetail } from './order-types';
import { formatToCurrency } from '../../utils/helpers';
import useLoggedInUser from './hooks/useLoggedInUser';

const CustomerTotalSummary = ({ orderDetail }: { orderDetail: OrderDetail }) => {
  const user = useLoggedInUser();

  const isSG = user?.countryCode === 'SG';

  return (
    <Box flex={1} border={`1px solid ${appColors.lightGray}`} p={4}>
      <Typography component='h3' style={{ fontSize: 16, fontWeight: 600, marginBottom: 12 }}>
        Total Summary
      </Typography>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
        <Typography>Sub Total ({orderDetail.items.length})</Typography>
        <Typography>{formatToCurrency(orderDetail.subtotal, !isSG)}</Typography>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
        <Typography>Shipping:</Typography>
        <Typography>Free</Typography>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
        <Typography>Discount:</Typography>
        <Typography>{formatToCurrency(Math.fround(orderDetail.totalDiscount), !isSG)}</Typography>
      </Box>
      <Divider style={{ margin: '24px 0' }} />
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
        <Typography style={{ fontWeight: 600 }}>Grand Total:</Typography>
        <Typography>{formatToCurrency(orderDetail.totalAmount, !isSG)}</Typography>
      </Box>
    </Box>
  );
};

export default CustomerTotalSummary;
