import {
    SET_CART_STATE,
    CartState,
    CartAction,
    RESET_CART_STATE
} from './types';

const INITIAL_STATE: CartState = {
    cartLoading: false,
    cartItems: [],
    checkOutItems: [],
    cartSummaryDiscount: 0,
    cartSummaryShipping: 0,
    cartSummarySubTotal: 0,
    cartSummaryPoNumber: '',
    checkOutLoading: false,
    checkOutAddresses: [],
    checkOutShippingAddress: { addressLine: '', city: '', country: '', customerId: '', dateCreated: '', dateUpdated: '', docType: '', id: '', isDeleted: false, postalCode: 0, province: '', tag: '' },
    checkOutBillingAddress: { addressLine: '', city: '', country: '', customerId: '', dateCreated: '', dateUpdated: '', docType: '', id: '', isDeleted: false, postalCode: 0, province: '', tag: '' },
    checkOutPaymentMethod: 'Credit Card',
    checkOutAddressDialogTitle: 'Shipping Address',
    checkOutAddressDialogOpen: false
}

const reducer = (state = INITIAL_STATE, action: CartAction): CartState => {
    switch (action.type) {
        case SET_CART_STATE:
            return { ...state, ...action.payload };
        case RESET_CART_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default reducer;