import { OrderStatus } from '../order-types';

export const formatOrderStatus = (orderStatus: OrderStatus) => {
  switch (orderStatus) {
    case 'FAILED_DELIVERY':
      return 'Failed Delivery';
    case 'READY_TO_SHIP':
      return 'Ready to Ship';
    default:
      return orderStatus;
  }
};
