import { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { CartState, CartItems } from '../../store/cart/types';
import { SystemState } from '../../store/system/types';
import { setCartState, getCustomerAddresses } from '../../store/cart/actions';

import Layout from '../common/Layout';

import { 
    CartContainer,
    CartGridContainer, 
    CartItemsContainer,
    CartSummaryContainer
} from './fragments/CartComponents';
import CartSummary from './fragments/CartSummary';
import AddressDialog from './fragments/AddressDialog';
import DeliveryDetails from './fragments/DeliveryDetails';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import find from 'lodash/find';

interface CheckOutProps {
    cart: CartState;
    system: SystemState;
    setCartState: typeof setCartState;
    getCustomerAddresses: typeof getCustomerAddresses;
}

class CheckOut extends Component<CheckOutProps> {
    componentDidMount = () => {
        this.props.getCustomerAddresses();
        this._calculateCartSummary(this.props.cart.checkOutItems)
        
        if(this.props.cart.checkOutItems.length <= 0) window.location.replace('/cart')
    }

    _onChangePoNumber = (value: string) => this.props.setCartState({ cartSummaryPoNumber: value });

    _onChangePaymentMethod = (value: string) => this.props.setCartState({ checkOutPaymentMethod : value })
    
    _onClickEditAddress = (title: 'Billing Address' | 'Shipping Address') => {
        this.props.setCartState({
            checkOutAddressDialogTitle: title,
            checkOutAddressDialogOpen: true
        })
    }

    _calculateCartSummary = (checkOutItems: Array<CartItems>) => {
        let discount = 0;
        let subTotal = 0;

        checkOutItems.forEach(item => {
            if (item.discountPrice > 0) {
                discount += item.price as number - item.discountPrice;
            }
            subTotal += item.price as number * item.quantity
        });

        this.props.setCartState({
            cartSummaryDiscount: discount,
            cartSummarySubTotal: subTotal
        })
    }

    _onPressConfirmAddressDialog = (id: string) => {
        console.log('asdlkjasd')
        if (this.props.cart.checkOutAddressDialogTitle === 'Shipping Address') {
            this.props.setCartState({
                checkOutShippingAddress: find(this.props.cart.checkOutAddresses, { id }),
                checkOutAddressDialogOpen: false
            })
        } else {
            this.props.setCartState({
                checkOutBillingAddress: find(this.props.cart.checkOutAddresses, { id }),
                checkOutAddressDialogOpen: false
            })
        }
    }
    
    render () {
        const { checkOutLoading, checkOutItems, cartSummaryDiscount, cartSummarySubTotal, cartSummaryPoNumber, checkOutBillingAddress, checkOutShippingAddress, checkOutPaymentMethod, checkOutAddresses, checkOutAddressDialogTitle, checkOutAddressDialogOpen } = this.props.cart;
        const fName = (this.props.system.session?.userDetails) ? this.props.system.session?.userDetails.firstName : null;
        const lName = (this.props.system.session?.userDetails) ? this.props.system.session?.userDetails.lastName: null;
        const cCode = (this.props.system.session?.userDetails) ? this.props.system.session?.userDetails.countryCode:null;
        const mNum = (this.props.system.session?.userDetails) ? this.props.system.session?.userDetails.mobileNumber:null;
        const billAdd = this.props.system.session?.userDetails ? this.props.system.session?.userDetails.billingAddressId : "";
        const shipAdd = this.props.system.session?.userDetails? this.props.system.session?.userDetails.shippingAddressId :"";
        return (
            <Layout>
                {checkOutLoading ?
                    <Box display="flex" height="100%" width="100%" justifyContent="center" alignItems="center">
                        <CircularProgress size={25} style={{ color: '#000' }} />
                    </Box>
                    :
                    <CartContainer>
                        <CartGridContainer container>
                            <CartItemsContainer>
                                <DeliveryDetails 
                                    shippingAddress={checkOutShippingAddress}
                                    billingAddress={checkOutBillingAddress}
                                    name={fName + ' ' + lName}
                                    contactNumber={cCode + ' ' + mNum}
                                    paymentMethod={checkOutPaymentMethod}
                                    onChangePaymentMethod={this._onChangePaymentMethod.bind(this)}
                                    onPressEditAddress={this._onClickEditAddress.bind(this)}
                                />
                            </CartItemsContainer>
                            <CartSummaryContainer>
                                <CartSummary 
                                    type="CHECKOUT"
                                    checkOutItems={checkOutItems}
                                    discount={cartSummaryDiscount}
                                    subTotal={cartSummarySubTotal}
                                    poNumber={cartSummaryPoNumber}
                                    onChangePoNumberInput={this._onChangePoNumber.bind(this)}
                                    onClickProceedToCheckOutButton={() => {}}
                                />
                            </CartSummaryContainer>
                        </CartGridContainer>
                    </CartContainer>
                }
                <AddressDialog 
                    address={checkOutAddresses}
                    open={checkOutAddressDialogOpen}
                    title={checkOutAddressDialogTitle}
                    selectedShippingAddressId={checkOutShippingAddress.id}
                    selectedBillingAddressId={checkOutBillingAddress.id}
                    onPressClose={() => this.props.setCartState({ checkOutAddressDialogOpen: false })}
                    onPressConfirm={this._onPressConfirmAddressDialog.bind(this)}
                    defaultBillingAddressId={billAdd}
                    defaultShippingAddressId={shipAdd}
                />
            </Layout>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return { 
        cart: state.cart,
        system: state.system
    }
}

const mapDispatchToProps = {
    setCartState,
    getCustomerAddresses
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckOut);