import { Link } from 'react-router-dom';

import { LinkContentContainer, TabsContainer } from './MyOrderComponents';

interface TabsProps {
    activeTab: 'Quotations' | 'Favorites'| 'Followed Vendor' | 'Wishlists' | 'Orders' | 'My Account';
}

const Tabs = (props: TabsProps) => {
    const { activeTab } = props;
    return (
        <TabsContainer>
            <Link to="">
                <LinkContentContainer style={{ color: activeTab === 'Quotations' ? '#159AFE' : '#000', opacity: activeTab === 'Quotations' ? 1 : 0.5, fontWeight: activeTab === 'Quotations' ? 'bold' : 'normal'  }}>
                    Quotations
                </LinkContentContainer>
            </Link>
            <Link to="">
                <LinkContentContainer style={{ color: activeTab === 'Favorites' ? '#159AFE' : '#000', opacity: activeTab === 'Favorites' ? 1 : 0.5, fontWeight: activeTab === 'Favorites' ? 'bold' : 'normal'  }}>
                    Favorites
                </LinkContentContainer>
            </Link>
            <Link to="">
                <LinkContentContainer style={{ color: activeTab === 'Followed Vendor' ? '#159AFE' : '#000', opacity: activeTab === 'Followed Vendor' ? 1 : 0.5, fontWeight: activeTab === 'Followed Vendor' ? 'bold' : 'normal'  }}>
                    Followed Vendor
                </LinkContentContainer>
            </Link>
            <Link to="">
                <LinkContentContainer style={{ color: activeTab === 'Wishlists' ? '#159AFE' : '#000', opacity: activeTab === 'Wishlists' ? 1 : 0.5, fontWeight: activeTab === 'Wishlists' ? 'bold' : 'normal'  }}>
                    Wishlists
                </LinkContentContainer>
            </Link>
            <Link to="/orders">
                <LinkContentContainer style={{ color: activeTab === 'Orders' ? '#159AFE' : '#000', opacity: activeTab === 'Orders' ? 1 : 0.5, fontWeight: activeTab === 'Orders' ? 'bold' : 'normal' }}>
                    Orders
                </LinkContentContainer>
            </Link>
            <Link to="">
                <LinkContentContainer style={{ color: activeTab === 'My Account' ? '#159AFE' : '#000', opacity: activeTab === 'My Account' ? 1 : 0.5, fontWeight: activeTab === 'My Account' ? 'bold' : 'normal'  }}>
                    My Account
                </LinkContentContainer>
            </Link>
        </TabsContainer>
    )
}

export default Tabs;