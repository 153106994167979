import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/styles/withStyles';

export const MyOrdersContainer = withStyles(
    () => ({
        root: {
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 0 50px 0',
            '& a': {
                textDecoration: 'none'
            },
            '& .my-orders-content-container': {
                display: 'flex',
                flexDirection: 'row',
                boxSizing: 'border-box'
            },
            '@media (max-width: 768px)': {
                '& .my-orders-content-container' : {
                    flexDirection: 'column'
                }
            }
        }
    })
)(Box);

export const TabsContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            boxSizing: 'border-box',
            justifyContent: 'center',
            padding: 20,
            '@media (max-width: 768px)': {
                display: 'none'
            }
        }
    })
)(Box);

export const LinkContentContainer = withStyles(
    () => ({
        root: {
            textDecoration: 'none',
            color: '#000',
            opacity: 0.5,
            fontSize: 17,
            marginRight: 30
        }
    })
)(Box);

export const VerticalTabsContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            width: '25%',
            height: 450,
            backgroundColor: '#F5F5F5',
            padding: '30px 0 100px 20px',
            '@media (max-width: 768px)': {
                display: 'none'
            }
        }
    })
)(Box);

export const VerticalTabLinkContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            boxSizing: 'border-box',
            alignItems: 'center',
            paddingBottom: 35,
            '& img': {
                width: 20,
                height: 20,
                opacity: 0.5
            },
            '& .vertical-tab-label': {
                fontSize: 17,
                opacity: 0.5,
                marginLeft: 10
            },
            '& .vertical-tab-label-active': {
                fontSize: 17,
                opacity: 1,
                marginLeft: 10,
                fontWeight: 'bold',
                color: '#159AFE'
            },
            '& .vertical-tab-icon': {
                width: 21,
                height: 21,
                opacity: .5
            }
        }
    })
)(Box);

export const MyOrdersContentContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            width: '75%',
            padding: '0 0 0 40px',
            '@media (max-width: 768px)': {
                width: '100%',
                padding: '0'
            },
        }
    })
)(Box);

export const MyOrdersHeaderContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            boxSizing: 'border-box',
            backgroundColor: '#F5F5F5',
            padding: '15px 20px 15px 20px',
            fontWeight: 'bold',
            fontSize: 16,
            marginBottom: 15
        }
    })
)(Box);

export const OrderBlockHeader = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            backgroundColor: '#F5F5F5',
            padding: '10px 25px 10px 25px',
            alignItems:'center',
            fontWeight: 'bold',
            fontSize: 16,
            '& .order-block-header-left-container': {
                display: 'flex',
                flexDirection: 'row',
                boxSizing: 'border-box',
                alignItems: 'center',
                '& .order-block-vendor-name' : {
                    fontSize: 14,
                    marginRight: 20
                },
                '& .order-block-order-num' : {
                    fontSize: 14,
                    marginRight: 20
                },
                '& .order-block-order-date': {
                    fontSize: 13,
                    fontWeight: 'lighter',
                    opacity: 0.5
                }
            },
            '@media (max-width: 768px)': {
                '& .order-block-header-left-container': {
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                },
                '& .order-block-order-num' : {
                    marginRight: 0,
                    marginBottom: 5
                },
                '& .order-block-vendor-name' : {
                    marginRight: 0,
                    marginBottom: 5
                }
            }
        }
    })
)(Box);

export const HorizontalTabsContainer = withStyles(
    () => ({
        root: {
            display: 'none',
            '@media (max-width: 768px)': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
                marginBottom: 20
            }
        }
    })
)(Box);

export const HorizontalTabsLinkContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            '& a': {
                color: '#000',
                fontSize: 20,
                opacity: 0.5,
                marginRight: 20
            }
        }
    })
)(Box);

export const OrderBlockContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            borderWidth: 1, 
            borderStyle: 'solid', 
            borderColor: '#E8E8E8', 
            marginBottom: 20,
            '& .order-item-image': {
                width: 70, 
                height: 70, 
                paddingRight: 20
            },
            '& .order-item-name': {
                fontSize: 12, 
                fontWeight: 'lighter', 
                width: '50%'
            },
            '& .order-item-qty': {
                fontSize: 12, 
                fontWeight: 'lighter', 
                width: '20%'
            },
            '& .order-item-price': {
                fontSize: 12, 
                fontWeight: 'lighter'
            },
            '& .order-item-mobile-content-container': {
                display: 'none'
            },
            '@media (max-width: 768px)': {
                '& .order-item-mobile-content-container': {
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box'
                },
                '& .order-item-name': {
                    display: 'none'
                },
                '& .order-item-qty': {
                    display: 'none'
                },
                '& .order-item-price': {
                    display: 'none'
                },
                '& .order-item-mobile-name': {
                    fontSize: 12, 
                    fontWeight: 'lighter'
                },
                '& .order-item-priceqty-container': {
                    display: 'flex',
                    flexDirection: 'row',
                    boxSizing: 'border-box',
                    alignItems: 'center',
                    paddingTop: 10
                },
                '& .order-item-mobile-price': {
                    marginRight: 20,
                    fontSize: 12, 
                    fontWeight: 'lighter'
                },
                '& .order-item-mobile-qty': {
                    fontSize: 12, 
                    fontWeight: 'lighter'
                }
            }
        }
    })
)(Box);

export const AddressHomeTag = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            boxSizing: 'border-box',
            backgroundColor: '#e8f7fd',
            borderRadius: 5,
            padding: '4px 15px 4px 15px',
            marginRight: 15,
            width: 100,
            '& svg': {
                color: '#159AFE'
            }
        }
    })
)(Box)
export const AddressOfficeTag = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            boxSizing: 'border-box',
            backgroundColor: '#e9eaf0',
            borderRadius: 5,
            marginRight: 15,
            width: 120,
            height: 40,
            padding: '0 10px 0 10px',
            '& svg': {
                color: '#646593',
                marginRight: 2
            }
        }
    })
)(Box)

export const AddressAndTotalContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            '& .content-container': {
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                width: '48%',
                padding: 20,
                borderWidth: 1, 
                borderColor: '#E8E8E8', 
                borderStyle: 'solid',
                '& .addressandtotal-title': {
                    fontSize: 17,
                    fontWeight: 'bold',
                    marginBottom: 20
                },
                '& .address-customer-name': {
                    fontSize: 13,
                    fontWeight: 'bold',
                    marginBottom: 20
                },
                '& .total-label': {
                    fontWeight: 'lighter',
                    fontSize: 12
                },
                '& .total-value': {
                    fontWeight: 'lighter',
                    fontSize: 12
                },
                '& .grand-total-label': {
                    fontSize: 12,
                    fontWeight: 'bold'
                },
                '& .grand-total-value': {
                    fontSize: 12,
                    fontWeight: 'bold'
                }
            },
            '@media (max-width: 768px)': {
                '& .content-container': {
                    width: '100%',
                    marginBottom: 20
                },
                flexDirection: 'column'
            }
        }
    })
)(Box)
