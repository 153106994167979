import React, { CSSProperties } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { styled } from '@material-ui/core';

import { appColors } from '../../utils/ui-utils';

const labelStyles: CSSProperties = {
  fontSize: 15,
  color: appColors.grayText,
  marginBottom: 8,
  display: 'block',
};

const Input = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,

    '& input:focus fieldset': {
      borderColor: appColors.primary,
    },
  },
});

interface FormAutoCompleteProps
  extends Omit<AutocompleteProps<string, false, false, false>, 'renderInput' | 'label'> {
  label: string;
  error?: boolean;
  helperText?: string;
}

const FormAutoComplete = ({ label, error, helperText, ...props }: FormAutoCompleteProps) => {
  return (
    <Box flex={1} display='flex' flexDirection='column' mb={2} position='relative'>
      <Typography component='label' htmlFor={props.id} style={labelStyles}>
        {label}
      </Typography>
      <Autocomplete
        {...props}
        renderInput={(params) => (
          <Input
            {...params}
            size='small'
            placeholder={props.placeholder}
            variant='outlined'
            style={{ borderRadius: 0 }}
            error={error}
            helperText={helperText}
            FormHelperTextProps={{
              style: {
                marginLeft: 0,
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default FormAutoComplete;
