import React from 'react';
import { ButtonBase } from '@material-ui/core';
import { usePagination } from '@material-ui/lab/Pagination';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from '../../theme/defaultTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ul: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
    },
    button: {
      width: 42,
      height: 42,
      textAlign: 'center',
      fontSize: 16,
      textTransform: 'capitalize',
      borderRadius: 4,

      '&:disabled': {
        color: colors.gray,
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        width: 38,
        height: 38,
      },
    },
    ellipsis: {
      marginTop: 8,
      display: 'inline-block',
    },
  })
);

interface SearchPaginationProps {
  count: number;
  page: number;
  onChange: (page: number) => void;
}

function SearchPagination({ count, page: currentPage, onChange }: SearchPaginationProps) {
  const classes = useStyles();
  const { items } = usePagination({
    count,
    page: currentPage,

    onChange(event, page) {
      onChange(page);
    },
  });

  return (
    <nav>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = <span className={classes.ellipsis}>…</span>;
          } else if (type === 'page') {
            children = (
              <ButtonBase
                className={[classes.button, selected ? 'selected' : ''].join(' ')}
                type='button'
                style={{ color: selected ? colors.primary : 'initial' }}
                {...item}
              >
                {page}
              </ButtonBase>
            );
          } else {
            children = (
              <ButtonBase className={classes.button} type='button' {...item}>
                {type.substring(0, 4)}
              </ButtonBase>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
}

export default SearchPagination;
