import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppState } from '../../store';
import { SystemState } from '../../store/system/types'
import { setSystemState } from '../../store/system/actions';
import { AuthState } from '../../store/auth/types';
import { setAuthState, fetchPhilippines, createUser } from '../../store/auth/actions';

// Global Components
import Layout from '../common/Layout';

// Local Components
import {
    RegisterContainer,
} from './fragments/RegisterComponents';
import RegisterInformation from './fragments/RegisterInformation';
import { getPlaceOptions } from '../../utils/helpers';

interface RegisterProps {
    auth: AuthState;
    system: SystemState;
    createUser: typeof createUser;
    setAuthState: typeof setAuthState;
    setSystemState: typeof setSystemState;
    fetchPhilippines: typeof fetchPhilippines;
    getPlaceOptions: typeof getPlaceOptions;
}

class Register extends Component<RegisterProps> {

    componentDidMount = () => {
        this.props.fetchPhilippines();
    }

    _onClickProceedButton = () => {
        const { firstName, lastName, companyName, email, password, confirmPassword, address, country, province, city, postal, countryCode, mobileNumber, areaCode, landLineNumber } = this.props.auth.registerInformation;
        let valid = true;
        // eslint-disable-next-line
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const hasLowerCase = new RegExp(/^(?=.*[a-z])/);
        const hasUpperCase = new RegExp(/^(?=.*[A-Z])/);
        const hasNumber = new RegExp(/^(?=.*[0-9])/);
        const hasSpecialCharac = new RegExp(/^(?=.*[!@#%&])/);
        this.props.setAuthState({
            registerProceedButtonClicked: true,
            registerErrorInputs: {
                ...this.props.auth.registerErrorInputs,
                name: { 
                    error: !firstName || !lastName ? true : false, 
                    message: !firstName && !lastName ? 'Please enter First name and Last name' : firstName && !lastName  ? 'Please enter Last name' : !firstName && lastName ? 'Please enter First name' : ''
                },
                companyName: { 
                    error: !companyName ? true : false, 
                    message: !companyName ? 'Please enter Company name' : '' 
                },
                email: { 
                    error: !email || !emailRegex.test(email) ? true : false, 
                    message: !email ? 'Please enter Email' : !emailRegex.test(email) ? 'Invalid Email' : ''
                },
                //|| !hasLowerCase.test(password) || !hasUpperCase.test(password) || !hasNumber.test(password) || !hasSpecialCharac.test(password)
                password: { 
                    error: !password || password.length <= 8 || password.length >=36 || !hasLowerCase.test(password) || !hasUpperCase.test(password) || !hasNumber.test(password) || !hasSpecialCharac.test(password) || password !== confirmPassword 
                    ? true : false, 
                    message: !password ? 'Please enter Password' : password.length < 8 
                    ? 'The Password is too short' : password.length > 36 
                    ? 'The Password is too long' : !hasLowerCase.test(password)
                    ? 'The password must contain at least one lower case letter' : !hasUpperCase.test(password)
                    ? 'The password must contain at least one upper case letter' : !hasNumber.test(password)
                    ? 'The password must contain at least one number' : !hasSpecialCharac.test(password)
                    ? 'The password must contain at least one special character' : ''
                },
                confirmPassword: {
                    error: !confirmPassword ? true : confirmPassword !== password ? true : false, 
                    message: !confirmPassword ? 'Please enter Confirm Password' : confirmPassword !== password ? 'Confirm Password doesn`t match the Password' : ''
                },
                mobileNumber: {
                    error: !countryCode || !landLineNumber ? true : false,
                    message: !countryCode && !mobileNumber ? 'Please enter Country Code and Mobile Number' : countryCode && !mobileNumber ? 'Please enter Mobile Number' : !countryCode && mobileNumber ? 'Please enter Country Code' : ''
                },
                landLineNumber: {
                    error: (areaCode && !landLineNumber) || (!areaCode && landLineNumber) ? true : false,
                    message: areaCode && !landLineNumber ? 'Please enter Landline Number' : !areaCode && landLineNumber ? 'Please enter Area Code' : ''
                },
                address: {
                    error: !address ? true : false,
                    message: !address ? 'Please enter Address' : ''
                },
                countryAndProvince: {
                    error: !country || !province ? true : false,
                    message: !country && !province ? 'Please enter Country and Province' : !country && province ? 'Please enter Country' : country && !province ? 'Please enter Province' : ''
                },
                cityAndPostal: {
                    error: !city || !postal ? true : false,
                    message: !city && !postal ? 'Please enter City and Postal' : !city && postal ? 'Please enter City' : city && !postal ? 'Please enter Postal' : ''
                }
            }
        })
        if (!firstName) valid = false;
        if (!lastName) valid = false;
        if (this.props.auth.registerSelectedUserType[0] === 'Vendor' && !companyName) valid = false;
        if (!email || !emailRegex.test(email)) valid = false;
        if (!password || password !== confirmPassword || password.length < 8 || password.length > 36 || !hasLowerCase.test(password) || !hasUpperCase.test(password) ||!hasNumber.test(password) || !hasSpecialCharac.test(password) ) valid = false;
        if (!confirmPassword || confirmPassword !== password) valid = false;
        if (!countryCode || !mobileNumber) valid = false;
        if ((areaCode && !landLineNumber) || (!areaCode && landLineNumber)) valid = false;
        if (areaCode && !landLineNumber) valid = false;
        if (!areaCode && landLineNumber) valid = false;
        if (!address) valid = false;
        if (!country) valid = false;
        if (!province) valid = false;
        if (!city) valid = false;
        if (!postal) valid = false;
        if (valid) this.props.createUser()
    }

    _onRadioListInput = (val: string) => {
        if (this.props.auth.registerSelectedUserType[0] !== val) {
            this.props.setAuthState({
                registerErrorInputs: {
                    name: { error: false, message: '' },
                    companyName: { error: false, message: '' },
                    email: { error: false, message: '' },
                    password: { error: false, message: '' },
                    confirmPassword: { error: false, message: '' },
                    mobileNumber: { error: false, message: '' },
                    landLineNumber: { error: false, message: '' },
                    address: { error: false, message: '' },
                    countryAndProvince: { error: false, message: '' },
                    cityAndPostal: { error: false, message: '' }
                }
            })
        }
        this.props.setAuthState({
            registerSelectedUserType: [val]
        })
    }

    _onChangeRegisterInformationInput = (property: string, value: string) => {
        this.props.setAuthState({
            registerInformation: { 
                ...this.props.auth.registerInformation,
                [property]: value
            }
        })
        
        if (property === 'firstName') {
            if (value && this.props.auth.registerInformation.lastName) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, name: { error: false, message: '' }}})
            } else if (value && !this.props.auth.registerInformation.lastName) {
                if (this.props.auth.registerProceedButtonClicked) {
                    this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, name: { error: true, message: 'Please enter Last Name' }}})
                }
            }
        } else if (property === 'lastName') {
            if (value && this.props.auth.registerInformation.firstName) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, name: { error: false, message: '' }}})
            } else if (value && !this.props.auth.registerInformation.firstName) {
                if (this.props.auth.registerProceedButtonClicked) {
                    this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, name: { error: true, message: 'Please enter First Name' }}})
                }
            }
        } else if (property === 'companyName') {
            if (value) this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, companyName: { error: false, message: '' }}})
        } else if (property === 'email') {
            if (value) this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, email: { error: false, message: '' }}})
        } else if (property === 'password') {
            if (value) this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, password: { error: false, message: '' }}})
        } else if (property === 'confirmPassword') {
            if (value) this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, confirmPassword: { error: false, message: '' }}})
        } else if (property === 'address') {
            if (value) this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, address: { error: false, message: '' }}})
        } else if (property === 'country') {
            if (value && this.props.auth.registerInformation.province) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, countryAndProvince: { error: false, message: '' }}})
            } else if (value && !this.props.auth.registerInformation.province) {
                if (this.props.auth.registerProceedButtonClicked) {
                    this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, countryAndProvince: { error: true, message: 'Please enter Province' }}})
                }
            }
        } else if (property === 'province') {
            if (value && this.props.auth.registerInformation.country) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, countryAndProvince: { error: false, message: '' }}})
            } else if (value && !this.props.auth.registerInformation.country) {
                if (this.props.auth.registerProceedButtonClicked) {
                    this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, countryAndProvince: { error: true, message: 'Please enter Country' }}})
                }
            }
        } else if (property === 'city') {
            if (value && this.props.auth.registerInformation.postal) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, cityAndPostal: { error: false, message: '' }}})
            } else if (value && !this.props.auth.registerInformation.postal) {
                if (this.props.auth.registerProceedButtonClicked) {
                    this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, cityAndPostal: { error: true, message: 'Please enter Postal' }}})
                }
            }
        } else if (property === 'postal') {
            if (value && this.props.auth.registerInformation.city) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, cityAndPostal: { error: false, message: '' }}})
            } else if (value && !this.props.auth.registerInformation.city) {
                if (this.props.auth.registerProceedButtonClicked) {
                    this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, cityAndPostal: { error: true, message: 'Please select City' }}})
                }
            }
        } else if (property === 'countryCode') {
            if (value && this.props.auth.registerInformation.mobileNumber) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, mobileNumber: { error: false, message: '' }}})
            } else if (value && !this.props.auth.registerInformation.mobileNumber) {
                if (this.props.auth.registerProceedButtonClicked) {
                    this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, mobileNumber: { error: true, message: 'Please enter Mobile Number' }}})
                }
            }
        } else if (property === 'mobileNumber') {
            if (value && this.props.auth.registerInformation.countryCode) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, mobileNumber: { error: false, message: '' }}})
            } else if (value && !this.props.auth.registerInformation.countryCode) {
                if (this.props.auth.registerProceedButtonClicked) {
                    this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, mobileNumber: { error: true, message: 'Please entry Country Code' }}})
                }
            }
        } else if (property === 'areaCode') {
            if (!value && !this.props.auth.registerInformation.landLineNumber) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, landLineNumber: { error: false, message: '' }}})
            } else if (!value && this.props.auth.registerInformation.landLineNumber) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, landLineNumber: { error: true, message: 'Please enter Area Code' }}})
            } else if (value && this.props.auth.registerInformation.landLineNumber) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, landLineNumber: { error: false, message: '' }}})
            }
        } else if (property === 'landLineNumber') {
            if (!value && !this.props.auth.registerInformation.areaCode) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, landLineNumber: { error: false, message: '' }}})
            } else if (!value && this.props.auth.registerInformation.areaCode) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, landLineNumber: { error: true, message: 'Please enter Landline Number' }}})
            } else if (value && this.props.auth.registerInformation.areaCode) {
                this.props.setAuthState({ registerErrorInputs: { ...this.props.auth.registerErrorInputs, landLineNumber: { error: false, message: '' }}})
            }
        }
    }

    render () {
        const { registerLoading, registerUserTypes, registerSelectedUserType, isLoggedIn, registerCountries, registerProvinces, registerCities, registerInformation, registerErrorInputs } = this.props.auth;
        return (
            <Layout>
                {isLoggedIn ? <Redirect to="/home" />  :
                    <RegisterContainer>
                        <RegisterInformation 
                            registerCities={registerCities}
                            registerLoading={registerLoading}
                            registerCountries={registerCountries}
                            registerProvinces={registerProvinces}
                            registerUserTypes={registerUserTypes}
                            registerErrorInputs={registerErrorInputs}
                            registerInformation={registerInformation}
                            registerSelectedUserType={registerSelectedUserType}
                            onPressProceedButton={this._onClickProceedButton.bind(this)}
                            onRadioListInput={this._onRadioListInput.bind(this)}
                            onChangeRegisterInformationinput={this._onChangeRegisterInformationInput.bind(this)}
                        />
                    </RegisterContainer>
                }
            </Layout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    auth: state.auth
});

const mapDispatchToProps = {
    createUser,
    setAuthState,
    setSystemState,
    fetchPhilippines
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);