import { Link } from 'react-router-dom';

import { HorizontalTabsContainer, HorizontalTabsLinkContainer } from './MyOrderComponents';

interface HorizontalTabsProps {
    activeSubTab: 'My Orders' | 'Track Orders';
}

const HorizontalTabs = (props: HorizontalTabsProps) => {
    const { activeSubTab } = props;
    return (
        <HorizontalTabsContainer>
            <HorizontalTabsLinkContainer>
                <Link to="/orders" style={{ color: activeSubTab === 'My Orders' ? '#159AFE' : '#000', fontWeight: activeSubTab === 'My Orders' ? 'bold' : 'normal', opacity: activeSubTab === 'My Orders' ? 1 : 0.5 }}>My Orders</Link>
            </HorizontalTabsLinkContainer>
            <HorizontalTabsLinkContainer>
                <Link to="/" style={{ color: activeSubTab === 'Track Orders' ? '#159AFE' : '#000', fontWeight: activeSubTab === 'Track Orders' ? 'bold' : 'normal', opacity: activeSubTab === 'Track Orders' ? 1 : 0.5 }}>Track Orders</Link>
            </HorizontalTabsLinkContainer>
        </HorizontalTabsContainer>
    )
}

export default HorizontalTabs;