import React from 'react';

import Box from '@material-ui/core/Box';

import UserSideLinks from '../UserSideLinks';
import ListTitle from '../../common/ListTitle';
import NoDataView from '../../common/NoDataView';
import LoadingIndicator from '../../common/LoadingIndicator';

import { useGetCustomerActiveOrders } from '../hooks/useGetCustomerActiveOrders';
import TrackOrderGroup from './TrackOrderGroup';

const TrackOrdersPage = () => {
  const orders = useGetCustomerActiveOrders();

  if (orders.isLoading) return <LoadingIndicator />;

  const isEmpty = !orders.data || orders.data.length === 0;

  return (
    <Box display='flex' alignItems='flex-start' gridColumnGap={32}>
      <UserSideLinks />
      <Box flex={1}>
        <ListTitle title='Track Orders' />
        {isEmpty ? (
          <NoDataView message='You have no orders to track yet' />
        ) : (
          orders.data?.map((order) => <TrackOrderGroup key={order.id} order={order} />)
        )}
      </Box>
    </Box>
  );
};

export default TrackOrdersPage;
