import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomerAddressInputs } from '../my-account/my-account-utils';
import { createAddress } from '../user-service';
import useLoggedInUser from './useLoggedInUser';

export default function useCreateAddress() {
  const user = useLoggedInUser();
  const dispatch = useDispatch();

  const history = useHistory();

  const queryClient = useQueryClient();

  return useMutation(
    (address: CustomerAddressInputs) => createAddress(user?.id as string, address),
    {
      retry: 1,
      onSuccess: (addressId, values) => {
        dispatch({
          type: 'set_system_state',
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: 'Your address was saved successfully!',
            snackBarType: 'success',
          },
        });

        const updateUserFields: any = {};
        const { isDefaultBillingAddress, isDefaultShippingAddress } = values;

        if (isDefaultBillingAddress) updateUserFields.billingAddressId = addressId;
        if (isDefaultShippingAddress) updateUserFields.shippingAddressId = addressId;

        dispatch({
          type: 'set_system_user_details',
          payload: updateUserFields,
        });

        queryClient.invalidateQueries('address:' + user?.id);

        history.replace('/me/address');
      },
      onError: (error: any) => {
        let err = 'An unknown error occured';

        if (error.response) {
          if (error.response.status === 401) return;

          err = error.response.data?.error?.message;
        }

        if (error.message) {
          err = error.message;
        }

        dispatch({
          type: 'set_system_user_details',
          payload: {
            snackBarIsOpen: true,
            snackBarMessage: err,
            snackBarType: 'error',
          },
        });
      },
    }
  );
}
