export type DynamicAuthInputType = string | boolean | number | undefined | Array<string> | RegisterInformationType | ErrorInputs | UserType | ResetPasswordErrorInputsType | LoginErrorInputs | ForgotPasswordErrorInputs;

interface DynamicAuthStateInput<T> {
    [key: string]: T;
}

export interface AuthStateInput extends DynamicAuthStateInput<DynamicAuthInputType> {}

export interface AuthState {
    username: string;
    password: string;
    rememberMe: boolean;
    isLoggedIn: boolean;
    loginLoading: boolean;
    loginErrorInputs: LoginErrorInputs;
    registerLoading: boolean;
    registerUserTypes: Array<string>;
    registerSelectedUserType: ['Customer'] | ['Vendor'];
    registerCountries: Array<string>;
    registerProvinces: Array<string>;
    registerCities: Array<string>;
    registerInformation: RegisterInformationType;
    registerErrorInputs: ErrorInputs;
    registerProceedButtonClicked: boolean;
    forgotPasswordEmail: string;
    forgotPasswordLoading: boolean;
    forgotPasswordErrorInputs: ForgotPasswordErrorInputs;
    resetPasswordNewPassword: string;
    resetPasswordConfirmNewPassword: string;
    resetPasswordLoading: boolean;
    resetPasswordUser: UserType;
    resetPasswordForgotPasswordId: string;
    resetPasswordSuccess: boolean;
    resetPasswordErrorInputs: ResetPasswordErrorInputsType;
    resetPasswordValidMinimum: boolean;
    resetPasswordValidMaximum: boolean;
    resetPasswordValidMixCase: boolean;
    resetPasswordValidNumber: boolean;
}

export interface ForgotPasswordErrorInputs {
    email: { error: boolean; message: string };
}

export interface ResetPasswordErrorInputsType {
    newPassword: { error: boolean; message: string };
    confirmPassword: { error: boolean; message: string };
}

export interface UserType {
    email: string;
    firstName: string;
    forgotPasswordId: string;
    id: string;
    lastName: string;
}

export interface RegisterInformationType {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    password: string;
    confirmPassword: string;
    countryCode: string;
    mobileNumber: string;
    areaCode: string;
    landLineNumber: string;
    address: string;
    country: string;
    province: string;
    city: string;
    postal: string;
}

export interface LoginErrorInputs {
    email: { error: boolean; message: string };
    password: { error: boolean; message: string };
}


export interface ErrorInputs {
    name: { error: boolean; message: string };
    companyName: { error: boolean; message: string };
    email: { error: boolean; message: string };
    password: { error: boolean; message: string };
    confirmPassword: { error: boolean; message: string };
    mobileNumber: { error: boolean; message: string };
    landLineNumber: { error: boolean; message: string };
    address: { error: boolean; message: string };
    countryAndProvince: { error: boolean; message: string };
    cityAndPostal: { error: boolean; message: string };
}

export const SET_AUTH_STATE = 'set_auth_state';
export const RESET_AUTH_STATE = 'reset_auth_state';

export interface SetAuthStateAction {
    type: typeof SET_AUTH_STATE;
    payload: AuthStateInput;
}

export interface ResetAuthStateAction {
    type: typeof RESET_AUTH_STATE;
    payload: null;
}

export type AuthAction =  SetAuthStateAction | ResetAuthStateAction;