import { Box, Container, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

export const ProductDetailContainer = withStyles(
    ()=>({
        root:{
            width:'100%',
            display:'column',
            padding:'20px'

        }
    })
)(Container)

export const ProductDetailCategory = withStyles(
    ()=>({
        root:{
        height:'30%',
        width:'70%',
        paddingLeft:'0',
        paddingBottom:'10px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        }
    })
)(Box)

export const ProductDetailBody = withStyles(
    ()=>({
        root:{
            display:'grid',
            gridTemplateColumns:'30% 20% 50%'
        }
    })
)(Box)

export const ProductDetailContainerBody = withStyles(
    ()=>({
        root:{
            display:'flex',
            flexDirection:'row',
            padding:'0'
        }
    }))(Container)


// export const ProductImageContainer = withStyles(()=>({

// }))(Box)

export const ProductInfoContainer = withStyles(()=>({
    root:{
        display:'grid',
        gridTemplateColumns:'1fr 3repeat'
    }
}))(Box)

export const ProductBox = withStyles(()=>({
    root:{
        display:'flex',
        flexDirection:'row',
        paddingLeft:'20px'
    }
}))(Box)

export const BrandDescription = withStyles(()=>({
    root:{
        marginTop:'20px'
    }
}))(Typography)

export const ProductDescription=withStyles(()=>({
    root:{
        fontSize:'12px',
        color:'grey',
        marginTop:'0px'

    }
}))(Typography)

export const ImageBox = withStyles(()=>({
    root:{
        marginTop:'10px',
        marginRight:'10px'
    }
}))(Box)

export const ProductInfoHeaders = withStyles(()=>({
    root:{
        marginTop:'12px',
        marginRight:'12px'
    }
}))(Box)

export const ProductInfoDetails = withStyles(()=>({
    root:{
        marginTop:'52px',
        marginLeft:'10px'
    }
}))(Box)