import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const HOME_TOKEN = process.env.REACT_APP_MARKET_WEB_HOME_TOKEN;


export const publicAxiosClient = axios.create({
    baseURL: `${API_URL}`,
    headers: {
        Authorization: `Bearer ${HOME_TOKEN}`
    }
});

