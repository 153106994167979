import { 
    OrdersAction,
    OrdersStateInput,
    SET_ORDERS_STATE
} from "./types";
import { AppThunk } from '..';

import axios from 'axios';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';

const API_URL = process.env.REACT_APP_API_URL;

export const setOrdersState = (input: OrdersStateInput) : OrdersAction => {
    return {
        type: SET_ORDERS_STATE,
        payload: input
    }
}

export const fetchCustomerOrders = () : AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_ORDERS_STATE,
            payload: {
                ordersLoading: true
            }
        })
        try {
            const res = await axios.get(`${API_URL}/order/getOrderItemsByCustomerId/${getState().system.session?.userDetails.id}/All`);
            if (res.status === 200) {
                dispatch({
                    type: SET_ORDERS_STATE,
                    payload: {
                        myOrders: orderBy(filter(res.data, (order) => (order.status === 'CANCELLED' || order.status === 'FAILED_DELIVERY' || order.status === 'RECEIVED')), ['date'], ['desc'])
                    }
                })
            }
        } catch (e) {
            console.log(e.response);
        } finally {
            dispatch({
                type: SET_ORDERS_STATE,
                payload: {
                    ordersLoading: false
                }
            })
        }
    }
}

export const getCustomerOrderById = (orderNumber: string, vendorName: string) : AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_ORDERS_STATE,
            payload: {
                orderDetailsLoading: true
            }
        })
        try {
            const res = await axios.get(`${API_URL}/v1/orders/${orderNumber}`);
            if (res.status === 200) {
                dispatch({
                    type: SET_ORDERS_STATE,
                    payload: {
                        orderDetails: {
                            ...res.data,
                            id: orderNumber,
                            vendorName
                        }
                    }
                })
            }
        } catch (e) {
            console.log(e.toString());
        } finally {
            dispatch({
                type: SET_ORDERS_STATE,
                payload: {
                    orderDetailsLoading: false
                }
            })
        }
    }
}