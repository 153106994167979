import {
  FooterContainer,
  FooterAboutUsContainer,
  FooterAboutUsAppStoreContainer,
  FooterContactUsGridItem,
  FooterNavContainer,
} from './CommonComponents';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LogoImg from '../../assets/images/symphony-logo-gold.png';
import { ReactComponent as AppStore } from '../../assets/images/footer/appstore.svg';
import PlayStore from '../../assets/images/footer/playstore.png';
import { colors } from '../../theme/defaultTheme';

const Footer = () => {
  return (
    <FooterContainer className='footer-container'>
      <Grid container={true} style={{ padding: 36, boxSizing: 'border-box' }}>
        {/* About us */}
        <Grid item={true} xs={12} sm={6}>
          <Box display='flex' flexDirection='column' marginBottom='16px'>
            <img src={LogoImg} alt='Symphony Marketplace' height={50} width={240} />
          </Box>
          <FooterAboutUsContainer display='flex' marginBottom='16px' width='60%'>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
            invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
          </FooterAboutUsContainer>
          <FooterAboutUsAppStoreContainer>
            <AppStore />
            <img src={PlayStore} alt='' />
          </FooterAboutUsAppStoreContainer>
        </Grid>
        <FooterContactUsGridItem item={true} xs={12} sm={6}>
          {/* Contact us */}
          <Grid container={true}>
            <Grid item={true} xs={12} sm={8}>
              <Grid container={true}>
                <Grid item={true} xs={12}>
                  <Box fontSize='18px' fontWeight='600' marginBottom='16px'>
                    Find Us
                  </Box>
                  <Box marginBottom='32px'>
                    Potong Pasir, B10 Workaholic Building Braddell Road, Singapore
                  </Box>
                </Grid>
                <Grid item={true} xs={12}>
                  <Box fontSize='18px' fontWeight='600' marginBottom='16px'>
                    Contact Us
                  </Box>
                  <Box marginBottom='16px'>+63 0901 000</Box>
                  <Box marginBottom='16px'>info@suites.digital.com</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} sm={4}>
              <FooterNavContainer>
                <Box>
                  <Box fontSize='18px' fontWeight='600' marginBottom='16px'>
                    Information
                  </Box>
                  <Box marginBottom='16px'>Guides</Box>
                  <Box marginBottom='16px'>Products</Box>
                  <Box marginBottom='16px'>FAQs</Box>
                  <Box marginBottom='16px'>Feedback</Box>
                </Box>
              </FooterNavContainer>
            </Grid>
          </Grid>
        </FooterContactUsGridItem>
      </Grid>
      <Grid container={true} style={{ backgroundColor: colors.darkest, height: 54 }}>
        <Grid
          item={true}
          xs={12}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF' }}
        >
          <Box fontSize='15px' marginRight='8px'>
            ©
          </Box>
          2020 Symphony Market All Rights Reserved
        </Grid>
      </Grid>
    </FooterContainer>
  );
};

export default Footer;
