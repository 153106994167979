import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { AppState } from '../../store';
import { setAuthState, forgotPassword } from '../../store/auth/actions';
import { AuthState } from '../../store/auth/types';
import { SystemState } from '../../store/system/types';
import { setSystemState } from '../../store/system/actions';

// Global Components
import Layout from '../common/Layout';
import Input from '../common/Input';

// Local Components
import { 
    ErrorText,
    SubmitButton,
    ForgotPasswordText,
    ForgotPasswordContent,
    ForgotPasswordContainer,
    ForgotPasswordInputContainer,
    RememberYourPasswordContainer
} from './fragments/ForgotPasswordComponents';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ForgotPasswordProps {
    auth: AuthState;
    system: SystemState;
    setAuthState: typeof setAuthState;
    forgotPassword: typeof forgotPassword;
    setSystemState: typeof setSystemState;
}

class ForgotPassword extends Component<ForgotPasswordProps> {

    _sendEmail = () => {
        const { forgotPasswordEmail } = this.props.auth;
        // eslint-disable-next-line
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        this.props.setAuthState({
            forgotPasswordErrorInputs: {
                email: {
                    error: !forgotPasswordEmail || !emailRegex.test(forgotPasswordEmail) ? true : false,
                    message: !forgotPasswordEmail ? 'Please enter Email' : !emailRegex.test(forgotPasswordEmail) ? 'Invalid Email' : ''
                }
            }
        })
        if (forgotPasswordEmail && emailRegex.test(forgotPasswordEmail)) this.props.forgotPassword();
    }

    _onChangeInput = (property: string, value: string) => {
        // eslint-disable-next-line
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        this.props.setAuthState({
            [property]: value
        })

        if (property === 'forgotPasswordEmail') {
            const { message } = this.props.auth.forgotPasswordErrorInputs.email;
            if (message === 'Please enter Email') {
                if (value) this.props.setAuthState({ forgotPasswordErrorInputs: { ...this.props.auth.forgotPasswordErrorInputs, email: { error: false, message: '' }}})
            } else if (message === 'Invalid Email') {
                if (emailRegex.test(value)) this.props.setAuthState({ forgotPasswordErrorInputs: { ...this.props.auth.forgotPasswordErrorInputs, email: { error: false, message: '' }}})
            }
        }
    }

    _onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            this._sendEmail()
        }
    }

    render() {
        const { forgotPasswordEmail, forgotPasswordLoading, forgotPasswordErrorInputs, isLoggedIn } = this.props.auth;
        return (
            <Layout>
                {isLoggedIn ? <Redirect to="/home" />  :
                    <ForgotPasswordContainer>
                        <ForgotPasswordContent>
                            <ForgotPasswordText>Forgot Password?</ForgotPasswordText>
                            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                                <ForgotPasswordInputContainer>
                                    <Input 
                                        id="forgotpassword-email-input"
                                        type="text"
                                        label=""
                                        value={forgotPasswordEmail}
                                        onChange={(e) => this._onChangeInput('forgotPasswordEmail', e.target.value)}
                                        placeholder="Email"
                                        login={true}
                                        onKeyDown={this._onEnterPress.bind(this)}
                                    />
                                </ForgotPasswordInputContainer>
                                { forgotPasswordErrorInputs.email.error &&
                                    <ErrorText id="forgotpassword-error-text">{forgotPasswordErrorInputs.email.message}</ErrorText>
                                }
                            </Box>
                            <SubmitButton id="forgotpassword-submit-btn" onClick={this._sendEmail.bind(this)}>
                                {forgotPasswordLoading ?
                                    <CircularProgress size={20} style={{ color: '#FFF' }} /> : "Submit"
                                }
                            </SubmitButton>
                            <RememberYourPasswordContainer>
                                <Typography>
                                    Remember your password? 
                                </Typography>
                                <Link to="/login" id="forgotpassword-backtologin-btn">Back To Login</Link>
                            </RememberYourPasswordContainer>
                        </ForgotPasswordContent>
                    </ForgotPasswordContainer>
                }
            </Layout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    auth: state.auth
});

const mapDispatchToProps = {
    setAuthState,
    setSystemState,
    forgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);