import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import { useGetCustomerAddress } from '../hooks/useGetCustomerAddress';
import LoadingIndicator from '../../common/LoadingIndicator';
import NoDataView from '../../common/NoDataView';
import CustomerAddressItem from './CustomerAddressItem';
import { appColors } from '../../../utils/ui-utils';
import FormButton from '../../common/FormButton';
import { useHistory } from 'react-router-dom';

const CustomerAddresList = () => {
  const { data: addresses, isLoading } = useGetCustomerAddress();

  const history = useHistory();

  if (isLoading) return <LoadingIndicator />;

  if (!addresses) return <NoDataView message="You don't have an address yet. Add one now" />;

  const goToAddressForm = () => history.push('/me/address/add');

  return (
    <Box flex={1} display='flex' flexDirection='column'>
      <Box
        bgcolor={appColors.lightGray}
        padding='16px 20px'
        mb={2}
        mt={-1}
        display='grid'
        gridTemplateColumns='350px 120px auto'
        alignItems='center'
      >
        <Typography style={{ fontSize: 15, fontWeight: 600 }}>Address</Typography>
        <Typography style={{ fontSize: 15, fontWeight: 600 }}>Tag</Typography>
        <Typography style={{ fontSize: 15, fontWeight: 600, marginLeft: 'auto' }}>
          Action
        </Typography>
      </Box>
      <Box maxHeight={500} overflow='auto'>
        {addresses.map((address) => (
          <CustomerAddressItem key={address.id} address={address} />
        ))}
      </Box>
      <FormButton
        onClick={goToAddressForm}
        startIcon={<AddIcon />}
        style={{ marginLeft: 'auto', marginTop: 16 }}
      >
        Add New Address
      </FormButton>
    </Box>
  );
};

export default CustomerAddresList;
