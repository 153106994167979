import React from 'react';
import { Link } from 'react-router-dom';
import { Category } from '../../../store/system/types';
import { useCategoriesStyles } from './categoriesStyles';
import CategoryMenu from './CategoryMenu';
import useSearchParams from './useSearchParams';

const CategoryItem = ({ category }: { category: Category }) => {
  const { searchParams } = useSearchParams();

  const isActiveH1 = searchParams.get('h1') === category?.name;

  const classes = useCategoriesStyles();

  return (
    <div className={classes.categoryItemContainer}>
      <Link
        to={`/search?q=${category.name}`}
        className={[
          classes.categoryItem,
          isActiveH1 ? 'active h1' : 'h1',
          category.h2 ? '' : 'click-only',
        ].join(' ')}
      >
        {category.name}
      </Link>
      <div className={['flyout-menu', classes.flyoutMenu].join(' ')}>
        <CategoryMenu category={category} />
      </div>
    </div>
  );
};

export default CategoryItem;
