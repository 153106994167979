import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getCustomerAddress, getCustomerAddressById } from '../user-service';
import useLoggedInUser from './useLoggedInUser';

export function useGetCustomerAddress() {
  const user = useLoggedInUser();

  return useQuery(('address:' + user?.id) as string, () => getCustomerAddress(user?.id as string));
}

export function useGetSingleCustomerAddress() {
  const { addressId } = useParams<{ addressId: string }>();
  const user = useLoggedInUser();

  return useQuery(addressId as string, () => getCustomerAddressById(user?.id as string, addressId));
}
