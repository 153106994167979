import {
    SET_PRODUCT_STATE,
    ProductState,
    ProductAction
} from './types';

const INITIAL_STATE: ProductState = {
    products: [],
    productsLoading: false,
    dialogOpen: false,
    productDetailsLoading: false
}

const reducer = (state = INITIAL_STATE, action: ProductAction): ProductState => {
    switch (action.type) {
        case SET_PRODUCT_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default reducer;