import apiClient, { endpoints } from '../../../utils/apiClient';

interface FollowerItem  {
    id:string;
    companyName:string;
    avatar:{
        path:string;
        
    }
}
interface VendorProducts {
    brand: string,
    color: string,
      dateCreated: string,
      discountPrice: number,
      displayId: String,
      h1:string,
      h2?:string,
      h3?:string,
      id: string,
      isActive: boolean,
      name: string,
      price: number,
      productSkuId: string,
      vendorId: string,
      image: {
        name: string,
        path: string,
        size: number,
        type: string
      },
      isOutOfStock: boolean
  
  }

export const getFollowedByUser = async(userId:string) => {
    const url = `${endpoints.follower}/${userId}`;

   const response = await apiClient.get<FollowerItem[]>(url);

   return response.data;
};

export const removeFollowedByUser = async(userId:string,vendorId:string) =>{
    const url = `${endpoints.follower}/${userId}_${vendorId}`;

    const response = await apiClient.delete(url);

    return response.data;
}

export const getProducts = async()=>{
    const url = `${endpoints.products}`;

    const response = await apiClient.get<VendorProducts[]>(url);

    return response.data;
}