import React, { PropsWithChildren } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { appColors } from '../../utils/ui-utils';

interface NoDataViewProps {
  message: string;
}

const NoDataView = ({ message, children }: PropsWithChildren<NoDataViewProps>) => {
  return (
    <Box
      flex={1}
      minHeight={300}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Typography style={{ color: appColors.grayText, fontSize: 20, fontWeight: 500 }}>
        {message}
      </Typography>
      {children && <Box p={2}>{children}</Box>}
    </Box>
  );
};

export default NoDataView;
