import { useQuery } from 'react-query';
import { publicAxiosClient } from '../../utils/apiPublicClient';
import useLocale from '../home/hooks/useLocale';
import { Product } from '../home/types';

async function publicProductsService(country: string) {
  const url = `v1/public/products?isActive=true&forMarketplace=true&countryCode=${country}`;
  const response = await publicAxiosClient.get<Product[]>(url);
  return response.data;
}

function usePublicProducts() {
  const country = useLocale();

  return useQuery('products', () => publicProductsService(country));
}

export default usePublicProducts;
