import moment from 'moment';

//@ts-ignore
import { provinces, cities } from 'philippines/index';
import sgCities from './singapore/cities.json';
import SGProvinces from './singapore/provinces.json';

export const formatDate = (dateStr: string) => moment(dateStr).format('MMMM D, YYYY');

export const formatOrderHistoryDate = (dateStr: string) => moment(dateStr).format('LLL');

export const formatToCurrency = (n: number, isPhp: boolean = true) =>
  isPhp
    ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PHP' }).format(n)
    : `S${new Intl.NumberFormat('en-SG', { style: 'currency', currency: 'SGD' }).format(n)}`;

type Province = {
  name: string;
  region: string;
  key: string;
};

type SGProvince = {
  name: string;
  country: string;
  key: string;
};

type City = {
  name: string;
  province: string;
  city?: boolean;
};

const keyToProvinceMap = (provinces as Province[]).reduce<Record<string, string>>((acc, curr) => {
  return {
    ...acc,
    [curr.name]: curr.key,
  };
}, {});

const keyToSGProvinceMap = (SGProvinces as SGProvince[]).reduce<Record<string, string>>(
  (acc, curr) => {
    return {
      ...acc,
      [curr.name]: curr.key,
    };
  },
  {}
);

export const phProvinces = (provinces as Province[]).map((p) => p.name);

export const sgProvinces = (SGProvinces as SGProvince[]).map((p) => p.name);

export const getPlaceOptions = (country: string, province: string) => {
  const provinceOptions = country === 'Philippines' ? phProvinces : sgProvinces;

  const cityOptions = country === 'Philippines' ? getPhCities(province) : getSgCities(province);

  return { provinceOptions, cityOptions };
};

export const getPhCities = (provinceName: string) =>
  (cities as City[])
    .filter((c) => c.province === keyToProvinceMap[provinceName])
    .map((i) => i.name);

export const getSgCities = (provinceName: string) =>
  (sgCities as City[])
    .filter((c) => c.province === keyToSGProvinceMap[provinceName])
    .map((i) => i.name);

export const getUserCountryCode =() => {
  const cData = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userCountry = cData.split("/");
  const sgChecker = sgProvinces.filter(i=> userCountry[1] === i)
  return sgChecker
  
}