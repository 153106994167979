import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { appColors } from '../../utils/ui-utils';
import { OrderDetail } from './order-types';

const CustomerAddressCard = ({ orderDetail }: { orderDetail: OrderDetail }) => {
  return (
    <Box flex={1} border={`1px solid ${appColors.lightGray}`} p={4}>
      <Typography component='h3' style={{ fontSize: 16, fontWeight: 600, marginBottom: 12 }}>
        Billing & Shipping Address
      </Typography>
      <Typography style={{ fontSize: 14, fontWeight: 600, marginBottom: 12 }}>
        {orderDetail.customerName}
      </Typography>
      <Typography style={{ fontSize: 15, fontWeight: 600 }}>Shipping Address</Typography>
      <Typography style={{ marginBottom: 12 }}>
        {orderDetail.shippingAddress.addressLine}
      </Typography>
      <Typography style={{ fontSize: 15, fontWeight: 600 }}>Billing Address</Typography>
      <Typography style={{ marginBottom: 12 }}>{orderDetail.billingAddress.addressLine}</Typography>
      <Typography>+63 {orderDetail.mobileNumber}</Typography>
    </Box>
  );
};

export default CustomerAddressCard;
