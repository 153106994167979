import { Component } from  'react';
import { connect } from 'react-redux';
import { RouteComponentProps, match } from 'react-router';
import { AppState } from '../../store';
import { OrdersState } from '../../store/orders/types';
import { setOrdersState, getCustomerOrderById } from '../../store/orders/actions';

import Layout from '../common/Layout';

import Tabs from './fragments/Tabs';
import OrderTag from './fragments/OrderTag';
import VerticalTabs from './fragments/VerticalTabs';
import HorizontalTabs from './fragments/HorizontalTabs';
import { 
    OrderBlockHeader,
    MyOrdersContainer, 
    OrderBlockContainer,
    MyOrdersHeaderContainer, 
    MyOrdersContentContainer,
    AddressAndTotalContainer
} from './fragments/MyOrderComponents';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';
import map from 'lodash/map';


interface MatchParams {
    params: { orderId: string, vendorName: string };
}

interface RouteParams extends RouteComponentProps {
    match: match & MatchParams
}

interface MyOrderDetailsProps {
    order: OrdersState;
    setOrdersState: typeof setOrdersState;
    getCustomerOrderById: typeof getCustomerOrderById;
}

class MyOrderDetails extends Component<MyOrderDetailsProps & RouteParams> {
    componentDidMount = () => {
        const { orderId, vendorName } = this.props.match.params;
        this.props.getCustomerOrderById(orderId, vendorName);
    }

    render () {
        const { orderDetailsLoading, activeSubTab, orderDetails } = this.props.order;
        return (
            <Layout>
                {orderDetailsLoading ?
                    <Box display="flex" height="100%" width="100%" justifyContent="center" alignItems="center">
                        <CircularProgress size={25} style={{ color: '#000' }} />
                    </Box>
                    :
                    <MyOrdersContainer>
                        <Tabs activeTab="Orders"/>
                        <Box className="my-orders-content-container">
                            <VerticalTabs activeMainTab="Orders" activeSubTab={activeSubTab}/>
                            <HorizontalTabs activeSubTab={activeSubTab}/>
                            <MyOrdersContentContainer>
                                <MyOrdersHeaderContainer>
                                    Order Details
                                </MyOrdersHeaderContainer>
                                <OrderBlockContainer>
                                    <OrderBlockHeader>
                                        <Box className="order-block-header-left-container">
                                            <Box className="order-block-vendor-name">{orderDetails.vendorName}</Box>
                                            <Box className="order-block-order-num">ORDER NO. {orderDetails.id}</Box>
                                            <Box className="order-block-order-date">{orderDetails.status === 'CANCELLED' ? 'Cancelled' : 'Delivered'} {moment().format('MMMM D YYYY')}</Box>
                                        </Box>
                                        <OrderTag type={orderDetails.status ? orderDetails.status : 'RECEIVED'  }/>
                                    </OrderBlockHeader>
                                    <Box display="flex" flexDirection="row" boxSizing="border-box" justifyContent="space-between">
                                        <Box display="flex" flexDirection="column" boxSizing="border-box" style={{ width: '100%' }}>
                                            {map(orderDetails.items, (o) => {
                                                return (
                                                    <Box key={`${orderDetails.id}_${o.name}`} display="flex" flexDirection="row" boxSizing="border-box" alignItems="center" padding="20px 25px">
                                                        <img src={o.image} alt="" className="order-item-image"  />
                                                        <Box className="order-item-name">{o.name}</Box>
                                                        <Box className="order-item-qty">Quantity: {o.qty}</Box>
                                                        <Box className="order-item-price">₱ {o.price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box>
                                                        <Box className="order-item-mobile-content-container">
                                                            <Box className="order-item-mobile-name">{o.name}</Box>
                                                            <Box className="order-item-priceqty-container">
                                                                <Box className="order-item-mobile-price">₱ {o.price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box>
                                                                <Box className="order-item-mobile-qty" >x {o.qty}</Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                </OrderBlockContainer>
                                <AddressAndTotalContainer>
                                    <Box className="content-container">
                                        <Box className="addressandtotal-title">Billing {`&`} Shipping Address</Box>
                                        <Box className="address-customer-name">{orderDetails.customerName}</Box>
                                        <Box display="flex" flexDirection="column" boxSizing="border-box" marginBottom="10px">
                                            <Box fontWeight="bold" marginBottom="10px">Shipping Address</Box>
                                            <Box fontWeight="lighter" fontSize="11px">{orderDetails.shippingAddress.addressLine}</Box>
                                        </Box>

                                        <Box display="flex" flexDirection="column" boxSizing="border-box" marginBottom="20px">
                                            <Box fontWeight="bold" marginBottom="10px">Billing Address</Box>
                                            <Box fontWeight="lighter" fontSize="11px">{orderDetails.billingAddress.addressLine}</Box>
                                        </Box>

                                        <Box fontWeight="lighter" fontSize="11px">+63 {orderDetails.mobileNumber}</Box>
                                    </Box>

                                    <Box className="content-container">
                                        <Box className="addressandtotal-title">Total Summary</Box>
                                        <Box display="flex" flexDirection="row" boxSizing="border-box" justifyContent="space-between" marginBottom="10px">
                                            <Box className="total-label">Sub Total {`(${orderDetails.items.length})`}</Box>
                                            <Box className="total-value">₱ {orderDetails.subtotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" boxSizing="border-box" justifyContent="space-between" marginBottom="10px">
                                            <Box className="total-label">Shipping: </Box>
                                            <Box className="total-value">Free</Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" boxSizing="border-box" justifyContent="space-between" marginBottom="10px">
                                            <Box className="total-label">Discount: </Box>
                                            <Box className="total-value">₱ {orderDetails.totalDiscount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box>
                                        </Box>
                                        <Box style={{ height: 1, backgroundColor: '#000', opacity: 0.1, width: '100%', marginBottom: 20 }} />

                                        <Box display="flex" flexDirection="row" boxSizing="border-box" justifyContent="space-between" marginBottom="10px">
                                            <Box className="grand-total-label">Grand Total: </Box>
                                            <Box className="grand-total-value">₱ {orderDetails.totalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box>
                                        </Box>
                                    </Box>
                                </AddressAndTotalContainer>
                            </MyOrdersContentContainer>
                        </Box>
                    </MyOrdersContainer>
                }
            </Layout>
        )
    } 
}

const mapStateToProps = (state: AppState) => {
    return { 
        order: state.order
    }
}

const mapDispatchToProps = {
    setOrdersState,
    getCustomerOrderById
}

export default connect(mapStateToProps, mapDispatchToProps)(MyOrderDetails);