import { VerticalTabsContainer, VerticalTabLinkContainer } from './MyOrderComponents';

import Box from '@material-ui/core/Box';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

import userImg from '../../../assets/images/header/user.png';

interface VerticalTabsProps {
    activeMainTab: 'My Account' | 'Orders';
    activeSubTab: 'My Profile' | 'Address' | 'My Orders' | 'Track Orders';
}

const VerticalTabs = (props: VerticalTabsProps) => {
    const { activeMainTab, activeSubTab } = props;
    return (
        <VerticalTabsContainer>
            <Box fontSize="22px" padding="0 0 30px 0" fontWeight={activeMainTab === 'My Account' ? 'bold' : 'lighter'}>My Account</Box>
            <VerticalTabLinkContainer>
                <img src={userImg} alt=""/>
                <Box className="vertical-tab-label">My Profile</Box>
            </VerticalTabLinkContainer>
            <VerticalTabLinkContainer>
                <LocationOnOutlinedIcon className="vertical-tab-icon" fontSize="small" />
                <Box className="vertical-tab-label">Address</Box>
            </VerticalTabLinkContainer>
            <Box fontSize="22px" padding="0 0 30px 0" fontWeight={activeMainTab === 'Orders' ? 'bold' : 'lighter'}>Orders</Box>
            <VerticalTabLinkContainer>
                <img src={userImg} alt="" />
                <Box className={activeSubTab === 'My Orders' ?  "vertical-tab-label-active" : "vertical-tab-label"}>My Orders</Box>
            </VerticalTabLinkContainer>
            <VerticalTabLinkContainer>
                <img src={userImg} alt=""/>
                <Box className={activeSubTab === 'Track Orders' ?  "vertical-tab-label-active" : "vertical-tab-label"}>Track Orders</Box>
            </VerticalTabLinkContainer> 
        </VerticalTabsContainer>
    )
}

export default VerticalTabs;