import { Box, styled } from "@material-ui/core";
import { colors } from "../../../theme/defaultTheme";

export const MainBodyContainer = styled(Box)({
    display:"grid",
    gridTemplateColumns:"2fr 1fr",
    gap:"10px"
});

export const PaymentBox = styled(Box)({
    display:"flex",
    flexDirection:"column",
    
})

export const PaymentHeading = styled(Box)({
    backgroundColor:colors.gray,
    padding:"10px 10px",
    margin:"10px 0"
})

export const PaymentIconBox = styled(Box)({
    display:"flex",
    padding:"20px 10px"
})
export const PaymentInfoBox = styled(Box)({
    display:"flex",
    flexDirection:"column",
    border: `1px solid ${colors.gray}`
})

export const BillingBox = styled(Box)({
    display:"flex",
    flexDirection:"column"
})

export const BillBoxInfo = styled(Box)({
    display:"flex",
    flexDirection:"column",
    backgroundColor:colors.gray,
    margin:"10px 0",
    padding:"10px 10px"
})

export const OrderBox = styled(Box)({
    overflow:"scroll",
    height:"200px"
})