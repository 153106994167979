import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { styled } from '@material-ui/core';
import { RiCheckboxCircleFill as CheckIcon } from 'react-icons/ri';

import { orderBy } from 'lodash';

import { OrderHistory } from '../order-types';
import { appColors } from '../../../utils/ui-utils';
import { formatOrderStatus } from './track-order-utils';
import { formatOrderHistoryDate } from '../../../utils/helpers';
import { colors } from '../../../theme/defaultTheme';

const JourneyBox = styled(Box)({
  position: 'relative',
  zIndex: 3,

  '&:not(:last-of-type)::after': {
    content: '""',
    position: 'absolute',
    top: 24,
    left: 10.5,
    width: 3,
    height: 'calc(100% - 15px)',
    backgroundColor: appColors.purpleLight,
    zIndex: 2,
  },
});

const OrderJourney = ({ orderHistory }: { orderHistory: OrderHistory[] }) => {
  const [expanded, setExpanded] = useState(false);

  const historyData = orderBy(orderHistory, ['date'], ['asc']);

  return (
    <Box p={4}>
      <Button
        onClick={() => setExpanded((old) => !old)}
        style={{
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: 15,
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: 12,
          borderRadius: 0,
        }}
      >
        Order Status {expanded ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Collapse in={expanded}>
        <Box ml={2}>
          {historyData.map((historyItem) => (
            <JourneyBox key={historyItem.status} display='flex' pb={2}>
              <CheckIcon
                style={{
                  color: colors.primary,
                  marginRight: 16,
                  fontSize: 26,
                }}
              />
              <Box>
                <Typography style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                  {formatOrderStatus(historyItem.status).toLowerCase()}
                </Typography>
                <Typography style={{ fontSize: 13, color: colors.gray }}>
                  {formatOrderHistoryDate(historyItem.date)}
                </Typography>
                <Typography style={{ color: colors.gray }}>{historyItem.update}</Typography>
              </Box>
            </JourneyBox>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default OrderJourney;
