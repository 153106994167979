import Box from '@material-ui/core/Box';

interface OrderTagProps {
    type: 'FAILED_DELIVERY' | 'CANCELLED' | 'RECEIVED';
}

const OrderTag = (props: OrderTagProps) => {
    const { type } = props;
    return (
        <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="center" 
            boxSizing="border-box" 
            width="100px" 
            height="30px"
            fontSize="13px" 
            style={
                { 
                    color: type === 'FAILED_DELIVERY' ? '#FF841A' : type === 'CANCELLED' ? '#FF4D4D' : '#1DAB22',
                    backgroundColor: type === 'FAILED_DELIVERY' ? '#FFE4CC' : type === 'CANCELLED' ? 'rgb(255, 77, 77, 0.2)' : 'rgb(29, 171, 34, 0.2)',
                    borderRadius: 5 
                }
            }
        >
            {type === 'FAILED_DELIVERY' ? 'Failed Delivery' : type === 'CANCELLED' ? 'Cancelled' : 'Received'}
        </Box>
    )
}

export default OrderTag;