import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

interface SnackBarProps {
    open: boolean;
    position: { vertical: 'top' | 'bottom', horizontal: 'left' | 'center' | 'right' };
    message: string;
    onClose: () => void;
    type: 'success' | 'warning' | 'error';
}

function Alert(props: AlertProps) {
    return (
        <MuiAlert elevation={6} variant="filled" {...props}>
            <Typography>
                {props.children}
            </Typography>
        </MuiAlert>
    );
}

const CustomSnackBar = (props: SnackBarProps) => {
    const { open, position, message, type, onClose } = props;
    return (
        <Snackbar 
            open={open} 
            autoHideDuration={2000} 
            onClose={onClose}
            anchorOrigin={{ vertical: position.vertical, horizontal: position.horizontal }}
        >
            <Alert onClose={onClose} severity={type === 'success' ? 'success' : type === 'warning' ? 'warning' : 'error'}>
                { message }
            </Alert>
        </Snackbar>
    )
}

export default CustomSnackBar;