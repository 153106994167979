export type DynamicVendorInputType = string | boolean | number | undefined | Array<Banner> | Array<VendorProduct> | Array<VendorAllProductsType> | Array<string>;

interface DynamicVendorStateInput<T> {
    [key: string]: T;
}

export interface VendorStateInput extends DynamicVendorStateInput<DynamicVendorInputType> {}

export interface VendorState {
    vendorLoading: boolean;
    vendorAvatar: string;
    vendorName: string;
    vendorIsFollowed: boolean;
    vendorBanner: Array<Banner>;
    vendorBestSellers: Array<VendorProduct>;
    vendorActiveTab: 'HOME' | 'ALLPRODUCTS'
    vendorAllProducts: Array<VendorAllProductsType>;
    vendorAllProductsLoading: boolean;
    vendorCategories: Array<string>;
    vendorActiveCategory: string;
}

export interface Banner {
    bannerNumber: number;
    bannernNumber: number;
    category: Array<string>;
    endDate: string;
    image: string;
    isActive: boolean;
    name: string;
    oldEndDate: string;
    oldStartDate: string;
    startDate: string;
}

export interface VendorProduct {
    brand: string;
    dateCreated: string;
    discountPrice: number;
    h1: string;
    image: MediaType;
    isFavorite: boolean;
    name: string;
    price: number;
    productId: string;
    qty: number;
    size: string;
    vendorId: string;
}

export interface VendorAllProductsType {
    brand: string;
    dateCreated: string;
    discountPrice: number;
    displayId: string;
    h1: string;
    h2: string;
    h3: string;
    id: string;
    image: MediaType;
    isActive: boolean;
    isFavorite: boolean;
    isOutOfStock: boolean;
    name: string;
    price: number;
    productSkuId: string;
    size: string;
    vendorId: string;
}

export interface MediaType {
    name: string;
    path: string;
    size: number;
    type: string;
}

export const SET_VENDOR_STATE = 'set_vendor_state';

export interface SetVendorStateInput {
    type: typeof SET_VENDOR_STATE;
    payload: VendorStateInput;
}

export type VendorAction =  SetVendorStateInput