import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { orderBy } from 'lodash';

import { Box, Typography, Button } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import RightIcon from '@material-ui/icons/ChevronRight';

import Layout from '../common/Layout';
import ProductCard from './ProductCard';
import SearchPagination from './SearchPagination';
import LoadingIndicator from '../common/LoadingIndicator';
import VendorCard from './VendorCard';
import { colors } from '../../theme/defaultTheme';
import useFullTextSearch from './useFullTextSearch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resultTitle: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        width: '55%',
      },
      fontSize: 20,
      margin: '18px 0',

      '& > span': {
        fontSize: 'inherit',
        fontWeight: 'bold',
      },
    },
    vendorGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridAutoFlow: 'row',
      gap: 24,
      marginBottom: 30,

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        gap: 12,
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',

        '& > a': {
          maxWidth: 'calc(100% - 12px)',
          minWidth: 'calc(100% - 12px)',
          boxSizing: 'border-box',
          scrollSnapAlign: 'center',
        },
      },
    },
    vendorStack: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      paddingBottom: 16,
    },
    moreShopBtn: {
      textTransform: 'capitalize',
      fontSize: 14,
      color: colors.primary,
    },
    resultGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridAutoFlow: 'row',
      gap: 24,
      marginBottom: 30,

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },

      [theme.breakpoints.between('sm', 'md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },

      [theme.breakpoints.between('md', 'lg')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
  })
);

function SearchPage() {
  const resultsPerPage = 10;

  const classes = useStyles();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [page, setPage] = useState(1);
  const [moreShopsVisible, setMoreShopsVisible] = useState(false);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const searchQuery = searchParams.get('q');

  const { loading, vendors, products, vendorResults, productResults } = useFullTextSearch({
    searchQuery: searchQuery ?? '',
    isOnSearchPage: true,
  });

  useEffect(() => {
    if (searchQuery) {
      setPage(1);
    }
  }, [searchQuery]);

  const productsToDisplay = products.data
    ? products.data.filter((p) => productResults.includes(p.id))
    : [];

  const sortedProducts = orderBy(
    productsToDisplay,
    [(p) => p.name.toLowerCase().trim()],
    ['asc']
  ).slice((page - 1) * resultsPerPage, resultsPerPage * page);

  const extractedVendorsFromProducts = Array.from(
    new Set(productsToDisplay.map((p) => p.vendorId))
  );

  const vendorsToDisplay = orderBy(
    vendors.data
      ? vendors.data.filter((p) =>
          [...extractedVendorsFromProducts, ...vendorResults].includes(p.id)
        )
      : [],
    [(v) => v.companyName.toLowerCase()],
    ['asc']
  );

  const handlePageChange = (_page: number) => {
    setPage(_page);
    scrollToTop();
  };

  const noResult = vendorResults.length === 0 && productResults.length === 0;

  if (loading) {
    return (
      <Layout>
        <Box height='70vh'>
          <LoadingIndicator />
        </Box>
      </Layout>
    );
  }

  if (noResult) {
    return (
      <Layout>
        <Box height='40vh'>
          <Typography component='h1' className={classes.resultTitle}>
            0 items found for <Typography component='span'>"{searchQuery}"</Typography>
          </Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography component='h1' className={classes.resultTitle}>
          Shop{vendorsToDisplay.length <= 1 ? '' : 's'} related to&nbsp;
          <Typography component='span'>"{searchQuery}"</Typography>
        </Typography>
        <Button
          endIcon={<RightIcon />}
          className={classes.moreShopBtn}
          onClick={() => setMoreShopsVisible((old) => !old)}
        >
          {moreShopsVisible ? 'Hide Shops' : 'More Shops'}
        </Button>
      </Box>
      <>
        {!moreShopsVisible && (
          <Box className={classes.vendorGrid}>
            {vendorsToDisplay.slice(0, 3).map((vendor) => (
              <VendorCard key={vendor.id} vendor={vendor} />
            ))}
          </Box>
        )}
        {moreShopsVisible && (
          <Box className={classes.vendorStack}>
            {vendorsToDisplay.map((vendor) => (
              <VendorCard key={vendor.id} vendor={vendor} hasVisitShop />
            ))}
          </Box>
        )}
      </>
      <>
        {!moreShopsVisible && (
          <Box>
            <Typography component='h1' className={classes.resultTitle}>
              Product{productResults.length <= 1 ? '' : 's'} related to{' '}
              <Typography component='span'>"{searchQuery}"</Typography>
            </Typography>
            <Box className={classes.resultGrid}>
              {sortedProducts.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </Box>
            <Box display='flex' justifyContent='flex-end' mb={3}>
              {productResults.length > resultsPerPage && (
                <SearchPagination
                  count={Math.ceil(productResults.length / resultsPerPage)}
                  page={page}
                  onChange={handlePageChange}
                />
              )}
            </Box>
          </Box>
        )}
      </>
    </Layout>
  );
}

export default SearchPage;
