import React from 'react';

import Box from '@material-ui/core/Box';
import UserSideLinks from '../UserSideLinks';

const CustomerAccountPage = () => {
  return (
    <Box display='flex' alignItems='flex-start' gridColumnGap={32}>
      <UserSideLinks />
      <h2>Profile page here</h2>
    </Box>
  );
};

export default CustomerAccountPage;
