import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import withStyles from '@material-ui/styles/withStyles';
import { colors } from '../../../theme/defaultTheme';

export const CarouselButtonContainer = withStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: 48,
      height: 48,
      color: colors.darkest,
      opacity: '50%',
    },
    '@media (max-width: 768px)': {
      '& svg': {
        width: 32,
        height: 32,
      },
    },
  },
}))(Box);

export const CarouselButton = withStyles(() => ({
  root: {
    backdropFilter: 'blur(5px)',
  },
}))(ButtonBase);

export const CarouselContainer = withStyles(() => ({
  root: {
    position: 'relative',
    '& li.slide': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))(Box);

export const CarouselItemContainer = withStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
    '& img': {
      width: '100%',
      maxHeight: 500,
      objectFit: 'cover',
    },
    '@media (max-width: 768px)': {
      '& img': {
        width: '100%',
        maxHeight: 300,
        objectFit: 'cover',
      },
    },
  },
}))(Box);

export const CarouselItemContainerAux = withStyles(() => ({
  root: {
    height: 180,
    width: 350,
    position: 'absolute',
    //backgroundColor: colors.white,
    zIndex: 12,
    bottom: 8,
    left: 8,
    boxSizing: 'border-box',
    padding: 16,
    '@media (max-width: 768px)': {
      padding: 8,
      height: 120,
      width: 200,
      '& .carousel-item-description': {
        fontSize: '16px!important',
      },
      '& .carousel-item-button': {
        width: '100%!important',
      },
    },
  },
}))(Box);

export const FeaturedCategoryContainer = withStyles(() => ({
  root: {
    height: 500,
    margin: '32px 0',
    border: '1px solid #E8E8E8',
    display: 'flex',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      height: 'auto',
    },
  },
}))(Box);

export const FeaturedCategoryDescriptionContainer = withStyles(() => ({
  root: {
    height: '100%',
    width: 350,
    boxSizing: 'border-box',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#FAFAFA',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
}))(Box);

export const FeaturedCategoryItemsContainer = withStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    padding: '24px 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
}))(Box);

export const FeaturedCategoryItemsControllerContainer = withStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
}))(Box);

export const FeaturedCategoryItemsButtonContainer = withStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root:last-child': {
      marginLeft: 12,
    },
  },
}))(Box);

export const FeaturedCategoryControllerButton = withStyles(() => ({
  root: {
    color: colors.white,
    width: 32,
    height: 32,
    backgroundColor: colors.darkest,
    '&.Mui-disabled': {
      backgroundColor: '#E2E2E2',
    },
    '& svg': {
      width: 18,
      height: 18,
    },
  },
}))(ButtonBase);

export const FeaturedCategoryItemsCardContainer = withStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
}))(Box);

export const ProductCarouselContainer = withStyles(()=> ({
   root:{
  maxWidth:  '100%',
  height: 'inherit',
  display: 'flex',
  position: 'relative',
  '& .carousel-root': {
    width: '100%',
  },
  '& .carousel-root > .carousel': {
    overflow: 'unset',
    height: '100%',
  },
  '& .headers-carousel': {
    width: '100%',
  },
  '& .slider.animated': {
    transform: `translate3d(0%, 0px, 0px)`,
  },
   }
}))(Box)