import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { styled } from '@material-ui/core';
import { colors } from '../../theme/defaultTheme';

const Checkbox = styled(MuiCheckbox)({
  '&.MuiCheckbox-root *': {
    userSelect: 'none',
    color: colors.primary,
  },

  '&.Mui-checked': {
    color: colors.primary,
  },
});

const FormCheckbox = ({ label, ...rest }: { label: string } & CheckboxProps) => {
  return <FormControlLabel control={<Checkbox {...rest} />} label={label} />;
};

export default FormCheckbox;
