import React, { CSSProperties } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { styled } from '@material-ui/core';
import { appColors } from '../../utils/ui-utils';

interface FormInputProps {
  label: string;
}

const labelStyles: CSSProperties = {
  fontSize: 15,
  color: appColors.grayText,
  marginBottom: 8,
  display: 'inline',
  width: 'max-content',
};

const Input = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,

    '& input:focus fieldset': {
      borderColor: appColors.primary,
    },
  },
});

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps & TextFieldProps>(
  (props, ref) => {
    const { label, ...inputProps } = props;

    return (
      <Box flex={1} display='flex' flexDirection='column' mb={2}>
        <Typography component='label' htmlFor={inputProps.name} style={labelStyles}>
          {label}
        </Typography>
        <Input
          size='small'
          variant='outlined'
          id={inputProps.name}
          {...inputProps}
          inputRef={ref}
          FormHelperTextProps={{
            style: {
              marginLeft: 0,
            },
          }}
        />
      </Box>
    );
  }
);

export default FormInput;
