import React from 'react';

import Chip from '@material-ui/core/Chip';
import { OrderStatus } from './order-types';
import { appColors } from '../../utils/ui-utils';
import { formatOrderStatus } from './track-orders/track-order-utils';

const TagColorMap: Record<OrderStatus, { text: string; bg: string }> = {
  FAILED_DELIVERY: {
    text: appColors.failedColor,
    bg: appColors.failedBgColor,
  },
  RECEIVED: {
    text: appColors.receivedColor,
    bg: appColors.receivedBgColor,
  },
  CANCELLED: {
    text: appColors.cancelledColor,
    bg: appColors.cancelledBgColor,
  },
  DELIVERED: {
    text: appColors.purple,
    bg: appColors.purpleLight,
  },
  PAID: {
    text: appColors.purple,
    bg: appColors.purpleLight,
  },
  UNPAID: {
    text: appColors.purple,
    bg: appColors.purpleLight,
  },
  READY_TO_SHIP: {
    text: appColors.purple,
    bg: appColors.purpleLight,
  },
  PENDING: {
    text: appColors.purple,
    bg: appColors.purpleLight,
  },
  SHIPPED: {
    text: appColors.purple,
    bg: appColors.purpleLight,
  },
};

const OrderTag = ({ status }: { status: OrderStatus }) => {
  const tagColor = TagColorMap[status as keyof typeof TagColorMap];

  return (
    <Chip
      style={{
        marginLeft: 'auto',
        textTransform: 'capitalize',
        fontWeight: 600,
        fontSize: 13,
        minWidth: 100,
        height: 30,
        borderRadius: 5,
        color: tagColor ? tagColor.text : appColors.purple,
        backgroundColor: tagColor ? tagColor.bg : appColors.purpleLight,
      }}
      label={status === 'DELIVERED' ? 'Received' : formatOrderStatus(status).toLowerCase()}
    />
  );
};

export default OrderTag;
