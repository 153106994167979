import React from 'react';

import { RiUser3Line, RiMapPin3Line, RiPriceTagLine, RiTruckLine } from 'react-icons/ri';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import UserLink from './UserLink';
import { appColors } from '../../utils/ui-utils';

const UserSideLinks = () => {
  return (
    <Box
      width={300}
      display='flex'
      flexDirection='column'
      gridRowGap={28}
      bgcolor={appColors.lightGray}
      style={{ padding: '48px 32px' }}
    >
      <Typography style={{ fontSize: 24, fontWeight: 600 }}>My Account</Typography>
      <UserLink to='/me/account'>
        <RiUser3Line /> My Profile
      </UserLink>
      <UserLink to='/me/address'>
        <RiMapPin3Line /> Address
      </UserLink>
      <Typography style={{ fontSize: 24, fontWeight: 600, marginTop: '16px' }}>Orders</Typography>
      <UserLink to='/me/orders'>
        <RiPriceTagLine /> My Orders
      </UserLink>
      <UserLink to='/me/track-orders'>
        <RiTruckLine /> Track Orders
      </UserLink>
    </Box>
  );
};

export default UserSideLinks;
