import {
    SET_AUTH_STATE,
    AuthState,
    AuthAction,
    RESET_AUTH_STATE
} from './types';

const INITIAL_STATE: AuthState = {
    username: '',
    password: '',
    rememberMe: false,
    isLoggedIn: false,
    loginLoading: false,
    loginErrorInputs: {
        email: { error: false, message: '' },
        password: { error: false, message: '' }
    },
    registerLoading: false,
    registerUserTypes: ['Customer', 'Vendor'],
    registerSelectedUserType: ['Customer'],
    registerCountries: [],
    registerProvinces: [],
    registerCities: [],
    registerInformation: {
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        password: '',
        confirmPassword: '',
        countryCode: '+63',
        mobileNumber: '',
        areaCode: '',
        landLineNumber: '',
        address: '',
        country: '',
        province: '',
        city: '',
        postal: '',
    },
    registerErrorInputs: {
        name: { error: false, message: '' },
        companyName: { error: false, message: '' },
        email: { error: false, message: '' },
        password: { error: false, message: '' },
        confirmPassword: { error: false, message: '' },
        mobileNumber: { error: false, message: '' },
        landLineNumber: { error: false, message: '' },
        address: { error: false, message: '' },
        countryAndProvince: { error: false, message: '' },
        cityAndPostal: { error: false, message: '' },
    },
    registerProceedButtonClicked: false,
    forgotPasswordEmail: '',
    forgotPasswordLoading: false,
    forgotPasswordErrorInputs: {
        email: { error: false, message: '' },
    },
    resetPasswordNewPassword: '',
    resetPasswordConfirmNewPassword: '',
    resetPasswordLoading: false,
    resetPasswordUser : {
        email: '',
        firstName: '',
        forgotPasswordId: '',
        id: '',
        lastName: '',
    },
    resetPasswordForgotPasswordId: '',
    resetPasswordSuccess: false,
    resetPasswordErrorInputs: {
        newPassword: { error: false, message: '' },
        confirmPassword: { error: false, message: '' },
    },
    resetPasswordValidMinimum: false,
    resetPasswordValidMaximum: false,
    resetPasswordValidMixCase: false,
    resetPasswordValidNumber: false,
}

const reducer = (state = INITIAL_STATE, action: AuthAction): AuthState => {
    switch (action.type) {
        case SET_AUTH_STATE:
            return { ...state, ...action.payload };
        case RESET_AUTH_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default reducer;