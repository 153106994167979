import { useState, useEffect } from 'react';
import { Address } from '../../../store/cart/types';

import { 
    AddressDialogConfirmButton, 
    AddressDialogCancelButton, 
    AddressDialogContentContainer,
    AddressHomeTag,
    AddressOfficeTag
} from './CartComponents';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import HomeIcon from '@material-ui/icons/Home';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import map from 'lodash/map';

interface AddressDialogProps {
    open: boolean;
    address: Array<Address>;
    defaultBillingAddressId: string;
    defaultShippingAddressId: string;
    selectedBillingAddressId: string;
    selectedShippingAddressId: string;
    title: 'Shipping Address' | 'Billing Address';
    onPressClose: () => void;
    onPressConfirm: (id: string) => void;
}

const AddressDialog = (props: AddressDialogProps) => {
    const { open, title, onPressClose, onPressConfirm, address, defaultBillingAddressId, defaultShippingAddressId, selectedShippingAddressId, selectedBillingAddressId } = props;
    const [activeId, setActiveId] = useState('');

    useEffect(() => {
        setActiveId(title === 'Shipping Address' ? selectedShippingAddressId : selectedBillingAddressId)
        // eslint-disable-next-line
    }, [props.open])
    
    return (
        <Dialog
            open={open}
            onClose={onPressClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ borderRadius: 0, maxWidth: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            maxWidth="xl"
        >
            <AddressDialogContentContainer>
                <Box style={{ position: 'absolute', top: 0, right: 1, marginRight: 30, marginTop: 40 }}>
                    <HighlightOffIcon fontSize="large" onClick={onPressClose}/>
                </Box>
                <Box display="flex" flexDirection="column" boxSizing="border-box">
                    <Box fontSize="23px" fontWeight="600">{title}</Box>
                    <Box display="flex" flexDirection="row" alignItems="center" marginTop="20px" padding="15px 35px 15px 35px" style={{ backgroundColor: '#FAFAFA'}}>
                        <Box fontWeight="bold" fontSize="13px">Address</Box>
                    </Box>
                    {map(address, (a) => {
                        return (
                            <Box key={a.id} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" marginTop="20px" padding="30px 35px 30px 35px" style={{ backgroundColor: '#FFF', borderWidth: 1, borderStyle: 'solid', borderColor: '#E8E8E8' }}>
                                <Box display="flex" flexDirection="row" alignItems="center" boxSizing="border-box">
                                    {a.tag === 'Office' ?
                                        <AddressOfficeTag>
                                            <HomeIcon fontSize="small" />
                                            <Box fontSize="12px" style={{ color: '#646593' }}>Office</Box>
                                        </AddressOfficeTag> :
                                        <AddressHomeTag>
                                            <HomeIcon fontSize="small" />
                                            <Box fontSize="12px" style={{ color: '#159AFE' }}>Home</Box>
                                        </AddressHomeTag>
                                    }
                                    <Box>{a.addressLine}</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center" boxSizing="border-box">
                                    <Box display="flex" flexDirection="column" boxSizing="border-box" paddingRight="15px"> 
                                        {defaultShippingAddressId === a.id &&
                                            <Box fontSize="12px" style={{ opacity: 0.5 }} marginBottom="10px">Default Shipping Address</Box>
                                        }
                                        {defaultBillingAddressId === a.id &&
                                            <Box fontSize="12px" style={{ opacity: 0.5 }}>Default Billing Address</Box>
                                        }
                                    </Box>
                                    <Checkbox 
                                        id={`checkout-address-dialog-checkbox-${a.id}`}
                                        checked={activeId === a.id ? true : false}
                                        style={{ height: 14, width: 14, padding: 2, borderColor: '#181E28', borderWidth: 1, borderStyle: 'solid' }}
                                        checkedIcon={<Box style={{ height: 14, width: 14, borderRadius: 7, backgroundColor: '#181E28' }}/>} 
                                        icon={<Box style={{ height: 14, width: 14, borderRadius: 7, backgroundColor: '#FFF' }}/>}
                                        onChange={(e) => {
                                            setActiveId(a.id)
                                        }}
                                    />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                <Box display="flex" flexDirection="row" boxSizing="border-box" alignItems="center" justifyContent="flex-end" padding="20px 0 20px 0">
                    <AddressDialogCancelButton id="checkout-address-dialog-cancel-btn" onClick={onPressClose}>
                        Cancel
                    </AddressDialogCancelButton>
                    <AddressDialogConfirmButton id="checkout-address-dialog-confirm-btn" onClick={() => onPressConfirm(activeId)}>
                        Confirm
                    </AddressDialogConfirmButton>
                </Box>
            </AddressDialogContentContainer>
        </Dialog>
    )
}

export default AddressDialog;