export type DynamicAuthInputType = string | boolean | number | undefined | Array<CartItems> | Address | Array<Address>;

interface DynamicCartStateInput<T> {
    [key: string]: T;
}


export interface CartStateInput extends DynamicCartStateInput<DynamicAuthInputType> {}

export interface CartState {
    cartLoading: boolean;
    cartItems: Array<CartItems>;
    checkOutItems: Array<CartItems>;
    cartSummaryDiscount: number;
    cartSummaryShipping: number;
    cartSummarySubTotal: number;
    cartSummaryPoNumber: string;
    checkOutLoading: boolean;
    checkOutAddresses: Array<Address>;
    checkOutShippingAddress: Address;
    checkOutBillingAddress: Address;
    checkOutPaymentMethod: 'Credit Card';
    checkOutAddressDialogTitle: 'Shipping Address' | 'Billing Address';
    checkOutAddressDialogOpen: boolean;
}

export interface Address {
    addressLine: string;
    city: string;
    country: string;
    customerId: string;
    dateCreated: string;
    dateUpdated: string;
    docType: string;
    id: string;
    isDeleted: boolean;
    postalCode: number;
    province: string;
    tag: string;
}

export interface CartItems {
    id: string;
    name: string;
    selected: boolean;
    quantity: number;
    price?: string | number;
    size?: string;
    color?: string;
    image?: MediaType;
    vendorId: string;
    isOutOfStock?: boolean;
    discountPrice: number;
    productId: string;
    productSkuId: string;
    skuNumber: string;
    displayId: string;
    vendorName: string;
    h1:string;
    h2?:string;
    h3?:string;
    brand:string;
    description:string;
    material:string;
    type:string;
    marketStock: number;
}

export interface MediaType {
    name: string;
    path: string;
    type: string;
    size: number;
}

export const SET_CART_STATE = 'set_cart_state';
export const RESET_CART_STATE = 'reset_cart_state';

export interface SetCartStateAction {
    type: typeof SET_CART_STATE;
    payload: CartStateInput;
}

export interface ResetCartStateAction {
    type: typeof RESET_CART_STATE,
    payload: null
}

export type CartAction =  SetCartStateAction | ResetCartStateAction;