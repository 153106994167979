import React from 'react';

import UserTopLinks from './UserTopLinks';
import UserPages from './UserPages';
import Layout from '../common/Layout';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../store/auth/actions';
import { Redirect } from 'react-router-dom';

const UserSpecificPage = () => {
  const isLoggedIn = useSelector(getAuthState);

  if (!isLoggedIn) return <Redirect to='/login' />;

  return (
    <Layout>
      <UserTopLinks />
      <UserPages />
    </Layout>
  );
};

export default UserSpecificPage;
