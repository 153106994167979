import React, { useMemo, MouseEventHandler } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, Button, Typography, IconButton } from '@material-ui/core';

import CartIcon from '@material-ui/icons/ShoppingCartOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CloseIcon from '@material-ui/icons/HighlightOff';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { Product } from '../home/types';
import { colors } from '../../theme/defaultTheme';
import { formatToCurrency } from '../../utils/helpers';
import {
  useAddFavorite,
  useFavoritesQuery,
  useRemoveFavoriteForHome,
} from '../user-specific/hooks/favorites-hooks';
import defaultImage from '../../assets/images/symphony_default.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useIsLoggedIn } from '../user-specific/hooks/useLoggedInUser';
import { useDispatch } from 'react-redux';
import { setSystemState } from '../../store/system/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    content: {
      display: 'flex',
      padding: 24,

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 16,
      },
    },
    imageBox: {
      boxSizing: 'border-box',
      width: '40%',
      aspectRatio: '1 / 1',
      margin: 0,
      padding: 12,
      backgroundColor: '#F5F5F5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        aspectRatio: '4 / 3',
      },
    },
    details: {
      width: '60%',
      paddingLeft: 32,
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingLeft: 0,
      },
    },
    vendor: {
      marginBottom: 12,
      fontSize: 13,

      display: 'flex',
      alignItems: 'center',
      gap: 8,

      '& > img': {
        width: 36,
        height: 36,
        display: 'inline-block',
      },
    },
    title: {
      borderBottom: `1px solid #eee`,
      position: 'relative',
      padding: 20,

      '& > h2': {
        fontWeight: 'bold',
        fontSize: 18,

        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
        },
      },
    },
    closeBtn: {
      position: 'absolute',
      top: 12,
      right: 12,

      [theme.breakpoints.down('sm')]: {
        top: 0,
        right: 0,
      },
    },
    desc: {
      marginBottom: 12,
      color: colors.gray,
    },
    favoriteBtn: {
      color: colors.gray,
      backgroundColor: colors.white,
      marginLeft: 8,
    },
    image: {
      maxWidth: '90%',
      maxHeight: '90%',
      display: 'block',
    },
    price: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 12,
    },
    button: {
      color: colors.white,
      backgroundColor: colors.primary,
      borderRadius: 0,
      padding: '13px 16px',
      textTransform: 'capitalize',
      fontSize: 14,
      minWidth: 250,
      alignSelf: 'flex-start',

      '&:hover': {
        backgroundColor: colors.primary,
        opacity: 0.9,
      },

      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
        flex: 1,
      },
    },
    buttonRow: {
      display: 'flex',
      marginTop: 'auto',
      alignItems: 'center',
      gap: 12,

      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
      },
    },
    vendorLink: {
      fontSize: 'inherit',
      color: colors.darkest,
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

interface ProductDetailsProps {
  product: Product;
  onClose: () => void;
}

function ProductDetails({ product, onClose }: ProductDetailsProps) {
  const classes = useStyles();

  const isLoggedIn = useIsLoggedIn();
  const history = useHistory();
  const favorites = useFavoritesQuery();
  const addFavorite = useAddFavorite();
  const removeFavorite = useRemoveFavoriteForHome();

  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const favs = favorites.data?.map((f) => f.id) ?? [];

  const isFavorite = favs.includes(product.id);

  const handleFavoriteClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();

    // dispatch(setSystemState({ redirectTo: `/search?q=${searchParams.get('q')}` }));

    // if not logged in, redirect to login page
    if (!isLoggedIn) {
      history.push('/login', { redirectTo: `/search?q=${searchParams.get('q')}` });
      return;
    }

    // perform mutation
    if (!isFavorite) {
      await addFavorite.mutateAsync({ productId: product.id, vendorId: product.vendorId });
      return;
    }

    await removeFavorite.mutateAsync({ favoriteId: product.id, favoriteName: product.name });
  };

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby='product-dialog-title'
      aria-describedby='product-dialog-description'
      maxWidth='md'
    >
      <Box id='product-dialog-title' className={classes.title}>
        <Box className={classes.vendor}>
          <img
            src={product.vendorImage ? product.vendorImage : defaultImage}
            alt={product.companyName}
          />
          <Link to={`/vendor/${product.vendorId}`} className={classes.vendorLink}>
            {product.companyName}
          </Link>
        </Box>
        <Typography component='h2'>{product.name}</Typography>
        <IconButton aria-label='close' className={classes.closeBtn} onClick={onClose}>
          <CloseIcon fontSize='large' htmlColor={colors.darkest} />
        </IconButton>
      </Box>
      <DialogContent className={classes.content}>
        <Box component='figure' className={classes.imageBox}>
          <img className={classes.image} src={product.image.path} alt={product.name} />
        </Box>
        <Box className={classes.details}>
          <Typography className={classes.price}>{formatToCurrency(product.price)}</Typography>
          <Typography className={classes.desc}>Product Description</Typography>
          <Typography id='product-dialog-description'>
            This is the product description. Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Voluptas deserunt rem placeat facere veritatis enim.
          </Typography>
          <Box className={classes.buttonRow}>
            <Button
              className={classes.button}
              variant='contained'
              disableElevation
              startIcon={<CartIcon />}
            >
              Add to Cart
            </Button>
            <IconButton
              className={classes.favoriteBtn}
              aria-label='favorite this product'
              onClick={handleFavoriteClick}
            >
              {isFavorite ? <FavoriteIcon htmlColor='red' /> : <FavoriteBorderIcon />}
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ProductDetails;
