import { Component } from  'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from '../../store';
import { OrdersState } from '../../store/orders/types';
import { setOrdersState, fetchCustomerOrders } from '../../store/orders/actions';

import Layout from '../common/Layout';

import Tabs from './fragments/Tabs';
import OrderTag from './fragments/OrderTag';
import VerticalTabs from './fragments/VerticalTabs';
import HorizontalTabs from './fragments/HorizontalTabs';
import { 
    OrderBlockHeader,
    MyOrdersContainer, 
    OrderBlockContainer,
    MyOrdersHeaderContainer, 
    MyOrdersContentContainer
} from './fragments/MyOrderComponents';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';
import map from 'lodash/map';

interface MyOrderProps {
    order: OrdersState;
    setOrdersState: typeof setOrdersState;
    fetchCustomerOrders: typeof fetchCustomerOrders;
}

class MyOrders extends Component<MyOrderProps> {
    componentDidMount = () => {
        this.props.fetchCustomerOrders();
    }

    render () {
        const { ordersLoading, activeSubTab, myOrders } = this.props.order;
        return (
            <Layout>
                {ordersLoading ?
                    <Box display="flex" height="100%" width="100%" justifyContent="center" alignItems="center">
                        <CircularProgress size={25} style={{ color: '#000' }} />
                    </Box>
                    :
                    <MyOrdersContainer>
                        <Tabs activeTab="Orders"/>
                        <Box className="my-orders-content-container">
                            <VerticalTabs activeMainTab="Orders" activeSubTab={activeSubTab}/>
                            <HorizontalTabs activeSubTab={activeSubTab}/>
                            <MyOrdersContentContainer>
                                <MyOrdersHeaderContainer>
                                    My Orders
                                </MyOrdersHeaderContainer>
                                {map(myOrders, (order) => {
                                    return (
                                        <OrderBlockContainer key={order.id} id={`order-block-${order.id}`}>
                                            <OrderBlockHeader>
                                                <Box className="order-block-header-left-container">
                                                    <Box className="order-block-vendor-name">{order.vendorName}</Box>
                                                    <Box className="order-block-order-num">ORDER NO. {order.id}</Box>
                                                    <Box className="order-block-order-date">{order.status === 'CANCELLED' ? 'Cancelled' : 'Delivered'} {moment(order.date).format('MMMM D YYYY')}</Box>
                                                </Box>
                                                <OrderTag type={order.status}/>
                                            </OrderBlockHeader>
                                            <Box display="flex" flexDirection="row" boxSizing="border-box" justifyContent="space-between">
                                                <Box display="flex" flexDirection="column" boxSizing="border-box" style={{ width: '100%' }}>
                                                    {map(order.items, (o) => {
                                                        return (
                                                            <Box key={`${order.id}_${o.name}`} display="flex" flexDirection="column" boxSizing="border-box">
                                                                <Box display="flex" flexDirection="row" boxSizing="border-box" alignItems="center" padding="10px 25px">
                                                                    <img src={o.image} alt="" className="order-item-image"  />
                                                                    <Box className="order-item-name">{o.name}</Box>
                                                                    <Box className="order-item-qty">Quantity: {o.qty}</Box>
                                                                    <Box className="order-item-price">₱ {o.price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box>
                                                                    <Box className="order-item-mobile-content-container">
                                                                        <Box className="order-item-mobile-name">{o.name}</Box>
                                                                        <Box className="order-item-priceqty-container">
                                                                            <Box className="order-item-mobile-price">₱ {o.price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box>
                                                                            <Box className="order-item-mobile-qty" >x {o.qty}</Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                                <Box display="flex" flexDirection="column" justifyContent="center" boxSizing="border-box" width="300px">
                                                    <Link to={`/orders/${order.vendorName}/${order.id}`} style={{ display: 'flex', justifyContent: 'center', textDecoration: 'underline', textTransform: 'none', color: '#181E28', fontWeight: 'bold' }}>View Item(s)</Link>
                                                </Box>
                                            </Box>
                                        </OrderBlockContainer>
                                    )
                                })}
                            </MyOrdersContentContainer>
                        </Box>
                    </MyOrdersContainer>
                }
            </Layout>
        )
    } 
}

const mapStateToProps = (state: AppState) => {
    return { 
        order: state.order
    }
}

const mapDispatchToProps = {
    setOrdersState,
    fetchCustomerOrders
}

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);