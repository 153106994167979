import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { appColors } from '../../utils/ui-utils';

const ListTitle = ({ title }: { title: string }) => {
  return (
    <Box bgcolor={appColors.lightGray} padding='16px 20px' mb={3}>
      <Typography component='h1' style={{ fontSize: 16, fontWeight: 600 }}>
        {title}
      </Typography>
    </Box>
  );
};

export default ListTitle;
