import { AnyAction } from 'redux';

export type DynamicProductInputType = string | Array<string> | Product | number | boolean | undefined | HTMLDivElement | null;
interface DynamicProductStateInput<T> {
    [key: string]: T;
}

export interface Product {
    id: string,
    brand: string,
    material: string,
    type: string,
    description: string,
    name: string,
    displayId: string,
    image: {
        name: string,
        path: string,
        size: number,
        type: string
    },
    h1: string,
    h2: string,
    h3: string,
    price: number,
    discountPrice?: number,
    productSkuId: string,
    featuredSku: string,
    vendorId: string,
    vendorName: string,
    isFavorite: boolean
}

export interface ProductStateInput extends DynamicProductStateInput<DynamicProductInputType> {}

export interface ProductState {
    products: Array<Product>,
    productsLoading: boolean,
    dialogOpen: boolean,
    selectedMedia?: string,
    selectedProduct?: Product,
    productDetailsLoading: boolean
}

export const SET_PRODUCT_STATE = 'set_product_state';

export interface SetProductStateAction {
    type: typeof SET_PRODUCT_STATE;
    payload: ProductStateInput;
}

export type ProductAction = AnyAction | SetProductStateAction;
