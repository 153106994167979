import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { TextField, InputAdornment, Box, Paper, IconButton } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Close';

import { colors } from '../../theme/defaultTheme';
import useOnClickOutside from './useOnClickOutside';
import useFullTextSearch from './useFullTextSearch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      position: 'relative',
    },
    resultsContainer: {
      position: 'absolute',
      top: 39,
      left: 0,
      right: 0,
      padding: 16,
      zIndex: 1000,
      backgroundColor: colors.white,
    },
    suggestionLink: {
      display: 'flex',
      textDecoration: 'none',
      color: 'inherit',
      paddingBottom: '12px',

      '&:hover': {
        color: colors.primary,
      },
    },
  })
);

interface SearchProps {
  onSuggestionClick?: () => void;
  focused?: boolean;
}

function Search({ onSuggestionClick, focused }: SearchProps) {
  const classes = useStyles();
  const suggestionBoxRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [resultsShown, setResultsShown] = useState(false);

  const navigate = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [searchQuery, setSearchQuery] = useState(() => searchParams.get('q') ?? '');

  const { searchHandler, searchSuggestions } = useFullTextSearch({ searchQuery });

  useEffect(() => {
    if (focused) {
      searchInputRef.current?.focus();
    }
  }, [focused]);

  useOnClickOutside(suggestionBoxRef, () => {
    setResultsShown(false);
  });

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    searchHandler();
    if (e.target.value) setResultsShown(true);
  };

  const submitSearchForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!searchQuery) return;

    // go to search page on enter
    navigate.push(`/search?q=${searchQuery}`);

    setResultsShown(false);

    if (onSuggestionClick) onSuggestionClick();
  };

  return (
    <Box className={classes.container}>
      <Box component='form' onSubmit={submitSearchForm}>
        <TextField
          inputRef={searchInputRef}
          value={searchQuery}
          onChange={handleSearchInput}
          onFocus={() => setResultsShown(Boolean(searchQuery))}
          size='small'
          variant='outlined'
          style={{ width: '100%', backgroundColor: '#f5f5f5' }}
          placeholder='Search for products, shops'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                {searchQuery ? (
                  <IconButton
                    aria-label='clear search'
                    edge='end'
                    onClick={() => setSearchQuery('')}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : null}
              </InputAdornment>
            ),
          }}
        />
        <input type='submit' hidden />
      </Box>
      {resultsShown && searchQuery && (
        <Paper ref={suggestionBoxRef} className={['results', classes.resultsContainer].join(' ')}>
          {searchQuery && searchSuggestions.length === 0 && (
            <p>0 items found for "{searchQuery}"</p>
          )}
          {searchSuggestions.map((suggestion) => (
            <Link
              key={suggestion}
              to={`/search?q=${suggestion}`}
              className={classes.suggestionLink}
              onClick={() => {
                setResultsShown(false);
                setSearchQuery(suggestion);

                if (onSuggestionClick) onSuggestionClick();
              }}
            >
              {suggestion}
            </Link>
          ))}
        </Paper>
      )}
    </Box>
  );
}

export default Search;
