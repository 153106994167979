import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Category } from '../../../store/system/types';
import { useCategoriesStyles } from './categoriesStyles';
import Shoes1 from '../../../assets/images/products/shoes-1.png';

const CategoryMenu = ({ category }: { category: Category }) => {
  const classes = useCategoriesStyles();

  return (
    <Paper className={classes.flyoutContent}>
      <Box component='ul' className='first'>
        {category.h2?.map((h2) => (
          <Box key={h2.name} component='li'>
              <Link to={`/search?q=${h2.name}`}>{h2.name}</Link>
            {h2.h3 && (
              <Box component='ul'>
                {h2.h3.map((h3) => (
                  <Box key={h3} component='li'>
                    <Link to={`/search?q=${h3}`}>{h3}</Link>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Box className={classes.flyoutRightImage}>
        <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>New For You</Typography>
        <img src={Shoes1} alt={category.name} />
        <Typography style={{ fontSize: 18 }}>Nike Run</Typography>
        <Typography style={{ fontWeight: 'bold' }}>Php 2,000.00</Typography>
      </Box>
    </Paper>
  );
};

export default CategoryMenu;
