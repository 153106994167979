import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFollowedByUser, getProducts, removeFollowedByUser } from '../followed-vendor/followedService';
import useLoggedInUser from './useLoggedInUser';

type FollowedByUserArgs = {
    vendorId:string;
    vendorName:string;
}

export function useProductFetchQuery(){
    return useQuery('products',()=>getProducts())
}

export function useFollowedByUserQuery(){
    const user = useLoggedInUser();

    return useQuery(('follower:' + user?.id) as string, ()=>
    getFollowedByUser(user?.id as string)
    );
}

export function useRemoveFollowedByUser(){
    const user = useLoggedInUser();
    const dispatch = useDispatch();
    const history = useHistory();
    
    const followeByUserQuery = useFollowedByUserQuery();

    return useMutation(
        ({vendorId,vendorName}:FollowedByUserArgs) => 
        removeFollowedByUser(user?.id as string,vendorId),
        {
            retry: 1,
            async onSuccess(data, variables, context){
                await followeByUserQuery.refetch();
                history.replace('/me/favorites');
                dispatch({
                    type:'set_system_state',
                    payload: {
                        snackBarIsOpen: true,
                        snackBarMessage: `Unfollowed ${variables.vendorName}`,
                        snackBarType: 'success'
                    },
                });
            },
            onError: (error:any) => {
                let err = 'An unknown error occured';

                if (error.response) {
                  if (error.response.status === 401) return;
        
                  err = error.response.data?.error?.message;
                }
        
                if (error.message) {
                  err = error.message;
                }
        
                dispatch({
                  type: 'set_system_user_details',
                  payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: err,
                    snackBarType: 'error',
                  },
                });
            }
        }
    )
}


